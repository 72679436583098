import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Footer from "./Footer";
import Header from "./Header";
import IcoImg from "./assets/img/Ico.png";
import StartupsIco1 from "./assets/img/StartupsIco-1.png";
import StartupsIco2 from "./assets/img/StartupsIco-2.png";
import StartupsIco3 from "./assets/img/StartupsIco-3.png";
import StartupsIco4 from "./assets/img/StartupsIco-4.png";
import StartupsIco5 from "./assets/img/StartupsIco-5.png";
import StartupsIco6 from "./assets/img/CustomerEngIco-7.png";
import StartupsIco7 from "./assets/img/CustomerEngIco-5.png";
import StartupsIco8 from "./assets/img/WebsiteDevelopmentIco-2.png";
import StartupsIco9 from "./assets/img/AutomationTestingIco-10.png"; 
import StartupsIco10 from "./assets/img/MobileAppDevIco-2.png"; 
import StartupsIco11 from "./assets/img/StartupsIco-6.png";
import StartupsIco12 from "./assets/img/StartupsIco-7.png";
import StartupsIco13 from "./assets/img/StartupsIco-8.png";
import StartupsIco14 from "./assets/img/ZohoCRMIco-8.png";
import StartupsIco15 from "./assets/img/MobileAppDevIco-3.png";
import StartupsIco16 from "./assets/img/ZohoCRM.png";
import StartupsIco17 from "./assets/img/SocialMediaIco-6.png";
import StartupsIco18 from "./assets/img/StartupsIco-9.png";
import StartupsIco19 from "./assets/img/seoIco-4.png";
import StartupsIco20 from "./assets/img/CMSIco-5.png";
import StartupsIco21 from "./assets/img/WebsiteDevelopmentIco-7.png";
import StartupsIco22 from "./assets/img/StartupsIco-10.png";
import StartupsIco23 from "./assets/img/StartupsIco-11.png";
const Startups = () => {
    const [key, setKey] = useState('technicalMastery');
    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="startUpBanner innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Startups</h2>
                                    <p>Hummingtec has worked with over 45 startups to help them achieve their goals. From the conception and validation of the idea until the product is built and ready to roll out, Hummingtec caters to the needs of a startup and provides all the services needed to be successful.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="startUpData">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="topDataWrap">
                                    <p>We offer you guidance and resources that will help you increase your valuation and reduce your overhead costs. As Technology Partners, we join hands in your growth and success story by enabling you to focus on your goals and GTM strategy, building networks while we transform your ideas into a crisp market-ready product and handle the monthly payroll, team management, quality checks, and deliver before the deadline.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="startupTabWrap commponTabWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="titleWrap">
                                    <h2>Elevate Your Business with Our Expert Software Solutions!</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    className=""
                                >
                                    <Tab eventKey="technicalMastery" title="Technical Mastery at Your Fingertips"> 
                                        <div className="testingWrapper">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="masonaryWrap">
                                                            <ResponsiveMasonry columnsCountBreakPoints={{ 380: 1, 480: 2, 860: 3 }}>
                                                                <Masonry>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco1} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Expert Software Developers</h2>
                                                                            <p>Imagine having a world-className virtual software team at your command. Scale up or down effortlessly, meeting project deadlines without the hassle of hiring. We bring the best in the business to you!</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco2} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Cutting-Edge Development Tools</h2>
                                                                            <p>Dive into innovation with our suite of development environments and tools. Whether it’s GitHub, Bitbucket, or other specialized tools, we've got the tech stack to bring your vision to life.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco3} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Cloud Services Revolution</h2>
                                                                            <p>Leverage the power of top-tier cloud platforms like AWS, Azure, or Google Cloud. We offer the ultimate solution for hosting, scalability, and high-performance computing needs.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco4} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>DevOps Excellence</h2>
                                                                            <p>Streamline your deployment with our CI/CD tools, containerization expertise (think Docker), and orchestration finesse (hello, Kubernetes). Efficiency is just a click away.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco5} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Assured Quality with QA Tools</h2>
                                                                            <p>Our automated testing frameworks guarantee top-notch quality. From unit to user acceptance testing, we ensure every aspect of your software shines.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco6} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Fortified Security</h2>
                                                                            <p>Safeguard your digital assets with our robust security tools. Code analysis, encryption, and data protection are our fortresses against cyber threats.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco11} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Smart Data Solutions</h2>
                                                                            <p>Our data storage and management solutions, encompassing both SQL and NoSQL, are designed for efficiency and power.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco7} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Insightful Analytics and Monitoring</h2>
                                                                            <p>Transform data into decisions with our analytics tools. Monitor application performance and user engagement to stay ahead of the curve.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco8} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>UI/UX Design Brilliance</h2>
                                                                            <p>Create captivating user experiences with our UI/UX design tools – because your users deserve the best.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco9} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>API and Integration Expertise</h2>
                                                                            <p>Seamlessly integrate with other software and services through our expertly developed APIs.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco10} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Mobile Development Prowess</h2>
                                                                            <p>Enter the mobile arena with our iOS and Android development platforms, tailored for today’s on-the-go users.</p>
                                                                        </div>
                                                                    </div>
                                                                </Masonry>
                                                            </ResponsiveMasonry>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="operatBrilliance" title="Operational Brilliance for Unmatched Growth">
                                        <div className="testingWrapper">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="masonaryWrap">
                                                            <ResponsiveMasonry columnsCountBreakPoints={{380: 1, 480: 2, 860: 3 }}>
                                                                <Masonry>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco12} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Strategic Business Planning</h2>
                                                                            <p>Navigate your business journey with our clear models, market analysis, and strategic insights.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco13} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Financial Management Simplified</h2>
                                                                            <p>Keep your finances in check with our comprehensive tools for tracking, forecasting, and managing your fiscal responsibilities.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco14} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>HR Management Made Easy</h2>
                                                                            <p>From recruitment to payroll, our HR tools streamline employee management, so you can focus on growth.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco15} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Project Management and Collaboration</h2>
                                                                            <p>Enhance team synergy with tools like Trello, Asana, or Jira – because great collaboration breeds innovation.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco16} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>CRM for Customer Success</h2>
                                                                            <p>Manage customer interactions and sales effortlessly with our dynamic CRM systems.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco17} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Marketing and Branding Genius</h2>
                                                                            <p>Elevate your brand with our digital marketing tools and creative strategies.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco18} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Legal and Compliance Clarity</h2>
                                                                            <p>Navigate the complexities of legal requirements and industry compliance with confidence.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco19} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Sales and Distribution Network</h2>
                                                                            <p>Establish powerful channels for software distribution and sales, tailored to your unique needs.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco22} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Corporate Governance and Structure</h2>
                                                                            <p>Build a solid foundation with our governance and structural strategies.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco23} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Network and Partnerships</h2>
                                                                            <p>Join our network of partners and industry experts for unparalleled support and collaboration.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco21} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Exceptional Customer Support Systems</h2>
                                                                            <p>Deliver outstanding customer service with our comprehensive support systems.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={StartupsIco20} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Scalability Strategies</h2>
                                                                            <p>Plan for the future with our scalability insights, ensuring your business grows seamlessly.</p>
                                                                        </div>
                                                                    </div>
                                                                </Masonry>
                                                            </ResponsiveMasonry>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default Startups;