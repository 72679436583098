// import { useEffect, useState, useRef } from "react";
// import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import caseStudyIco1 from './assets/img/caseStudyIco-1.png';
import caseStudyIco2 from './assets/img/caseStudyIco-2.png';
import caseStudyIco3 from './assets/img/caseStudyIco-3.png';
import caseStudyIco4 from './assets/img/caseStudyIco-4.png';
import caseStudyIco5 from './assets/img/caseStudyIco-5.png';
import caseStudyIco6 from './assets/img/caseStudyIco-6.png';
import ManufacturingImg from './assets/img/aboutImg.png';
import FashionListIco from './assets/img/Ico.png'

const Hospitality = () => {
    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="HospitalityBanner innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Hospitality</h2>
                                    <p>Elevate Your Hospitality Experience with Hummingtec. Discover a World of Innovation for the Hospitality Industry</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <div className="manuFacturListWrap hospitalityListWrap">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco1} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Custom Hospitality Software Solutions</h2>
                                        <p>Revolutionize your guest experience with our bespoke software development. From reservation systems to guest management apps, we craft solutions that cater to the unique needs of your hospitality business.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco2} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Intelligent Property Management Systems (PMS)</h2>
                                        <p>Streamline operations with our advanced PMS. Automate tasks, manage bookings efficiently, and enhance guest satisfaction effortlessly.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco3} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Mobile App Development for Hospitality</h2>
                                        <p>Connect with your guests on-the-go. Our mobile apps offer convenient booking, personalized services, and real-time notifications, elevating the guest experience to new heights.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco4} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>IoT Integration for Smart Rooms:</h2>
                                        <p>Transform guest rooms into smart, connected havens. Control lighting, temperature, and entertainment with IoT technology for a seamless stay.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco5} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>AI-Powered Customer Service Tools</h2>
                                        <p>Enhance guest interactions with AI-driven chatbots and virtual concierges. Provide instant assistance and recommendations, improving service quality and response time.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco5} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Data Analytics for Personalized Experiences:</h2>
                                        <p>Utilize data to understand guest preferences better. Tailor services, create targeted marketing campaigns, and anticipate needs for a truly personalized touch.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco1} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Digital Marketing and SEO for Hospitality</h2>
                                        <p>Boost your online presence. Our digital marketing strategies and SEO services ensure your property stands out in a crowded marketplace.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco2} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>E-Commerce Platforms for Ancillary Services</h2>
                                        <p>Maximize revenue with e-commerce solutions for on-property amenities and services. Easy booking and purchasing for dining, spa services, and more.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco3} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Wi-Fi and Connectivity Solutions</h2>
                                        <p>Offer uninterrupted high-speed internet access to your guests. Our robust Wi-Fi solutions ensure connectivity across your property, enhancing guest satisfaction.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco4} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Virtual Reality (VR) Tours and Experiences</h2>
                                        <p>Invite guests to explore your property virtually. Create immersive VR tours that showcase your facilities and services, making a lasting impression.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco5} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Cybersecurity and Data Protection</h2>
                                        <p>Safeguard your guests’ data with our comprehensive cybersecurity measures. Trust and security are the foundations of guest loyalty.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco5} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Custom CRM Systems for Hospitality</h2>
                                        <p>Manage guest relationships effectively. Our CRM systems help track guest preferences, manage feedback, and foster repeat business.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContBefore">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco1} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Training and Support for Hospitality Technology</h2>
                                        <p>Empower your staff with the knowledge and tools they need. We provide extensive training and support for all our technological solutions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContBefore noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco2} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Sustainable Technology Solutions    </h2>
                                        <p>Embrace eco-friendly practices with our sustainable technology offerings. Reduce your carbon footprint while enhancing operational efficiency.</p>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div> 
                <div className="CommonBtmWrap">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="dataWrap">
                                    <h2>Partner with Hummingtec for a Hospitality Transformation</h2>
                                    <p>At Hummingtec, we understand the unique challenges of the hospitality industry. Our technology solutions are designed to elevate your services, streamline operations, and provide unforgettable experiences for your guests.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
                {/* <div className="CommonBtmWrap">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 botm-p">   
                            <h2>Ready to revolutionize your hospitality business? </h2>                             
                                <p>Book a free consultation to know more about how we can help you.</p>                                   
                               
                            </div>                            
                        </div>
                    </div>
                </div>              */}
            </div>
            <Footer></Footer>
        </>
    );
}
export default Hospitality;