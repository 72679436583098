import { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Footer from "./Footer";
import Header from "./Header";
import aiChatbotsIco from './assets/img/aiChatbots.png';
import aiPoweredTransport from './assets/img/aiPoweredTransport.png';
import DigitalHealthAssistant from './assets/img/DigitalHealthAssistant.png';
import gtaModalTraining from './assets/img/gtaModalTraining.png';
import leadGeneration from './assets/img/leadGeneration.png';
import vehicleRouteOptimization from './assets/img/vehicleRouteOptimization.png';
import AIPoweredTransportSolution from './assets/img/AIPoweredTransportSolution.png';
import OCR from './assets/img/OCR.png';
import ForecastingandPrediction from './assets/img/ForecastingandPrediction.png';
import GarmentDesign from './assets/img/GarmentDesign.png';

const AIML = () => {
    
    const location = useLocation();
    const { pathname } = location;
    return (
        <>
            <Helmet>
                <title>Hummingtec Solutions: Leading AI/ML Innovation for Business Transformation</title>
                <meta name="description" content="Unlock the potential of AI with Hummingtec Solutions. From GPT model training to AI-driven insights, explore how our AI/ML technologies can revolutionize your business operations and fuel growth."/>
                <meta name="keywords" content="AI innovation, ML technology, Hummingtec AI services, vehicle route optimization, GPT model training, image recognition, digital health solutions, AI chatbots, lead generation AI, business transformation"/>
                <link rel="canonical" href="https://www.hummingtec.com/AIML"/>
            </Helmet>
            <Header></Header>
            <div className="mainContent">
                <div className="innerPageIntroData aipageDataWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 sticyDiv">
                                <div className="sticyDiv">
                                    <div className="titleWrap">
                                        <h2>AI/ML</h2>
                                        <p>Explore AI Innovation with Hummingtec Solutions</p>
                                    </div>
                                    <div className="dataWrap">
                                        <p>At Hummingtec Solutions, we're at the forefront of AI technology, delivering cutting-edge solutions across a spectrum of industries. Our expertise spans vehicle route optimization, GPT model training, image recognition and generation, digital health, AI-driven insights and dashboarding, AI chatbots, and lead generation, among others. Discover how our AI services can transform your business operations and drive growth.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <ul className="aiMlList">
                                    <li>
                                        <div className="itemWrap clr1">
                                            <Link to="/AIChatbots">
                                                <div className="imgWrap">
                                                    <img src={aiChatbotsIco} className="img-fluid" alt=""/>
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>AI Chatbots</h2>
                                                </div>
                                            </Link>
                                        </div>
                                        {/* <div className="itemWrap clr2">
                                            <Link to="/aiPowered">
                                                <div className="imgWrap">
                                                    <img src={AIPoweredTransportSolution} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>AI-Powered Transport Solution</h2>
                                                </div>
                                            </Link>
                                        </div> */}
                                        <div className="itemWrap clr2">
                                            <Link to="/opticalCharRec">
                                                <div className="imgWrap">
                                                    <img src={OCR} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Optical Character Recognition & Image Processing</h2>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="itemWrap clr3">
                                            <Link to="/forecastingAndPrediction">
                                                <div className="imgWrap">
                                                    <img src={ForecastingandPrediction} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>AI Driven Forecasting and Prediction</h2>
                                                </div>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap clr5">
                                            <Link to="/generativeAi">
                                                <div className="imgWrap">
                                                    <img src={GarmentDesign} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Generative AI in Garment Design</h2>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="itemWrap clr1">
                                            <Link to="/leadGeneration">
                                                <div className="imgWrap">
                                                    <img src={leadGeneration} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Lead Generation</h2>
                                                </div>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        {/* <div className="itemWrap clr5">
                                            <Link to="/vehicleRouteOpt">
                                                <div className="imgWrap">
                                                    <img src={vehicleRouteOptimization} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Vehicle Route Optimization</h2>
                                                </div>
                                            </Link>
                                        </div> */}
                                        <div className="itemWrap clr2">
                                            <Link to="/aiDrivenIn">
                                                <div className="imgWrap">
                                                    <img src={aiPoweredTransport} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>AI-Driven Insights for Competitive Edge</h2>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="itemWrap clr6">
                                            <Link to="/GPTModelTraining">
                                                <div className="imgWrap">
                                                    <img src={gtaModalTraining} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>GPT Model Training</h2>
                                                </div>
                                            </Link>
                                        </div>
                                        {/* <div className="itemWrap clr1">
                                            <Link to="/digitalHealth">
                                                <div className="imgWrap">
                                                    <img src={DigitalHealthAssistant} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Digital Health Assistant</h2>
                                                </div>
                                            </Link>
                                        </div> */}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="whyHumingtecSec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="titleWrap">
                                    <h2>Why Choose Hummingtec Solutions for Your AI Needs?</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dataWrap">
                                    <p>With Hummingtec Solutions, you gain a partner committed to innovation and excellence. Our team of AI experts is dedicated to developing solutions that not only meet but exceed your expectations. By choosing Hummingtec, you're investing in a future where AI technology propels your business to new heights of success.</p>
                                    <p>Embrace the future with Hummingtec Solutions' AI services. Contact us today to learn how our AI expertise can benefit your business.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default AIML;