import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import Footer from "./Footer";
import Header from "./Header";
import IcoImg from "./assets/img/Ico.png";
import Mobileappdev from "./assets/video/Mobileappdev.mp4";
import MobileAppDevIco1 from "./assets/img/MobileAppDevIco-1.png";
import MobileAppDevIco2 from "./assets/img/MobileAppDevIco-2.png";
import MobileAppDevIco3 from "./assets/img/MobileAppDevIco-3.png";
import MobileAppDevIco4 from "./assets/img/MobileAppDevIco-4.png";
import WebsiteDevelopmentIco6 from "./assets/img/WebsiteDevelopmentIco-6.png";
import CustomerEngIco7 from "./assets/img/CustomerEngIco-7.png";
import CRMIco10 from './assets/img/CRMIco-10.png';


const MobileApplication = () => {
    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="mobileBanner innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Mobile App Development</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="aboutSectionWrap mobileSecWrap">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-md-5">
                                <div className="dataWrap sticyDiv">
                                    <div className="videoWrap">
                                        <svg class="position-absolute" viewBox="0 0 413 367" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M297.773 365.385C240.904 379.987 171.136 291.086 138.891 279.276C75.5722 256.299 -32.89 263.6 9.61542 210.775C29.2559 186.295 70.2956 153.011 85.539 133.9C116.612 95.0327 55.6386 25.4583 110.749 5.05841C165.566 -15.1267 212.176 30.612 266.114 45.6435C301.584 55.736 339.692 50.7971 376.042 55.9508C400.959 59.6013 422.944 80.0012 408.287 99.9716C318.293 222.8 394.216 340.476 297.773 365.385Z" fill="#701A75"></path></svg>
                                        <div className="video">
                                            <video autoPlay loop muted playsInline>
                                                <source src={Mobileappdev} type="video/mp4" />
                                            </video>
                                        </div>
                                    </div>
                                    <p>Ahoy, tech enthusiasts and visionary entrepreneurs! Today we embark on a journey through the bustling realm of Mobile App Development, where ideas transform into digital companions that fit snugly in your pocket.</p>
                                    <p>In this world, apps are more than mere tools; they're your round-the-clock allies, engaging users with sleek interfaces and captivating experiences. Let's navigate through the vital touchpoints that make mobile apps the stars of the digital landscape:</p>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="mobileAppMasonary masonaryWrap">
                                    <ResponsiveMasonry columnsCountBreakPoints={{ 380: 1, 480: 2 }}>
                                        <Masonry>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={MobileAppDevIco1} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>User-Centric Designs</h2>
                                                    <p> Every tap, swipe, and pinch is an intimate conversation between the user and the app. We craft these dialogues with precision, ensuring they're as intuitive as they are beautiful. Our design philosophy centers on empathy, placing the user's needs and comfort at the forefront.</p>
                                                </div>
                                            </div>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={MobileAppDevIco2} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Cross-Platform Mastery</h2>
                                                    <p>In the bustling bazaars of Android to the sleek avenues of iOS, our apps traverse platforms with ease. Using frameworks like Flutter and React Native, we build bridges across ecosystems, ensuring your app speaks the universal language of seamless functionality.</p>
                                                </div>
                                            </div>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={WebsiteDevelopmentIco6} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Performance Optimization</h2>
                                                    <p>Speed and responsiveness are the heartbeats of an app. We fine-tune performance, ensuring your app responds with vigor, whether it's handling data-heavy tasks or just casually browsing through content. Our optimization ensures that your app not only works but thrives.</p>
                                                </div>
                                            </div>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={CustomerEngIco7} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Security</h2>
                                                    <p>Your Digital Safe: In a world teeming with digital pickpockets, security isn't an afterthought; it's woven into the very fabric of our apps. From secure transactions to data protection, we fortify your app, making it a digital safe that earns the user's trust.</p>
                                                </div>
                                            </div>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={MobileAppDevIco3} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Agile Development</h2>
                                                    <p>Our development process is as flexible as it is robust. We embrace changes, adapt with agility, and deliver quality with speed. This approach ensures that your app evolves at the pace of your ideas, always ready to meet the ever-changing market demands.</p>
                                                </div>
                                            </div>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={MobileAppDevIco4} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>AI & ML Integration</h2>
                                                    <p> We imbue apps with intelligence, integrating AI and ML to create experiences that are not just interactive but also insightful. From personalized recommendations to predictive analytics, our apps are a step ahead, anticipating user needs with a touch of digital foresight.</p>
                                                </div>
                                            </div>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={CRMIco10} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>IoT Connectivity</h2>
                                                    <p>In the interwoven world of IoT, our apps act as conduits, connecting the dots between devices, wearables, and more. We harness this connectivity to deliver cohesive experiences that extend the utility and reach of your app.</p>
                                                </div>
                                            </div>
                                        </Masonry>
                                    </ResponsiveMasonry>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="aboutSectionWrap mobApp">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dataWrap">
                                    <p>Mobile app development is the fusion of technological innovation, creative vision, and strategic execution. It's the craft of forging digital experiences that connect, captivate, and consistently add value to every user interaction. As we delve deeper into the myriad of opportunities within the mobile app universe, we're reminded that the boundaries of this space are as limitless as our collective imagination allows.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default MobileApplication;