import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import aboutImg from "./assets/img/aboutImg.png";
const VissionMission = () => {

    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="bannerWrap innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Vision</h2>
                                    <p>Empowering Innovation Through Seamless Technology Solutions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="aboutSectionWrap visionWrap">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="imgWrap">
                                    <img src={aboutImg} class="img-fluid" alt="" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="dataWrap">
                                    <h2>Vision</h2>
                                    <p>Hummingtec aspires to be at the forefront of technological advancements, pioneering next-generation technologies that revolutionize industries and shape the future of innovation.</p>
                                    <p>At Humingtec, we envision a world where technology seamlessly integrates with human ingenuity to drive innovation, efficiency, and growth. Our vision is to be the leading catalyst for transformative software solutions and services that empower businesses and individuals to realize their full potential in the digital age. We strive to create cutting-edge technologies that inspire creativity, foster collaboration, and enhance the way people live, work, and connect globally."</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="aboutSectionWrap missionWrap">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="dataWrap">
                                    <h2>Mission</h2>
                                    <p>At Hummingtec, our mission is not just about delivering software solutions—it's about making a difference. Together, we innovate, collaborate, and inspire, shaping a world where possibilities are limitless and success knows no bounds."</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default VissionMission;