import { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet';
import Modal from 'react-bootstrap/Modal';
import emailjs from 'emailjs-com';
import Footer from "../Footer";
import Header from "../Header";
import opticalCharRecImg2 from "../assets/img/opticalCharRecImg-2.png";
import slide1 from "../assets/img/products/AppointmentBookingSolutionImg/TourismImg-1.jpg";
import slide2 from "../assets/img/products/AppointmentBookingSolutionImg/TourismImg-2.jpg";
import slide3 from "../assets/img/products/AppointmentBookingSolutionImg/TourismImg-3.jpg";
import slide4 from "../assets/img/products/AppointmentBookingSolutionImg/TourismImg-4.jpg";

const ToolsForWebsiteRevamp = () => {
    const location = useLocation();
    const productSlider = {
        dots: false,
        arrows:true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef(null);

    const [ref1, inView1] = useInView({ threshold: 0.5 });
    const [ref2, inView2] = useInView({ threshold: 0.5 });
    const [ref3, inView3] = useInView({ threshold: 0.5 });
    const [ref4, inView4] = useInView({ threshold: 0.5 });
    const [ref5, inView5] = useInView({ threshold: 0.5 });
    const [ref6, inView6] = useInView({ threshold: 0.5 });
    const [ref7, inView7] = useInView({ threshold: 0.5 });
    const [ref8, inView8] = useInView({ threshold: 0.5 });

    useEffect(() => {
        if (inView1) {
            setCurrentSlide(0);
        } else if (inView2) {
            setCurrentSlide(1);
        } else if (inView3) {
            setCurrentSlide(2);
        } else if (inView4) {
            setCurrentSlide(3);
        } else if (inView5) {
            setCurrentSlide(4);
        } else if (inView6) {
            setCurrentSlide(5);
        } else if (inView7) {
            setCurrentSlide(6);
        } else if (inView8) {
            setCurrentSlide(7);
        }
    }, [inView1, inView2, inView3, inView4, inView5, inView6, inView7, inView8]);

    useEffect(() => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(currentSlide);
        }
    }, [currentSlide]);
    
    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="topPageBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div className="titleWrap">
                                    <h2>Tools for Website Revamp Verification</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ProjectSecWrap webRevampToolListWrap">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div className="productImgSlider sticyDiv">
                                    <Slider {...productSlider}  ref={sliderRef}>
                                        <div>
                                            <img src={slide1} alt="" className="img-fluid" />
                                        </div>
                                        <div>
                                            <img src={slide2} alt="" className="img-fluid" />
                                        </div>
                                        <div>
                                            <img src={slide3} alt="" className="img-fluid" />
                                        </div>
                                        <div>
                                            <img src={slide4} alt="" className="img-fluid" />
                                        </div>
                                        <div>
                                            <img src={slide1} alt="" className="img-fluid" />
                                        </div>
                                        <div>
                                            <img src={slide2} alt="" className="img-fluid" />
                                        </div>
                                        <div>
                                            <img src={slide3} alt="" className="img-fluid" />
                                        </div>
                                        <div>
                                            <img src={slide4} alt="" className="img-fluid" />
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="dataWrap">
                                    <h2>Tools for Website Revamp Verification</h2>
                                    <ul>
                                        <li ref={ref1}>
                                            <div className="dataWrap">
                                                <h2>1. Website Performance</h2>
                                                <h3>Google PageSpeed Insights (Free)</h3>
                                                <ul>
                                                    <li><strong>Description - </strong> Analyzes the content of a web page, then generates suggestions to make that page faster.</li>
                                                    <li><strong>How it Helps - </strong> Provides insights on both mobile and desktop performance, including speed scores and detailed recommendations for improvement.</li>
                                                </ul>
                                                <h3>GTmetrix (Free and Paid)</h3>
                                                <ul>
                                                    <li><strong>Description - </strong> Offers in-depth analysis of website performance, including page load details, recommendations, and performance scores.</li>
                                                    <li><strong>How it Helps - </strong> Allows for detailed tracking of page performance over time, providing specific insights into how to speed up your site.</li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li ref={ref2}>
                                            <div className="dataWrap">
                                                <h2>2. Mobile Optimization</h2>
                                                <h3>Google Mobile-Friendly Test (Free)</h3>
                                                <ul>
                                                    <li><strong>Description - </strong> Checks if your website is mobile-friendly and provides suggestions to enhance mobile usability.</li>
                                                    <li><strong>How it Helps - </strong> Ensures that your site meets mobile usability standards, which is crucial given the high volume of mobile traffic.</li>
                                                </ul>
                                                <h3>BrowserStack (Paid)</h3>
                                                <ul>
                                                    <li><strong>Description - </strong> Provides access to real mobile devices and browsers for testing how your site performs on different devices.</li>
                                                    <li><strong>How it Helps - </strong> Ensures that your site is fully responsive and functions well across a variety of mobile devices and browsers.</li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li ref={ref3}>
                                            <div className="dataWrap">
                                                <h2>3. SEO and Lead Generation</h2>
                                                <h3>Ahrefs (Paid)</h3>
                                                <ul>
                                                    <li><strong>Description - </strong> An all-in-one SEO toolset with features for keyword research, backlink tracking, and competitor analysis.</li>
                                                    <li><strong>How it Helps - </strong> Improves your site’s SEO by identifying high-value keywords, tracking backlinks, and analyzing competitors.</li>
                                                </ul>
                                                <h3>Moz (Free and Paid)</h3>
                                                <ul>
                                                    <li><strong>Description - </strong> Offers tools for keyword research, SEO audits, and rank tracking.</li>
                                                    <li><strong>How it Helps - </strong> Provides insights into how to improve your site's search engine ranking through better SEO practices.</li>
                                                </ul>
                                                <h3>Google Search Console (Free)</h3>
                                                <ul>
                                                    <li><strong>Description - </strong> Monitors your website's presence in Google search results and provides tools for troubleshooting issues.</li>
                                                    <li><strong>How it Helps - </strong> Identifies and fixes issues that could affect your site’s performance in search results, such as crawl errors and security issues.</li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li ref={ref4}>
                                            <div className="dataWrap">
                                                <h2>4. User Experience (UX) and Navigation</h2>
                                                <h3>Hotjar (Free and Paid)</h3>
                                                <ul>
                                                    <li><strong>Description - </strong> Provides heatmaps, session recordings, and feedback polls to understand how users interact with your site.</li>
                                                    <li><strong>How it Helps - </strong>  Identifies user behavior patterns and pain points, enabling you to enhance the user experience.</li>
                                                </ul>
                                                
                                                <h3>Crazy Egg (Paid)</h3>
                                                <ul>
                                                    <li><strong>Description - </strong> Offers heatmaps, scroll maps, and A/B testing tools to analyze user behavior. </li>
                                                    <li><strong>How it Helps - </strong> Visualizes where users click and scroll, helping to optimize page layout and improve navigation.</li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li ref={ref5}>
                                            <div className="dataWrap">
                                                <h2>5. Content Quality</h2>
                                                <h3>Grammarly (Free and Paid)</h3>
                                                <ul>
                                                    <li><strong>Description - </strong> Checks grammar, spelling, and punctuation, and offers style and tone suggestions.</li>
                                                    <li><strong>How it Helps - </strong> Ensures that your content is polished and professional, which enhances readability and user engagement.</li>
                                                </ul>
                                                
                                                <h3>Hemingway Editor (Free and Paid)</h3>
                                                <ul>
                                                    <li><strong>Description - </strong> Analyzes text for readability and clarity, highlighting complex sentences and suggesting simpler alternatives.</li>
                                                    <li><strong>How it Helps - </strong>  Improves the clarity and conciseness of your content, making it easier for users to understand and engage with.</li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li ref={ref6}>
                                            <div className="dataWrap">
                                                <h2>6. Technical Issues and Bugs</h2>
                                                <h3>Google Chrome DevTools (Free)</h3>
                                                <ul>
                                                    <li><strong>Description - </strong> A set of web authoring and debugging tools built into Google Chrome.</li>
                                                    <li><strong>How it Helps - </strong> Identifies and resolves technical issues related to HTML, CSS, and JavaScript.</li>
                                                </ul>
                                                
                                                <h3>Sentry (Free and Paid)</h3>
                                                <ul>
                                                    <li><strong>Description - </strong> Monitors and fixes crashes in real-time, providing insights into application performance.</li>
                                                    <li><strong>How it Helps - </strong> Quickly identifies and resolves bugs and errors, improving site reliability and performance.</li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li ref={ref7}>
                                            <div className="dataWrap">
                                                <h2>7. Cross-Browser Compatibility</h2>
                                                <h3>BrowserStack (Paid)</h3>
                                                <ul>
                                                    <li><strong>Description - </strong> Tests your site across various browsers and devices in real-time.</li>
                                                    <li><strong>How it Helps - </strong> Ensures consistent performance and appearance across different browsers and devices.</li>
                                                </ul>
                                                
                                                <h3>LambdaTest (Free and Paid)</h3>
                                                <ul>
                                                    <li><strong>Description - </strong> Provides cross-browser testing on a scalable cloud infrastructure.</li>
                                                    <li><strong>How it Helps - </strong> Allows you to test your website on a wide range of browsers and operating systems to ensure compatibility.</li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li ref={ref8}>
                                            <div className="dataWrap">
                                                <h2>8. Security</h2>
                                                <h3>Qualys SSL Labs (Free)</h3>
                                                <ul>
                                                    <li><strong>Description - </strong> Analyzes the SSL/TLS configuration of your website and grades its security.</li>
                                                    <li><strong>How it Helps - </strong> Ensures your site’s SSL/TLS setup is secure, protecting user data and building trust.</li>
                                                </ul>
                                                
                                                <h3>Sucuri SiteCheck (Free and Paid)</h3>
                                                <ul>
                                                    <li><strong>Description - </strong> Scans your site for malware, security issues, and blacklisting status.</li>
                                                    <li><strong>How it Helps - </strong> Identifies security vulnerabilities and malware, helping you keep your site secure and your users safe.</li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="whyHumingtecSec webRevampToolConclusion">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="titleWrap">
                                    <h2>Conclusion</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dataWrap">
                                    <p>These tools provide a comprehensive suite of features to verify and enhance various aspects of a website revamp. By utilizing these tools, you can ensure that your website is optimized for performance, mobile-friendly, SEO-friendly, user-friendly, free of technical issues, cross-browser compatible, and secure.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default ToolsForWebsiteRevamp;