// import { useEffect, useState, useRef } from "react";
// import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import caseStudyIco1 from './assets/img/caseStudyIco-1.png';
import caseStudyIco2 from './assets/img/caseStudyIco-2.png';
import caseStudyIco3 from './assets/img/caseStudyIco-3.png';
import caseStudyIco4 from './assets/img/caseStudyIco-4.png';
import caseStudyIco5 from './assets/img/caseStudyIco-5.png';
import caseStudyIco6 from './assets/img/caseStudyIco-6.png';
import ManufacturingImg from './assets/img/aboutImg.png';
import FashionListIco from './assets/img/Ico.png'

const Education = () => {
    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="educationBanner innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Education</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <div className="manuFacturListWrap">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco1} className="img-fluid" alt=""/>
                                    </div>
                                    <div className="dataWrap">
                                        <h2>School Management Software: The Core of Modern Education</h2>
                                        <p>Transform your school's administrative operations with our intuitive School Management Software. Our system brings together all facets of school management into one seamless, efficient experience. From attendance to assessments, communications to compliance, manage your entire school's ecosystem with ease.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco2} className="img-fluid" alt=""/>
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Admission Management: Simplifying the Start of the Educational Journey</h2>
                                        <p>Welcome new learners with our streamlined Admission Management system. Designed to simplify the application process, it provides prospective students and their families an easy way to submit information, track application status, and receive updates. Our system ensures no applicant is lost in the shuffle, and administrators can easily oversee the entire admission cycle.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco3} className="img-fluid" alt=""/>
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Fees Management: Financial Efficiency at Your Fingertips</h2>
                                        <p>Take control of your school's financials with our comprehensive Fees Management system. Automate billing, track payments, and generate financial reports with just a few clicks. Parents can view and settle their bills online, reducing paperwork and administrative load. Our platform is secure, reliable, and ready to handle all your financial transactions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContBefore">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco4} className="img-fluid" alt=""/>
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Student/Parent Portal: Bridging the Communication Gap</h2>
                                        <p>Our Student/Parent Portal is the heartbeat of school-home interaction. This dedicated platform allows students and parents to access academic records, school notices, and communicate directly with teachers. It's a hub for homework, schedules, and feedback, empowering families to be actively involved in the educational process.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContBefore noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco5} className="img-fluid" alt=""/>
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Learning Management System (LMS): Igniting the Flame of Knowledge</h2>
                                        <p>Elevate the teaching and learning experience with our versatile Learning Management System (LMS). Our LMS facilitates the creation, distribution, and management of educational content, enabling a collaborative and interactive learning environment. With our LMS, educators can customize learning paths, while students engage with multimedia content, quizzes, and forums, all tailored to their educational needs.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="CommonBtmWrap">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">                                
                                <div className="dataWrap">
                                    <p>Our school management software is not just a tool but a partner in your educational mission, designed to support and enhance the learning environment for all. Discover the potential of a unified educational experience with our solutions.</p> 
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>                
            </div>
            <Footer></Footer>
        </>
    );
}
export default Education;