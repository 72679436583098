import { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

import CustomCRM from './assets/img/CustomCRM.png';
import SalesforceCRM from './assets/img/SalesforceCRM.png';
import ZohoCRM from './assets/img/ZohoCRM.png';
import leadGeneration from './assets/img/leadGeneration.png';
import caseStudyIco1 from './assets/img/caseStudyIco-1.png';
const CRM = () => {

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const initialSection = splitLocation[1] || 'salesforceCrm';

    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="innerPageIntroData crmpageListWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 sticyDiv padRightWrap">
                                <div className="sticyDiv">
                                    <div className="titleWrap">
                                        <h2>CRM</h2>
                                        <p>Optimize Customer Relationships with Hummingtec's Comprehensive CRM Services</p>
                                    </div>
                                    <div className="dataWrap">
                                        <p>In today's competitive business landscape, managing customer relationships effectively is more crucial than ever. At Hummingtec Solutions, we offer an extensive range of Customer Relationship Management (CRM) services designed to enhance your sales, marketing, and customer service efforts. Whether you're looking for industry-leading platforms like Salesforce, Zoho, and SugarCRM, or seeking a bespoke solution with our custom CRM service, we have the expertise to elevate your customer management strategy to new heights.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <ul className="aiMlList">
                                    <li>
                                        <div className="itemWrap clr1">
                                            <Link to="/salesforceCrm">
                                                <div className="imgWrap">
                                                    <img src={SalesforceCRM} className="img-fluid" alt=""/>
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Salesforce CRM</h2>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="itemWrap clr2">
                                            <Link to="/zohoCrm">
                                                <div className="imgWrap">
                                                    <img src={ZohoCRM} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Zoho CRM</h2>
                                                </div>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap clr3">
                                            <Link to="/CustomCrm">
                                                <div className="imgWrap">
                                                    <img src={CustomCRM} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Custom CRM</h2>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="itemWrap clr4">
                                            <Link to="/SugarCRM">
                                                <div className="imgWrap">
                                                    <img src={leadGeneration} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>SugarCRM Development</h2>
                                                </div>
                                            </Link>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="staffAugBenefitsWrap benefitsCrmWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="ttlWrap">
                                    <h2>Why Choose Hummingtec for Your CRM Needs?</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <ul>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="imgWrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 66 66">
                                                    <g>
                                                        <path d="M24.4 58.6h17.2v2H24.4zM24 6.9h17.8v2H24zM49.685 12.861l1.734-.998 8.683 15.086-1.734.998zM49.716 53.551l8.888-15.188 1.726 1.01-8.888 15.188zM44.1 49.4c-.5 0-1-.4-1-.9l-.5-6c0-.6.4-1 .9-1.1.6-.1 1 .4 1.1.9l.5 6c0 .6-.4 1.1-1 1.1z" fill="#9ceebd" opacity="1" data-original="#9ceebd"></path>
                                                        <path d="M33 50.6c-5.6 0-11.2-.9-16.7-2.6-.5-.1-.7-.6-.7-1.1C17.1 31.7 19.3 31 20.1 31l8.4-1.6c.3-.1.6 0 .8.2 2.4 1.8 4.9 1.8 7.6 0 .2-.2.5-.2.8-.2l8 1.6c.8 0 3 .7 4.9 15.9.1.5-.2.9-.7 1.1-5.6 1.8-11.3 2.6-16.9 2.6zm-15.3-4.2c10.1 3 20.5 3 30.8 0-.8-5.9-2.2-12.4-3.1-13.3l-7.7-1.5c-3.2 2-6.4 2-9.3 0l-8 1.5c-.9 1-2.1 7.4-2.7 13.3z" fill="#9ceebd" opacity="0.45" data-original="#9ceebd"></path>
                                                        <path d="M21.9 49.4h-.1c-.5-.1-.9-.6-.9-1.1l.7-6c.1-.5.6-.9 1.1-.9.5.1.9.6.9 1.1l-.7 6c-.1.5-.5.9-1 .9zM32.9 27.5c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7zm0-12c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5zM33 37.8c-.3 0-.5-.1-.7-.3l-2.7-2.7c-.2-.2-.3-.5-.3-.8s.2-.6.5-.7l2.7-1.7c.3-.2.7-.2 1.1 0l2.7 1.7c.3.2.4.4.5.7 0 .3-.1.6-.3.8l-2.7 2.7c-.3.2-.5.3-.8.3zm-1.1-3.5 1.1 1.1 1.1-1.1-1.1-.7z" fill="#9ceebd" opacity="0.45" data-original="#9ceebd"></path>
                                                        <path d="M33 50.6c-.3 0-.6-.1-.8-.4l-2.7-3.5c-.2-.3-.3-.6-.2-.9l2.7-9.2c.1-.4.5-.7 1-.7.4 0 .8.3 1 .7l2.7 9.2c.1.3 0 .6-.2.9l-2.7 3.5c-.2.3-.5.4-.8.4zm-1.6-4.7L33 48l1.6-2.1-1.6-5.4zM5.92 39.17l1.73-1.002 8.817 15.224-1.73 1.002zM5.993 27.011l8.832-15.346 1.734.998L7.727 28.01zM47.2 14.5c-3.6 0-6.6-3-6.6-6.6s3-6.6 6.6-6.6 6.6 3 6.6 6.6-3 6.6-6.6 6.6zm0-11.2c-2.5 0-4.6 2.1-4.6 4.6s2.1 4.6 4.6 4.6 4.6-2.1 4.6-4.6-2.1-4.6-4.6-4.6z" fill="#9ceebd" opacity="0.45" data-original="#9ceebd"></path>
                                                        <path d="M18.8 14.5c-3.6 0-6.6-3-6.6-6.6s3-6.6 6.6-6.6 6.6 3 6.6 6.6-3 6.6-6.6 6.6zm0-11.2c-2.5 0-4.6 2.1-4.6 4.6s2.1 4.6 4.6 4.6 4.6-2.1 4.6-4.6-2.1-4.6-4.6-4.6zM58.4 39.7c-3.6 0-6.6-3-6.6-6.6s3-6.6 6.6-6.6 6.6 3 6.6 6.6-3 6.6-6.6 6.6zm0-11.2c-2.5 0-4.6 2.1-4.6 4.6s2.1 4.6 4.6 4.6 4.6-2.1 4.6-4.6-2.1-4.6-4.6-4.6zM7.6 39.7c-3.6 0-6.6-3-6.6-6.6s3-6.6 6.6-6.6 6.6 3 6.6 6.6-3 6.6-6.6 6.6zm0-11.2c-2.5 0-4.6 2.1-4.6 4.6s2.1 4.6 4.6 4.6 4.6-2.1 4.6-4.6-2.1-4.6-4.6-4.6zM18.8 64.7c-3.6 0-6.6-3-6.6-6.6s3-6.6 6.6-6.6 6.6 3 6.6 6.6-3 6.6-6.6 6.6zm0-11.2c-2.5 0-4.6 2.1-4.6 4.6s2.1 4.6 4.6 4.6 4.6-2.1 4.6-4.6-2.1-4.6-4.6-4.6zM47.2 64.7c-3.6 0-6.6-3-6.6-6.6s3-6.6 6.6-6.6 6.6 3 6.6 6.6-3 6.6-6.6 6.6zm0-11.2c-2.5 0-4.6 2.1-4.6 4.6s2.1 4.6 4.6 4.6 4.6-2.1 4.6-4.6-2.1-4.6-4.6-4.6z" fill="#9ceebd" opacity="1" data-original="#9ceebd"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Expert Guidance</h2>
                                                <p>Our team of CRM experts brings deep knowledge and extensive experience across various platforms, ensuring you receive skilled guidance and best practices.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="imgWrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 512 512">
                                                    <g>
                                                        <path d="M326.623 133.062c13.785 0 25-11.215 25-25s-11.215-25.001-25-25.001-25 11.216-25 25.001c0 5.63 1.872 10.83 5.025 15.014l-41.076 57.401a24.9 24.9 0 0 0-7.784-1.247c-6.569 0-12.551 2.551-17.017 6.709l-27.379-15.536a24.96 24.96 0 0 0 .561-5.257c0-13.785-11.215-25-25-25s-25 11.215-25 25c0 1.803.196 3.561.561 5.257l-27.379 15.536c-4.466-4.158-10.448-6.708-17.017-6.708-13.785 0-25 11.216-25 25.001s11.215 25.001 25 25.001 25-11.216 25-25.001a24.96 24.96 0 0 0-.561-5.257l27.379-15.536c4.466 4.158 10.448 6.709 17.017 6.709 6.569 0 12.55-2.551 17.017-6.708l27.379 15.536a24.96 24.96 0 0 0-.561 5.257c0 13.785 11.215 25.001 25 25.001 13.786 0 25.001-11.216 25.001-25.001a24.871 24.871 0 0 0-5.026-15.016l41.076-57.401a24.923 24.923 0 0 0 7.784 1.246zm0-35.001c5.514 0 10 4.486 10 10.001 0 5.514-4.486 10-10 10s-10-4.486-10-10c-.001-5.515 4.485-10.001 10-10.001zm-206.508 116.17c-5.514 0-10-4.486-10-10.001s4.486-10.001 10-10.001 10 4.486 10 10.001-4.486 10.001-10 10.001zm68.836-39.085c-5.514 0-10-4.486-10-10.001 0-5.514 4.486-10 10-10s10 4.486 10 10c.001 5.515-4.486 10.001-10 10.001zm68.836 39.085c-5.514 0-10-4.486-10-10.001s4.486-10.001 10-10.001c5.515 0 10.001 4.486 10.001 10.001s-4.487 10.001-10.001 10.001z" fill="#9ccdee" opacity="1" data-original="#9ccdee"></path>
                                                        <path d="M418.796 376.965H21.215A6.222 6.222 0 0 1 15 370.75V61.331a6.222 6.222 0 0 1 6.215-6.215h35.356c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5H21.215C9.517 40.116 0 49.633 0 61.331V370.75c0 11.698 9.517 21.215 21.215 21.215h51.713v16.506c0 9.403 7.65 17.053 17.053 17.053h75.074l-35.34 68.998a7.5 7.5 0 0 0 13.35 6.838l38.843-75.836h82.922l38.843 75.836a7.498 7.498 0 0 0 10.094 3.257 7.5 7.5 0 0 0 3.256-10.095l-35.34-68.998h75.074c9.403 0 17.053-7.649 17.053-17.053v-16.506h44.986c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5zm-59.986 31.506a2.055 2.055 0 0 1-2.053 2.053H89.981a2.055 2.055 0 0 1-2.053-2.053v-16.506H358.81v16.506zM86.571 55.116h338.951a6.222 6.222 0 0 1 6.215 6.215v71.311c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5V61.331c0-11.698-9.517-21.215-21.215-21.215H264.995V25.374c0-10.375-8.441-18.816-18.817-18.816H200.56c-10.375 0-18.817 8.441-18.817 18.816v14.742H86.571c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5zm110.172-29.742a3.82 3.82 0 0 1 3.817-3.816h45.618a3.821 3.821 0 0 1 3.817 3.816v14.742h-53.251V25.374z" fill="#9ccdee" opacity="1" data-original="#9ccdee"></path>
                                                        <path d="M104.902 267.576a7.5 7.5 0 0 0-7.5 7.5v66.272a7.5 7.5 0 0 0 7.5 7.5h30.427a7.5 7.5 0 0 0 7.5-7.5v-66.272a7.5 7.5 0 0 0-7.5-7.5h-30.427zm22.927 66.273h-15.427v-51.272h15.427v51.272zM173.737 229.248a7.5 7.5 0 0 0-7.5 7.5v104.601a7.5 7.5 0 0 0 7.5 7.5h30.427a7.5 7.5 0 0 0 7.5-7.5V236.748a7.5 7.5 0 0 0-7.5-7.5h-30.427zm22.928 104.601h-15.427v-89.601h15.427v89.601zM242.573 267.576a7.5 7.5 0 0 0-7.5 7.5v66.272a7.5 7.5 0 0 0 7.5 7.5H273a7.5 7.5 0 0 0 7.5-7.5v-66.272a7.5 7.5 0 0 0-7.5-7.5h-30.427zm22.927 66.273h-15.427v-51.272H265.5v51.272zM311.409 311.832a7.5 7.5 0 0 0-7.5 7.5v22.017a7.5 7.5 0 0 0 7.5 7.5h30.427c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-22.927v-14.517a7.5 7.5 0 0 0-7.5-7.5zM508.175 384.019l-43.027-74.526c10.901-11.619 18.839-25.816 23.079-41.64 6.777-25.291 3.299-51.708-9.793-74.384-27.026-46.813-87.097-62.906-133.907-35.881-46.811 27.025-62.906 87.096-35.88 133.907 13.092 22.676 34.231 38.896 59.522 45.674a98.565 98.565 0 0 0 25.53 3.382c7.442 0 14.841-.856 22.077-2.55l43.018 74.528c5.273 9.137 14.875 14.25 24.737 14.249 4.833 0 9.729-1.229 14.208-3.813 8.796-5.077 14.261-14.544 14.261-24.706 0-4.979-1.322-9.902-3.825-14.24zM372.051 322.68c-21.421-5.74-39.326-19.479-50.415-38.685-22.89-39.648-9.257-90.526 30.39-113.417 13.043-7.53 27.296-11.106 41.374-11.106 28.715 0 56.683 14.893 72.043 41.497 11.088 19.206 14.034 41.58 8.294 63.002-5.74 21.422-19.479 39.325-38.685 50.414-19.205 11.089-41.579 14.033-63.001 8.295zm118.191 87.293c-6.453 3.726-14.733 1.508-18.456-4.942l-41.388-71.704a99.523 99.523 0 0 0 12.156-5.951 99.317 99.317 0 0 0 11.23-7.566l41.4 71.707A13.528 13.528 0 0 1 497 398.26a13.56 13.56 0 0 1-6.758 11.713z" fill="#9ccdee" opacity="0.45" data-original="#9ccdee"></path>
                                                        <path d="M461.542 263.611c5.295-17.056 4.034-35.316-3.551-51.416a7.5 7.5 0 1 0-13.569 6.392c5.983 12.7 6.976 27.109 2.795 40.575-4.204 13.54-13.286 24.903-25.573 31.997-13.002 7.508-28.151 9.5-42.652 5.615-14.502-3.886-26.624-13.187-34.13-26.189-15.497-26.841-6.267-61.285 20.574-76.782a56.131 56.131 0 0 1 62.051 3.857 7.498 7.498 0 0 0 10.506-1.455 7.5 7.5 0 0 0-1.456-10.506 71.1 71.1 0 0 0-78.602-4.887c-16.472 9.51-28.255 24.866-33.178 43.238s-2.396 37.562 7.114 54.034c9.51 16.473 24.866 28.255 43.238 33.179a71.6 71.6 0 0 0 18.546 2.457c12.338 0 24.517-3.236 35.488-9.571 15.567-8.986 27.073-23.382 32.399-40.538z" fill="#9ccdee" opacity="0.45" data-original="#9ccdee"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Tailored Strategies</h2>
                                                <p>We understand that every business is unique. That's why we offer customized CRM strategies that align with your specific objectives and challenges.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="imgWrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 512 512">
                                                    <g>
                                                        <path d="M472 16H312a24.028 24.028 0 0 0-24 24v96a24.028 24.028 0 0 0 24 24h36.687l13.656 13.657a8 8 0 0 0 11.314 0L387.313 160H472a24.028 24.028 0 0 0 24-24V40a24.028 24.028 0 0 0-24-24zm8 120a8.009 8.009 0 0 1-8 8h-88a8 8 0 0 0-5.657 2.343L368 156.687l-10.343-10.344A8 8 0 0 0 352 144h-40a8.009 8.009 0 0 1-8-8V40a8.009 8.009 0 0 1 8-8h160a8.009 8.009 0 0 1 8 8z" fill="#cb9cee" opacity="0.45" data-original="#cb9cee"></path>
                                                        <path d="M376 56h-32v16h12.686l-34.343 34.343 11.314 11.314L368 83.314V96h16V64a8 8 0 0 0-8-8zM400 48h64v16h-64zM400 80h64v16h-64zM400 112h64v16h-64zM424 408a40.045 40.045 0 0 0 40-40V184h-16v144H64V128a24.028 24.028 0 0 1 24-24h184V88H88a40.045 40.045 0 0 0-40 40v240a40.045 40.045 0 0 0 40 40h99.867l-17.143 40H136a24 24 0 0 0 0 48h240a24 24 0 0 0 0-48h-34.724l-17.143-40zM64 368v-24h384v24a24.028 24.028 0 0 1-24 24H88a24.028 24.028 0 0 1-24-24zm320 104a8.009 8.009 0 0 1-8 8H136a8 8 0 0 1 0-16h240a8.009 8.009 0 0 1 8 8zm-60.133-24H188.133l17.143-40h101.448z" fill="#cb9cee" opacity="1" data-original="#cb9cee"></path>
                                                        <path d="M248 360h16v16h-16zM88 144v152a8 8 0 0 0 8 8h160v-16h-24v-96h-16v96h-16v-64h-16v64h-16v-80h-16v80h-16v-48h-16v48h-16V144zM416 192H296a8 8 0 0 0-8 8v32a8 8 0 0 0 8 8h120a8 8 0 0 0 8-8v-32a8 8 0 0 0-8-8zm-112 16h64v16h-64zm104 16h-24v-16h24zM416 304a8 8 0 0 0 8-8v-32a8 8 0 0 0-8-8H296a8 8 0 0 0-8 8v32a8 8 0 0 0 8 8zm-8-16h-72v-16h72zm-104-16h16v16h-16z" fill="#cb9cee" opacity="0.45" data-original="#cb9cee"></path>
                                                        <path d="M120 208h16v16h-16zM152 176h16v16h-16zM184 192h16v16h-16zM216 160h16v16h-16z" fill="#cb9cee" opacity="0.45" data-original="#cb9cee"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Seamless Integration</h2>
                                                <p>We ensure your chosen CRM solution works harmoniously with your existing technology stack, providing a seamless, integrated experience for your team.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="imgWrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 64 64">
                                                    <g>
                                                        <path d="M59.506 27.903A27.322 27.322 0 0 0 51.47 9.43C46.267 4.226 39.352 1.361 32 1.361c-14.849 0-26.982 11.819-27.505 26.542a6.474 6.474 0 0 0-3.852 5.911v7.262a6.475 6.475 0 0 0 6.468 6.468 4.19 4.19 0 0 0 4.185-4.185V31.53c0-2.167-1.662-3.934-3.775-4.144C8.301 14.559 18.979 4.361 32 4.361c6.552 0 12.713 2.554 17.35 7.191 4.258 4.258 6.75 9.808 7.125 15.835-2.111.212-3.77 1.978-3.77 4.143v11.828c0 2.185 1.688 3.963 3.826 4.148v2.989a6.111 6.111 0 0 1-6.105 6.104h-4.521a4.497 4.497 0 0 0-4.267-3.038h-4.66c-.669 0-1.311.142-1.899.416a4.547 4.547 0 0 0-2.64 4.122c0 1.214.473 2.354 1.33 3.207a4.504 4.504 0 0 0 3.209 1.332h4.66c1.932 0 3.635-1.249 4.27-3.039h4.518c5.021 0 9.105-4.084 9.105-9.104v-3.523a6.473 6.473 0 0 0 3.826-5.898v-7.262c0-2.634-1.586-4.902-3.851-5.909zM8.295 31.53v11.828c0 .653-.531 1.185-1.185 1.185a3.472 3.472 0 0 1-3.468-3.468v-7.262a3.472 3.472 0 0 1 3.468-3.468c.654 0 1.185.532 1.185 1.185zm34.848 26.891a1.546 1.546 0 0 1-1.506 1.219h-4.66c-.41 0-.795-.16-1.089-.454a1.523 1.523 0 0 1-.45-1.085 1.542 1.542 0 0 1 1.539-1.538h4.66c.41 0 .795.159 1.088.453.29.289.45.675.45 1.085.001.11-.011.22-.032.32zm17.214-17.346a3.472 3.472 0 0 1-3.468 3.468 1.186 1.186 0 0 1-1.185-1.185V31.53c0-.653.531-1.185 1.185-1.185a3.472 3.472 0 0 1 3.468 3.468z" fill="#dcdc30" opacity="1" data-original="#dcdc30"></path>
                                                        <path d="M41.713 41.592a6.363 6.363 0 0 0 6.356-6.356V22.285c0-1.694-.662-3.29-1.864-4.492s-2.797-1.864-4.492-1.864H22.287a6.363 6.363 0 0 0-6.356 6.356v12.951a6.363 6.363 0 0 0 6.356 6.356h.119v3.356a3.109 3.109 0 0 0 3.112 3.124 3.05 3.05 0 0 0 2.196-.927l5.583-5.553zm-10.091-2.564-6.042 6.009c-.027.028-.047.049-.104.024-.069-.028-.069-.073-.069-.113v-4.856a1.5 1.5 0 0 0-1.5-1.5h-1.619a3.36 3.36 0 0 1-3.356-3.356V22.285a3.36 3.36 0 0 1 3.356-3.356h19.426c.894 0 1.735.35 2.371.985.636.636.985 1.478.985 2.371v12.951a3.36 3.36 0 0 1-3.356 3.356H32.68c-.397 0-.777.157-1.058.436z" fill="#dcdc30" opacity="1" data-original="#dcdc30"></path>
                                                        <path d="M24.713 26.787c-1.22 0-2.213.994-2.213 2.213s.994 2.213 2.213 2.213c1.221 0 2.215-.994 2.215-2.213s-.993-2.213-2.215-2.213zM31.999 26.787c-1.22 0-2.213.994-2.213 2.213s.994 2.213 2.213 2.213c1.222 0 2.215-.994 2.215-2.213s-.993-2.213-2.215-2.213zM39.285 26.787c-1.22 0-2.213.994-2.213 2.213s.994 2.213 2.213 2.213c1.221 0 2.215-.994 2.215-2.213s-.994-2.213-2.215-2.213z" fill="#dcdc30" opacity="0.35" data-original="#dcdc30"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Ongoing Support</h2>
                                                <p>From initial implementation to ongoing maintenance, our dedicated support team is here to assist with any issues or updates, ensuring your CRM system continually drives value for your business.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="whyHumingtecSec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="titleWrap">
                                    <h2>Elevate Your Customer Management with Hummingtec Solutions</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dataWrap">
                                    <p>Leverage Hummingtec's CRM services to build stronger customer relationships, streamline your sales and marketing efforts, and drive business growth. Whether you're adopting a CRM for the first time or looking to optimize your current system, our tailored solutions and expert guidance can help you achieve your customer management goals.</p>
                                    <p>Ready to transform your CRM strategy? Contact Hummingtec Solutions today to find out how we can help your business thrive.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default CRM;