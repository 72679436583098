import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Footer from "./Footer";
import Header from "./Header";
import aboutImg from "./assets/img/aboutImg.png";
import { jobDescriptions } from "./JobList";
const Careers = () => {

    return (
        <>
            <Helmet>
                <title>Join Our Team: Careers at Humingtec | Innovate, Impact, Grow</title>
                <meta name="description" content="Ready to make an impact? Explore careers at Humingtec and join a team dedicated to innovation, collaboration, and making a difference in technology. Discover opportunities for growth and learn about our culture."/>
                <meta name="keywords" content="Hummingtec careers, technology jobs, innovation, collaboration, professional growth, tech industry careers, software development jobs, AI and ML careers, blockchain technology, CRM solutions careers"/>
                <link rel="canonical" href="https://www.hummingtec.com/Careers"/>
            </Helmet>
            <Header></Header>
            <div className="mainContent">
                <div className="bannerWrap innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Careers</h2>
                                    <p>Join Our Team at Humingtec</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="aboutSectionWrap padBtmLess careersIntroWrap">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="dataWrap">
                                    <p>Welcome to Humingtec's Careers page! We're thrilled that you're considering joining our team of passionate professionals who are dedicated to driving innovation and making a positive impact in the world of technology.</p>
                                    <p>At Humingtec, we believe in fostering a culture of collaboration, creativity, and continuous learning. Our diverse team is united by a shared vision to push boundaries, solve complex challenges, and deliver exceptional solutions to our clients and partners. Whether you're a seasoned expert in your field or a fresh graduate eager to embark on an exciting career journey, we offer opportunities for growth, development, and meaningful work that makes a difference.</p>
                                    <p>Explore our current openings, learn more about our culture, and discover why Humingtec could be the perfect place for you to thrive professionally and personally. Join us as we shape the future of technology together at Humingtec!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="vacanciesWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="titleWrap">
                                    <h2>Current Vacancies</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {jobDescriptions.map((job, index) => (
                                <div key={index} className="col-md-4">
                                    <div className="itemWrap">
                                        <h2>{job.title}</h2>
                                        <span><strong>Experience - </strong> 3-4Yrs</span>
                                        <p>{job.introduction}</p>
                                        <Link to={`/careers/${encodeURIComponent(job.title)}`}>Apply Now</Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default Careers;