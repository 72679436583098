import React from 'react';
import './App.css';
import { Route, Routes } from "react-router-dom";
import Home from './Components/Home';
import Manufacturing from './Components/Manufacturing';
import CaseStudy from './Components/CaseStudy';
import Fashion from './Components/Fashion';
import Education from './Components/Education';
import Healthcare from './Components/Healthcare'
import Mobility from './Components/Mobility';
import Hospitality from './Components/Hospitality';
import LearningManagement from './Components/LearningManagement';
import MetaHOS from './Components/MetaHOS';
import Symbio from './Components/Symbio';
import Verge from './Components/Verge';
import CustomApplication from './Components/CustomApplication';
import DigitalTransformation from './Components/DigitalTransformation';
import MobileApplication from './Components/MobileApplication';
import Testing from './Components/Testing';
import CRM from './Components/CRM';
import AIML from './Components/AIML';
import Contact from './Components/Contact';
import Solutions from './Components/Solutions';
import SMBs from './Components/SMBs';
import Startups from './Components/Startups';
import EnterprisesCustomers from './Components/EnterprisesCustomers';
import AboutUs from './Components/About';
import Leadership from './Components/Leadership';
import Careers from './Components/Careers';
import VissionMission from './Components/VissionMission';
import PrivacyPolicy from './Components/PrivacyPolicy';
import Disclaimer from './Components/Disclaimer';
import TermsConditions from './Components/TermsConditions';
import CareersDetails from './Components/CareersDetails';
import AIMLDetails from './Components/AIMLDetails';
import DigitalTransformationDtls from './Components/DigitalTransformationDetails.js';
import CRMDetails from './Components/CRMDetails';
import StaffAugmentation from './Components/StaffAugmentation.js';
import ReactJs from './Components/ReactJs.js';
import DedicatedTeams from './Components/DedicatedTeams.js';
import Python from './Components/Python.js';
import NodeJs from './Components/NodeJs.js';
import DotNET from './Components/DotNET.js';
import Java from './Components/Java.js';
import PHP from './Components/PHP.js';
import Angular from './Components/Angular.js';
import Pricing from './Components/Pricing.js';
// import Internship from './Components/Internship.js';
import IntelligentChatAutomation from './Components/IntelligentChatAutomation.js';
import AIDrivenEComSolutions from './Components/AIDrivenEComSolutions.js';
import AIPoweredAppBooking from './Components/AIPoweredAppBooking.js';
import Construction from './Components/Construction.js';

import WebsiteRevamp from './Components/Blogs/WebsiteRevamp.js';
import ToolsForWebsiteRevamp from './Components/Blogs/ToolsForWebsiteRevamp.js';
import DesignTrendsIn2024 from './Components/Blogs/DesignTrendsIn-2024.js';
import PowerBI from './Components/Blogs/PowerBI.js';
import BusinessAnalytics from './Components/Blogs/BusinessAnalytics.js';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} exact></Route>
        <Route path="/Manufacturing" element={<Manufacturing />}></Route>
        <Route path="/Fashion" element={<Fashion/>}></Route>
        <Route path="/Education" element={<Education/>}></Route>
        <Route path="/Healthcare" element={<Healthcare/>}></Route>
        <Route path="/Mobility" element={<Mobility/>}></Route>
        <Route path='/Hospitality' element={<Hospitality/>}></Route> 
        <Route path="/LearningManagement" element={<LearningManagement />}></Route>
        <Route path="/MetaHOS" element={<MetaHOS />}></Route>
        <Route path='/Symbio' element={<Symbio />}></Route>
        <Route path='/Verge' element={<Verge />}></Route>
        <Route path='/CustomApplication' element={<CustomApplication />}></Route>
        <Route path='/DigitalTransformation' element={<DigitalTransformation />}></Route>
        <Route path='/DigitalTransformationDtls' element={<DigitalTransformationDtls />}></Route>
        <Route path='/websiteDevelopment' element={<DigitalTransformationDtls />}></Route>
        <Route path='/seo' element={<DigitalTransformationDtls />}></Route>
        <Route path='/SocialMediaManage' element={<DigitalTransformationDtls />}></Route>
        <Route path='/CustomerEngagement' element={<DigitalTransformationDtls />}></Route>
        <Route path='/cms' element={<DigitalTransformationDtls />}></Route>
        <Route path='/WorkflowAutomation' element={<DigitalTransformationDtls />}></Route>
        <Route path='/MobileApplication' element={<MobileApplication />}></Route>
        <Route path='/Testing' element={<Testing />}></Route>
        <Route path='/CRM' element={<CRM />}></Route>
        <Route path='/CRMDetails' element={<CRMDetails />}></Route>
        <Route path='/salesforceCrm' element={<CRMDetails />}></Route>
        <Route path='/zohoCrm' element={<CRMDetails />}></Route>
        <Route path='/CustomCrm' element={<CRMDetails />}></Route>
        <Route path='/SugarCRM' element={<CRMDetails />}></Route>
        <Route path='/AIML' element={<AIML />}></Route>
        <Route path='/AIMLDetails' element={<AIMLDetails />}></Route>
        <Route path='/aiPowered' element={<AIMLDetails />}></Route>
        <Route path='/opticalCharRec' element={<AIMLDetails />}></Route>
        <Route path='/aiDrivenIn' element={<AIMLDetails />}></Route>
        <Route path='/generativeAi' element={<AIMLDetails />}></Route>
        <Route path='/digitalHealth' element={<AIMLDetails />}></Route>
        <Route path='/vehicleRouteOpt' element={<AIMLDetails />}></Route>
        <Route path='/GPTModelTraining' element={<AIMLDetails />}></Route>
        <Route path='/AIChatbots' element={<AIMLDetails />}></Route>
        <Route path='/leadGeneration' element={<AIMLDetails />}></Route>
        <Route path='/OCR' element={<AIMLDetails />}></Route>
        <Route path='/forecastingAndPrediction' element={<AIMLDetails />}></Route>
        <Route path='/Solutions' element={<Solutions />}></Route>
        <Route path='/SMBs' element={<SMBs />}></Route>
        <Route path='/Startups' element={<Startups />}></Route>
        <Route path='/EnterprisesCustomers' element={<EnterprisesCustomers />}></Route>
        <Route path="/CaseStudy" element={<CaseStudy />}></Route>
        <Route path="/AboutUs" element={<AboutUs />}></Route>
        <Route path="/Leadership" element={<Leadership />}></Route>
        <Route path="/Careers" element={<Careers />}></Route>
        {/* <Route path="/Internship" element={<Internship />}></Route> */}
        <Route path="/careers/:title" element={<CareersDetails />} />
        <Route path="/VissionMission" element={<VissionMission />}></Route>
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />}></Route>
        <Route path="/Disclaimer" element={<Disclaimer />}></Route>
        <Route path="/TermsConditions" element={<TermsConditions />}></Route>
        <Route path="/StaffAugmentation" element={<StaffAugmentation />}></Route>
        <Route path="/DedicatedTeams" element={<DedicatedTeams />}></Route>
        <Route path="/ReactJs" element={<ReactJs />}></Route>
        <Route path="/Python" element={<Python />}></Route>
        <Route path="/NodeJs" element={<NodeJs />}></Route>
        <Route path="/DotNET" element={<DotNET />}></Route>
        <Route path="/Java" element={<Java />}></Route>
        <Route path="/PHP" element={<PHP />}></Route>
        <Route path="/Angular" element={<Angular />}></Route>
        <Route path="/Pricing" element={<Pricing />}></Route>
        <Route path="/Contact" element={<Contact />}></Route>
        <Route path='/IntelligentChatAutomation' element={<IntelligentChatAutomation/>}></Route>
        <Route path='/AIDrivenEComSolutions' element={<AIDrivenEComSolutions/>}></Route>
        <Route path='/AIPoweredAppBooking' element={<AIPoweredAppBooking/>}></Route>
        <Route path='/Construction' element={<Construction/>}></Route>

        <Route path='/WebsiteRevamp' element={<WebsiteRevamp/>}></Route>
        <Route path='/ToolsForWebsiteRevamp' element={<ToolsForWebsiteRevamp/>}></Route>
        <Route path='/DesignTrendsIn2024' element={<DesignTrendsIn2024/>}></Route>
        <Route path='/PowerBI' element={<PowerBI/>}></Route>
        <Route path='/BusinessAnalytics' element={<BusinessAnalytics/>}></Route>
      </Routes>
    </>
  );
}

export default App;
