import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Footer from "./Footer";
import Header from "./Header";
import PHPDevelopmentImg1 from "./assets/img/PHPDevelopmentImg-1.png";
import JavaDevelopmentImg1 from "./assets/img/JavaDevelopmentImg-1.png";
import JavaDevelopmentImg2 from "./assets/img/JavaDevelopmentImg-2.png";
import productsliderImg1 from "./assets/img/productsliderImg-1.png";
import productsliderImg2 from "./assets/img/productsliderImg-2.png";
import Modal from 'react-bootstrap/Modal';
import emailjs from 'emailjs-com';
const Java = () => {
    const [lgShow, setLgShow] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        interest: '',
        message: ''
    });
    const [thankYouPopup, setThankYouPopup] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
      
        try {
            const templateParams = {
                ...formData,
            };
            await emailjs.send('service_fo6avff', 'template_bvon8go', templateParams, 'G815HLz7iB0QllWGn');
            setThankYouPopup(true);
            setFormData({
                name: '',
                email: '',
                mobile: '',
                interest: '',
                message: ''
            });
            setLgShow(false);
            setShowThankYou(true);
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('An error occurred while submitting the form. Please try again later.');
        }
    };

    return (
        <>
            <Helmet>
                <title> Custom Java Development Services | Hummingtec Pvt Ltd</title>
                <meta name="description" content="Unlock the potential of Java with custom development services from Hummingtec. Our Java experts specialize in scalable, robust applications designed to enhance business efficiency and performance."/>
                <meta name="keywords" content="Java Development, Java Development Services, humingtec, hummingtec, Hummingtec Pvt Ltd"/>
                <link rel="canonical" href="https://www.hummingtec.com/Java"/>
            </Helmet>
            <Header></Header>
            <div className="mainContent">
                <div className="bannerWrap innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Java Development</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="servTechIntro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="ttlWrap">
                                    <h2>Introduction</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dataWrap">
                                    <p>Java stands as one of the most versatile, secure, and widely-used programming languages for building robust, high-performance applications across platforms. At Hummingtec, we leverage Java's capabilities to deliver scalable, efficient, and reliable software solutions that cater to diverse business needs, from web applications and microservices to mobile apps and enterprise systems.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="staffAugItroWrap flexRevMob">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="dataWrap dataWrapPadLt">
                                    <h2>Why Choose Java for Your Development Projects?</h2>
                                    <p>Java's platform independence, strong memory management, and multithreading capabilities make it an ideal choice for developing cross-platform applications that require reliability, scalability, and high performance. Its extensive ecosystem and robust community support further enhance its appeal for developing complex business solutions.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="imgWrap">
                                    <img src={PHPDevelopmentImg1} className="img-fluid" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="staffAugItroWrap staffAugValueWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="imgWrap">
                                    <img src={JavaDevelopmentImg1} className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="dataWrap dataWrapPadRt">
                                    <h2>Java Frameworks and Applications</h2>
                                    <p>Our team specializes in a range of Java frameworks to ensure the best fit for your project's needs:</p>
                                    <ul>
                                        <li><strong>1. Spring Framework:</strong> For comprehensive infrastructure support for developing Java applications.</li>
                                        <li><strong>2. Hibernate:</strong> An object-relational mapping tool for Java, facilitating data access and manipulation in databases.</li>
                                        <li><strong>3. Apache Struts:</strong> A framework for creating elegant, modern Java web applications.</li>
                                    </ul>
                                    <h3>Applications of Java span various domains, including:</h3>
                                    <div className="btmTags">
                                        <span>Enterprise-level systems (ERP, CRM)</span>
                                        <span>Web and mobile applications</span>
                                        <span>Financial and banking services</span>
                                        <span>Big data analytics</span>
                                        <span>Cloud-based solutions</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="staffAugBenefitsWrap javaBenefitsWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="ttlWrap">
                                    <h2>Our Java Development Expertise</h2>
                                    <p>We offer a comprehensive suite of Java development services, including:</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <ul>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="imgWrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 48 48">
                                                    <g>
                                                        <path d="M9 19.25A2.753 2.753 0 0 0 6.25 22v5c0 .962 1.5.976 1.5 0v-1.25h2.5V27c0 .974 1.5.97 1.5 0v-5A2.753 2.753 0 0 0 9 19.25zm-1.25 5V22a1.251 1.251 0 0 1 2.5 0v2.25zM18.75 22A2.753 2.753 0 0 0 16 19.25h-2a.75.75 0 0 0-.75.75v7c0 .973 1.5.97 1.5 0v-2.25H16A2.753 2.753 0 0 0 18.75 22zM16 23.25h-1.25v-2.5H16a1.251 1.251 0 0 1 0 2.5zM21 19.274c-.375-.003-.75.236-.75.726v7c0 .963 1.5.972 1.5 0v-7c0-.478-.375-.723-.75-.726z" fill="#6cdc99" opacity="1" data-original="#6cdc99"></path>
                                                        <path d="M44 5.25H4A2.753 2.753 0 0 0 1.25 8v32A2.753 2.753 0 0 0 4 42.75h40A2.753 2.753 0 0 0 46.75 40V8A2.753 2.753 0 0 0 44 5.25zM4 6.75h40c.69 0 1.25.56 1.25 1.25v5.25H2.75V8c0-.69.56-1.25 1.25-1.25zm40 34.5H4c-.69 0-1.25-.56-1.25-1.25V14.75h42.5V40c0 .69-.56 1.25-1.25 1.25z" fill="#6cdc99" opacity="1" data-original="#6cdc99"></path>
                                                        <path d="M40.58 27.129a1.752 1.752 0 0 0 .64-2.39l-1.285-2.227c-.472-.81-1.58-1.11-2.391-.641-.657.38-1.508-.116-1.508-.871 0-.965-.785-1.75-1.75-1.75h-2.572c-.965 0-1.75.785-1.75 1.75 0 .76-.85 1.25-1.508.871a1.77 1.77 0 0 0-2.39.64l-1.287 2.227a1.752 1.752 0 0 0 .64 2.39c.662.383.659 1.363 0 1.743a1.752 1.752 0 0 0-.64 2.39l1.286 2.227c.475.822 1.57 1.117 2.391.641.66-.382 1.508.115 1.508.871 0 .965.785 1.75 1.75 1.75h2.572c.965 0 1.75-.785 1.75-1.75 0-.762.847-1.252 1.508-.871.825.475 1.918.186 2.39-.64l1.287-2.227a1.752 1.752 0 0 0-.64-2.39c-.663-.383-.66-1.362 0-1.743zm-.75 3.04a.25.25 0 0 1 .092.343l-1.286 2.226a.252.252 0 0 1-.342.092c-1.643-.949-3.758.285-3.758 2.17a.25.25 0 0 1-.25.25h-2.572a.25.25 0 0 1-.25-.25c0-1.89-2.116-3.12-3.758-2.17a.252.252 0 0 1-.342-.092l-1.286-2.226a.25.25 0 0 1 .092-.342c1.635-.952 1.641-3.393 0-4.34a.25.25 0 0 1-.092-.342l1.286-2.226a.25.25 0 0 1 .342-.092c1.644.952 3.758-.28 3.758-2.17a.25.25 0 0 1 .25-.25h2.572a.25.25 0 0 1 .25.25c0 1.893 2.115 3.12 3.758 2.17a.251.251 0 0 1 .342.092l1.286 2.226a.25.25 0 0 1-.092.342c-1.628.948-1.627 3.4 0 4.34z" fill="#6cdc99" opacity="0.45" data-original="#6cdc99"></path>
                                                        <path d="M33 24.25c-2.067 0-3.75 1.682-3.75 3.75s1.683 3.75 3.75 3.75 3.75-1.682 3.75-3.75-1.683-3.75-3.75-3.75zm0 6c-1.24 0-2.25-1.01-2.25-2.25s1.01-2.25 2.25-2.25 2.25 1.01 2.25 2.25-1.01 2.25-2.25 2.25zM32 10.75h10c.96 0 .978-1.5 0-1.5H32c-.956 0-.975 1.5 0 1.5z" fill="#6cdc99" opacity="0.45" data-original="#6cdc99"></path>
                                                        <circle cx="6" cy="10" r=".75" fill="#6cdc99" opacity="0.45" data-original="#6cdc99"></circle>
                                                        <circle cx="9" cy="10" r=".75" fill="#6cdc99" opacity="0.45" data-original="#6cdc99"></circle>
                                                        <circle cx="12" cy="10" r=".75" fill="#6cdc99" opacity="0.45" data-original="#6cdc99"></circle>
                                                        <path d="M10.53 35.47 8.06 33l2.47-2.47c.66-.66-.383-1.738-1.06-1.06l-3 3a.75.75 0 0 0 0 1.06l3 3c.663.664 1.768-.353 1.06-1.06zM18.53 29.47c-.664-.665-1.746.375-1.06 1.06L19.94 33l-2.47 2.47c-.668.668.384 1.737 1.06 1.06l3-3a.75.75 0 0 0 0-1.06l-3-3zM15.22 29.335c-.353-.126-.78-.03-.931.428l-2 6c-.305.912 1.115 1.4 1.422.474l2-5.999c.143-.429-.137-.777-.49-.903z" fill="#6cdc99" opacity="0.45" data-original="#6cdc99"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Web Application Development</h2>
                                                <p>Crafting dynamic, scalable web applications using Java EE (Enterprise Edition) and frameworks like Spring and JSF (JavaServer Faces).</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="imgWrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 128 128">
                                                    <g>
                                                        <g fill="none" stroke="#9ccdee" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3">
                                                            <path d="M6.3 27.3h18V11h63.5v106H6.3zM22.8 11 6.3 25.9" opacity="1"></path>
                                                        </g>
                                                        <g fill="none" stroke="#9ccdee" stroke-miterlimit="10" stroke-width="3">
                                                            <path d="M16.1 36.3H78v11.2H16.1zM16.1 61.8H54V73H16.1zM16.1 87.2h13.3v13.4H16.1zM63.7 61.8h13.6V73H63.7zM39.3 87.2h13.3v13.4H39.3zM62.2 87.2H77v13.4H62.2zM33.1 47.5v14.3M69.6 47.5v14.3M22.8 74.2v13.4M46 73.1v14.5M69.2 73.1v14.5" opacity="0.45"></path>
                                                        </g>
                                                        <g fill="none" stroke="#9ccdee" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3">
                                                            <path d="m120.9 100.5-10 13.3-9.9-13.2c0-31-.1-55.3-.1-86.3h20c-.1 30.9-.1 55.2 0 86.2zM101.1 100.8h19.3" opacity="0.45"></path>
                                                            <path d="M101.7 24.4c6.6 0 11.8-.1 18.3-.1" opacity="0.45"></path>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Microservices Architecture</h2>
                                                <p>Building resilient, independently deployable microservices with Spring Boot for enhanced scalability and faster deployment.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="imgWrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 32 32">
                                                    <g>
                                                        <path d="M16.5 4h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 1 0 1zM20.5 31h-11A2.503 2.503 0 0 1 7 28.5v-7a.5.5 0 0 1 1 0v7c0 .827.673 1.5 1.5 1.5h11c.827 0 1.5-.673 1.5-1.5v-17a.5.5 0 0 1 1 0v17c0 1.378-1.121 2.5-2.5 2.5z" fill="#cb9cee" opacity="1" data-original="#cb9cee"></path>
                                                        <path d="M22.5 5a.5.5 0 0 1-.5-.5v-1c0-.827-.673-1.5-1.5-1.5h-11C8.673 2 8 2.673 8 3.5v1a.5.5 0 0 1-1 0v-1C7 2.122 8.121 1 9.5 1h11C21.879 1 23 2.122 23 3.5v1a.5.5 0 0 1-.5.5zM14.5 25h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1zM17.5 27h-7a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1zM19.5 23h-9a.5.5 0 0 1 0-1h9a.5.5 0 0 1 0 1zM9 14c-1.379 0-2.5-1.122-2.5-2.5S7.621 9 9 9s2.5 1.122 2.5 2.5S10.379 14 9 14zm0-4c-.827 0-1.5.673-1.5 1.5S8.173 13 9 13s1.5-.673 1.5-1.5S9.827 10 9 10zM9 20a6.98 6.98 0 0 1-4.739-1.862.5.5 0 0 1-.146-.487A3.477 3.477 0 0 1 7.5 15h3a3.48 3.48 0 0 1 3.386 2.65.5.5 0 0 1-.146.487A6.98 6.98 0 0 1 9 20zm-3.827-2.393c2.162 1.801 5.492 1.801 7.654 0A2.48 2.48 0 0 0 10.5 16h-3a2.48 2.48 0 0 0-2.327 1.607z" fill="#cb9cee" opacity="1" data-original="#cb9cee"></path>
                                                        <path d="M9 20c-3.859 0-7-3.14-7-7s3.141-7 7-7 7 3.14 7 7-3.141 7-7 7zM9 7c-3.309 0-6 2.691-6 6s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6zM4.5 22.95c-.276 0-.5-.174-.5-.45v-.1a.5.5 0 0 1 1 0c0 .276-.224.55-.5.55zM4.5 24.95c-.276 0-.5-.174-.5-.45v-.1a.5.5 0 0 1 1 0c0 .276-.224.55-.5.55zM4.5 26.95c-.276 0-.5-.174-.5-.45v-.1a.5.5 0 0 1 1 0c0 .276-.224.55-.5.55zM24.34 27h-.84a.5.5 0 0 1 0-1h.437l.245-1.135a.498.498 0 0 1 .371-.38 4.791 4.791 0 0 0 1.367-.571.5.5 0 0 1 .529.005l.99.633.613-.618-.633-.982a.501.501 0 0 1-.007-.531 4.76 4.76 0 0 0 .57-1.367.502.502 0 0 1 .378-.372l1.139-.253v-.857l-1.139-.253a.502.502 0 0 1-.378-.372 4.788 4.788 0 0 0-.57-1.367.497.497 0 0 1 .006-.53l.634-.991-.612-.612-.991.634a.498.498 0 0 1-.529.005 4.815 4.815 0 0 0-1.367-.571.498.498 0 0 1-.371-.38L23.937 14H23.5a.5.5 0 0 1 0-1h.84a.5.5 0 0 1 .488.395l.267 1.233c.366.111.725.261 1.071.448l1.074-.687a.5.5 0 0 1 .623.068l1.181 1.18a.5.5 0 0 1 .067.623l-.687 1.074c.187.347.337.706.448 1.073l1.235.274a.5.5 0 0 1 .392.488v1.66a.5.5 0 0 1-.392.488l-1.235.274a5.767 5.767 0 0 1-.448 1.072l.686 1.064a.498.498 0 0 1-.065.623l-1.181 1.19a.5.5 0 0 1-.624.069l-1.074-.687a5.815 5.815 0 0 1-1.071.448l-.267 1.233a.5.5 0 0 1-.488.397z" fill="#cb9cee" opacity="0.45" data-original="#cb9cee"></path>
                                                        <path d="M24.553 22.768a.5.5 0 0 1-.211-.953A2.01 2.01 0 0 0 25.5 20a2.002 2.002 0 0 0-2.777-1.843.5.5 0 1 1-.39-.92A3.004 3.004 0 0 1 26.5 20a3.011 3.011 0 0 1-1.736 2.721.498.498 0 0 1-.211.047zM26 10h-6c-1.103 0-2-.897-2-2s.897-2 2-2h6c1.103 0 2 .897 2 2s-.897 2-2 2zm-6-3a1.001 1.001 0 0 0 0 2h6a1.001 1.001 0 0 0 0-2z" fill="#cb9cee" opacity="0.45" data-original="#cb9cee"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Mobile Application Development</h2>
                                                <p>Developing cross-platform mobile applications that deliver a seamless user experience across devices.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="imgWrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 60 60">
                                                    <g>
                                                        <path d="M56 0H4a4 4 0 0 0-4 4v52a4 4 0 0 0 4 4h52a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4Zm2 4v25h-7.8a6 6 0 1 0-10.4 0H31v-9.222a1.77 1.77 0 0 0-.947-1.569 1.749 1.749 0 0 0-1.806.1 4 4 0 1 1-3.012-7.24 4.014 4.014 0 0 1 3.006.617A1.767 1.767 0 0 0 31 10.223V2h25a2 2 0 0 1 2 2ZM4 2h25v7.8a6 6 0 1 0 0 10.4V29h-8.223a1.767 1.767 0 0 0-1.464 2.759 4.023 4.023 0 0 1 .617 3.006 4 4 0 1 1-7.241-3.011 1.746 1.746 0 0 0 .1-1.807A1.77 1.77 0 0 0 11.222 29H2V4a2 2 0 0 1 2-2ZM2 56V31h8.8a6 6 0 0 0 5.206 9 6.231 6.231 0 0 0 1.255-.128A6 6 0 0 0 21.2 31H29v8.223a1.767 1.767 0 0 0 2.759 1.464 4.015 4.015 0 0 1 3.006-.617 4 4 0 1 1-3.011 7.241 1.746 1.746 0 0 0-1.807-.1A1.77 1.77 0 0 0 29 48.778V58H4a2 2 0 0 1-2-2Zm54 2H31v-8.8a6 6 0 0 0 7.652-1.41A6 6 0 0 0 31 38.8V31h9.222a1.77 1.77 0 0 0 1.569-.947 1.746 1.746 0 0 0-.1-1.806 4 4 0 1 1 7.24-3.012 4.023 4.023 0 0 1-.617 3.006A1.767 1.767 0 0 0 49.777 31H58v25a2 2 0 0 1-2 2Z" fill="#dcdc30" opacity="1" data-original="#dcdc30"></path>
                                                        <path d="m35.293 11.707 4 4a1 1 0 0 0 1.414-1.414L37.414 11l3.293-3.293a1 1 0 1 0-1.414-1.414l-4 4a1 1 0 0 0 0 1.414ZM49.293 15.707a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0 0-1.414l-4-4a1 1 0 0 0-1.414 1.414L52.586 11l-3.293 3.293a1 1 0 0 0 0 1.414ZM43.8 16.98a.948.948 0 0 0 .2.02 1 1 0 0 0 .979-.8l2-10a1 1 0 0 0-1.959-.4l-2 10a1 1 0 0 0 .78 1.18ZM10.707 44.293a1 1 0 0 0-1.414 0l-4 4a1 1 0 0 0 0 1.414l4 4a1 1 0 0 0 1.414-1.414L7.414 49l3.293-3.293a1 1 0 0 0 0-1.414ZM24.707 48.293l-4-4a1 1 0 0 0-1.414 1.414L22.586 49l-3.293 3.293a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.414ZM16.2 43.02a.994.994 0 0 0-1.176.784l-2 10a1 1 0 0 0 .776 1.176.948.948 0 0 0 .2.02 1 1 0 0 0 .979-.8l2-10a1 1 0 0 0-.779-1.18Z" fill="#dcdc30" opacity="0.45" data-original="#dcdc30"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Enterprise Solutions</h2>
                                                <p>Creating robust enterprise applications that streamline business processes, improve efficiency, and drive growth.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="staffAugFaqWrap">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-md-8">
                                <div class="ttlWrap ttlCenter">
                                    <h2>FAQ</h2>
                                </div>
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Why is Java considered good for enterprise applications?
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p>Java's platform-independent nature, robust security features, and extensive library ecosystem make it suitable for developing large-scale, distributed enterprise applications.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Can Java be used for mobile app development?
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p>Yes, Java is widely used for Android app development, offering a secure and object-oriented environment for creating high-performing mobile apps.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingThree">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                How does Java ensure the security of applications?
                                            </button>
                                        </h2>
                                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p>Java provides a secure runtime environment, enforcing strict access controls, automatic memory management, and a variety of built-in security features to protect applications from threats.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="staffAugItroWrap staffAugValueWrap dotNetValueWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="imgWrap">
                                    <img src={JavaDevelopmentImg2} className="img-fluid" alt=""/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="dataWrap dataWrapPadRt">
                                    <h2>Why Partner with Us for Your Java Projects?</h2>
                                    <ul>
                                        <li><strong>1. Proven Expertise:</strong> Our team of Java developers brings years of experience and deep technical knowledge to every project.</li>
                                        <li><strong>2. Customized Solutions:</strong> We understand that every business is unique. Our approach is to create custom Java solutions that align with your specific business objectives.</li>
                                        <li><strong>3. Agile and Transparent:</strong> We follow an agile development process to ensure timely delivery and complete transparency throughout the project lifecycle.</li>
                                        <li><strong>4. Comprehensive Support:</strong> From initial consultation to post-launch support, we're with you every step of the way.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="servCaseStudyWrap nodeJsCsStudy">
                    <div class="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="ttlWrap">
                                    <h2>Java Development Case Studies</h2>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-12">
                                <ul>
                                    <li>
                                        <div class="itemWrap bgClr1">
                                            <div className="dataWrap">
                                                <h2>Case Study 1: Scalable Web Application for E-commerce</h2>
                                                <p><strong>Client:</strong> An emerging online retailer.</p>
                                                <p><strong>Solution:</strong> Developed a scalable, secure e-commerce platform using Java, Spring Boot for the backend, and Angular for the frontend, integrating advanced features like real-time inventory management and personalized recommendations.</p>
                                                <p><strong>Outcome:</strong> The platform facilitated a 40% increase in sales and significantly improved customer engagement.</p>
                                            </div>
                                            <div className="imgWrap">
                                                <img src={productsliderImg1} className="img-fluid" alt="" />
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="itemWrap bgClr2">
                                            <div className="imgWrap">
                                                <img src={productsliderImg2} className="img-fluid" alt="" />
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Case Study 2: Enterprise System for Supply Chain Management</h2>
                                                <p><strong>Client:</strong> A multinational logistics company.</p>
                                                <p><strong>Solution:</strong> Implemented a comprehensive supply chain management system using Java EE, optimizing operations, and automating workflow processes.</p>
                                                <p><strong>Outcome:</strong> Enhanced operational efficiency, resulting in a 25% reduction in delivery times and considerable cost savings.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="reachOutWrap">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <div className="dataWrap">
                                <h3>Transform your business with our Java development expertise. Contact <i>Hummingtec</i> today to explore how we can help you achieve your technology goals with the power of Java.</h3>
                                <button className="btn" onClick={() => setLgShow(true)}>Contact Us</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                className="contactUsPopup"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="titleWrap">
                        <h2>Let's get in touch</h2>
                        <p>Kindly fill out the form below, and our team will promptly respond to your inquiries.</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <ul className="formList">
                            <li>
                                <div className="inputField">
                                    <label>Name</label>
                                    <input type="text" name="name" value={formData.name} onChange={handleChange} />
                                </div>
                            </li>
                            <li>
                                <div className="inputField">
                                    <label>Email Address</label>
                                    <input type="email" name="email" value={formData.email} onChange={handleChange} />
                                </div>
                            </li>
                            <li>
                                <div className="inputField">
                                    <label>Mobile Number</label>
                                    <input type="tel" name="mobile" value={formData.mobile} onChange={handleChange} />
                                </div>
                            </li>
                            <li>
                                <div className="inputField selectField">
                                    <label>I'm interested in:</label>
                                    <select name="interest" value={formData.interest} onChange={handleChange}>
                                        <option value="">Select an option</option>
                                        <option>Artificial Intelligence</option>
                                        <option>Machine Learning</option>
                                        <option>Custom GPT</option>
                                        <option>Blockchain/Etherium</option>
                                        <option>E-Commerce/Shopping Cart</option>
                                        <option>Salesforce CRM</option>
                                        <option>Zoho CRM</option>
                                        <option>Custom CRM</option>
                                        <option>Website Development</option>
                                        <option>SEO</option>
                                        <option>Social Media Management</option>
                                        <option>Mobile Application Development</option>
                                        <option>Custom Application Development</option>
                                        <option>Security and Testing</option>
                                        <option>Other</option>
                                    </select>
                                </div>
                            </li>
                            <li className="full">
                                <div className="inputField">
                                    <label>Message</label>
                                    <textarea name="message" value={formData.message} onChange={handleChange}></textarea>
                                </div>
                            </li>
                            <li className="full">
                                <div className="ctaWrap">
                                    <button type="submit" className="btnSubmti">Submit</button>
                                </div>
                            </li>
                        </ul>
                    </form>
                </Modal.Body>
            </Modal>
            <Modal
                size="md"
                show={showThankYou}
                onHide={() => setShowThankYou(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                className="contactUsPopup"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="thankYouPopup">
                        <h3>Thank you for contacting us!</h3>
                        <p>We will respond to your inquiry shortly.</p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default Java;