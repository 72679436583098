import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import caseStudyIco1 from './assets/img/caseStudyIco-1.png';
import caseStudyIco2 from './assets/img/caseStudyIco-2.png';
import caseStudyIco3 from './assets/img/caseStudyIco-3.png';
import caseStudyIco4 from './assets/img/caseStudyIco-4.png';
import caseStudyIco5 from './assets/img/caseStudyIco-5.png';
import caseStudyIco6 from './assets/img/caseStudyIco-6.png';
import caseStudyIco7 from './assets/img/caseStudyIco-6.png';
const CaseStudy = () => {
    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="bannerWrap innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Case Study</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="caseStudyTopWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="titleWrap">
                                    <h2>Optimizing Route Selection for a trucking Company</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="itemWrap">
                                    <h2>Problem Statement</h2>
                                    <p>The client sought to enhance their route selection process to minimize travel time, reduce fuel consumption, and improve delivery punctuality. They needed a custom AI solution that could analyze various parameters (e.g., traffic, weather, delivery schedules) to optimize route choices dynamically.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="itemWrap">
                                    <h2>Client Background</h2>
                                    <p>A trucking Company is a leading logistics firm specializing in the transportation of goods across a wide geographic area. They faced challenges with route planning, leading to inefficiencies, increased fuel costs, and delayed deliveries.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="csaeStudyListWrap">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco1} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Requirement Analysis & Solution Design</h2>
                                        <p>Collaborated closely with XYZ Transport to understand their specific needs, data sources, and desired outcomes.</p>
                                        <p>Designed a custom AI-driven route optimization system using machine learning algorithms.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco2} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Data Collection & Preprocessing</h2>
                                        <p>Integrated real-time data feeds from GPS trackers, traffic APIs, weather services, and historical traffic patterns.</p>
                                        <p>Cleaned and preprocessed the data to remove outliers and ensure consistency.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco3} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Model Development</h2>
                                        <p>Employed a combination of supervised learning and reinforcement learning techniques to train the AI model.</p>
                                        <p>Utilized historical route data, traffic patterns, and weather conditions to predict optimal routes.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco4} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Real-Time Prediction & Optimization</h2>
                                        <p>Developed an intuitive dashboard for dispatchers to input delivery details and receive real-time route suggestions.</p>
                                        <p>The system continuously analyzed incoming data to dynamically update route recommendations based on changing conditions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco5} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Integration & Deployment</h2>
                                        <p>Integrated the AI solution into XYZ Transport's existing fleet management software.</p>
                                        <p>Conducted extensive testing to ensure seamless interoperability with their operational systems.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco6} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Testing & Validation</h2>
                                        <p>Conducted extensive testing using historical data to validate the accuracy and effectiveness of the route recommendations.</p>
                                        <p>Fine-tuned the model based on feedback and performance metrics.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContBefore">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco7} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>User Training & Knowledge Transfer</h2>
                                        <p>Provided training to dispatchers and operations staff on how to use the new route optimization system effectively.</p>
                                        <p>Shared insights into the model's decision-making process and best practices for maximizing its benefits.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="caseStudyBtmWrap">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="itemWrap bgClr1">
                                    <h2>Results</h2>
                                    <p>Reduced average travel time by 15% across all routes.</p>
                                    <p>Decreased fuel consumption by 10%, leading to substantial cost savings.</p>
                                    <p>Improved on-time delivery performance by 20%, enhancing customer
                                        satisfaction.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="itemWrap bgClr2">
                                    <h2>Feedback</h2>
                                    <p>The Company reported a significant improvement in operational efficiency and cost savings. The custom AI solution not only streamlined their route selection process but also provided a competitive edge in the logistics industry.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="itemWrap bgClr3">
                                    <h2>Conclusion</h2>
                                    <p>The successful implementation of the custom AI-driven route optimization system demonstrated the potential of AI in transforming logistics operations. By leveraging real-time data and advanced algorithms, XYZ Transport was able to achieve substantial improvements in their delivery operations, setting a new standard for efficiency in the industry.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default CaseStudy;