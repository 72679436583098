import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import aboutImg from "./assets/img/aboutImg.png";
import serviceImg1 from "./assets/img/serviceImg-1.png";
import serviceImg2 from "./assets/img/serviceImg-2.png";
import serviceImg3 from "./assets/img/serviceImg-3.png";
import serviceImg4 from "./assets/img/serviceImg-4.png";
import ProjectVid from "./assets/video/Project.mp4";
const LearningManagement = () => {
    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="bannerWrap innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Products</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ProjectSecWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="videoWrap">
                                    <div className="video">
                                        <video autoPlay loop muted playsInline>
                                            <source src={ProjectVid} type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="dataWrap">
                                    <h2>Comprehensive Learning Management System (LMS)</h2>
                                    <p>Comprehensive Learning Management System (LMS), a cutting-edge platform designed for seamless education administration. Boasting a user-friendly admin portal, it ensures efficient user data management, offering detailed profiles and access control. The system features robust tools for creating assessments, engaging games, and insightful data visualization to track user progress comprehensively.</p>
                                    <h4>A Comprehensive Learning Management System</h4>
                                    <ul className="padLess">
                                        <li>
                                            <div className="dataWrap">
                                                <h2>Content Management and Customization</h2>
                                                <ul>
                                                    <li><strong>CMS Content Management:</strong> Efficiently manage and create content for the LMS platform.</li>
                                                    <li><strong>Resource and Subcategory Management:</strong> Organize and manage a wide range of educational resources and categories for easy access.</li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataWrap">
                                                <h2>Real-Time Monitoring and Feedback</h2>
                                                <ul>
                                                    <li><strong>Weekly Survey Data Management:</strong> Conduct and manage weekly surveys for continuous feedback and improvement.</li>
                                                    <li><strong>Interactive Dashboard:</strong> A dynamic dashboard provides real-time insights into user engagement, game completion, and performance analytics.</li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataWrap">
                                                <h2>User-Friendly Admin Portal</h2>
                                                <ul>
                                                    <li><strong>Comprehensive User Data Management:</strong> Manage user profiles, status, and access rights seamlessly. Includes functionalities for adding new users and viewing detailed user profiles.</li>
                                                    <li><strong>Robust User Creation Forms:</strong>  Facilitate easy user registration with comprehensive form options.</li>
                                                    <li><strong>Detailed User Results and Dashboard Views:</strong> Track and analyze user performance in assessments and games, providing a clear overview of progress.</li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataWrap">
                                                <h2>Advanced Assessment and Game Management</h2>
                                                <ul>
                                                    <li><strong>Customizable Assessment Tools:</strong> Create and manage assessments tailored to specific learning objectives.</li>
                                                    <li><strong>Innovative Game Integration:</strong> Enhance user engagement through interactive games, with options to manage game settings and progress.</li>
                                                    <li><strong>Assessment Data Visualization:</strong> Assessment Data Visualization: Offers insightful data visualization for better understanding and analysis of user performance.</li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="howWeWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="dataWrap">
                                    <h3>We also make sure that:</h3>
                                    <ul>
                                        <li>We assign the proper specialist for the delivery of the solution as per the client’s request.</li>
                                        <li>Provide Free Project Estimations.</li>
                                        <li>Equip and integrate the latest technologies, tools and trends.</li>
                                        <li>Create a stable team of experts with proper work and cultural code.</li>
                                        <li>Work Flexible Hours to manage clients query from all over the world.</li>
                                        <li>Assured Quality Controls & Checks.</li>
                                        <li>We believe in providing solutions to your business and not just codes.</li>
                                        <li>The extra security of your data and guarantee IP rights.</li>
                                        <li>From backend to front-end, we develop, promote, market and take care of your business.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 padLt">
                                <div className="topService">
                                    <div className="topLeft">
                                        <span>Popular</span>
                                        <h4>Design for how people think</h4>
                                        <p>Aliquam ut euismod condimentum elementum ultricies volutpat sit non. </p>
                                        <a href="#">Take Lesson</a>
                                    </div>
                                    <div className="topRight">
                                        <img src={serviceImg1} className="img-fluid" alt="" />
                                        <div className="posImg">
                                            <img src={serviceImg2} className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="bottomService">
                                    <div className="leftImg">
                                        <img src={serviceImg3} className="img-fluid" alt="" />
                                    </div>
                                    <div className="rightImg">
                                        <img src={serviceImg4} className="img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default LearningManagement;