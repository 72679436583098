import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import IcoImg from "./assets/img/Ico.png";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AutomationTestingIco1 from "./assets/img/AutomationTestingIco-1.png";
import AutomationTestingIco2 from "./assets/img/AutomationTestingIco-2.png";
import AutomationTestingIco3 from "./assets/img/AutomationTestingIco-3.png";
import AutomationTestingIco4 from "./assets/img/AutomationTestingIco-4.png";
import AutomationTestingIco5 from "./assets/img/AutomationTestingIco-5.png";
import AutomationTestingIco6 from "./assets/img/AutomationTestingIco-6.png";
import AutomationTestingIco7 from "./assets/img/AutomationTestingIco-7.png";
import AutomationTestingIco10 from "./assets/img/AutomationTestingIco-10.png";
import AutomationTestingIco11 from "./assets/img/AutomationTestingIco-11.png";

const Testing = () => {
    const [key, setKey] = useState('functionalQA');
    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="TestingBanner innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Security and Testing</h2>
                                    <p>In today's tech-centric world, security isn't a luxury—it's a cornerstone of trust. That's where our Security Testing services come into play. It's our meticulous strategy to uncover and mend the vulnerabilities that might otherwise leave your software exposed.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="testPageTopWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="106" height="91" viewBox="0 0 106 91" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M56.6944 0.0342636C76.833 0.491123 97.5402 8.37687 103.972 27.4618C110.585 47.0842 100.304 67.6011 83.5837 79.8211C66.6919 92.1668 44.3028 95.4381 26.9424 83.7603C7.50212 70.6834 -5.52635 47.2296 2.31758 25.1557C9.70767 4.35902 34.6248 -0.4664 56.6944 0.0342636Z" fill="#D7DBFF"></path>
                                        </svg>
                                        <img src={IcoImg} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Manual Testing</h2>
                                        <p>The Precision Approach: Imagine our manual testing as the craftsman's touch in a world of mass production. It's where our experts, with their keen eye for detail, comb through your system's landscape—uncovering those subtle security risks that automated scans could overlook. We believe in the power of the human element, where scenario-based testing ensures resilience against real-world threats.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="106" height="91" viewBox="0 0 106 91" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M56.6944 0.0342636C76.833 0.491123 97.5402 8.37687 103.972 27.4618C110.585 47.0842 100.304 67.6011 83.5837 79.8211C66.6919 92.1668 44.3028 95.4381 26.9424 83.7603C7.50212 70.6834 -5.52635 47.2296 2.31758 25.1557C9.70767 4.35902 34.6248 -0.4664 56.6944 0.0342636Z" fill="#D7DBFF"></path>
                                        </svg>
                                        <img src={IcoImg} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Automation Testing</h2>
                                        <p>The Efficiency Expert: With automation testing, we bring in the heavy artillery. This isn't just about speed; it's about creating a systematic sweep across your software to identify vulnerabilities with unerring accuracy. It's our way of ensuring that every inch of your digital territory is scanned, vetted, and secured—so you can focus on innovation with peace of mind.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="106" height="91" viewBox="0 0 106 91" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M56.6944 0.0342636C76.833 0.491123 97.5402 8.37687 103.972 27.4618C110.585 47.0842 100.304 67.6011 83.5837 79.8211C66.6919 92.1668 44.3028 95.4381 26.9424 83.7603C7.50212 70.6834 -5.52635 47.2296 2.31758 25.1557C9.70767 4.35902 34.6248 -0.4664 56.6944 0.0342636Z" fill="#D7DBFF"></path>
                                        </svg>
                                        <img src={IcoImg} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Penn Testing</h2>
                                        <p>The Proactive Defense: Penetration testing is akin to a strategic war game for your software. Our specialists emulate the tactics of cyber adversaries to challenge your defenses, uncovering any chink in the armor. The result? Not just a report, but a blueprint for fortifying your systems against the siege of cyber threats.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dataWrap martTop">
                                    <p>When we combine these facets of security testing, we're not just checking boxes; we're ensuring that your software stands as a bastion of security. Your business deserves to operate with confidence, and your customers with assurance. Let's make security your strongest asset in an unpredictable digital landscape.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="testingWrap commponTabWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    className=""
                                >
                                    <Tab eventKey="functionalQA" title="Functional QA">
                                        <div className="testingWrapper">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="dataWrap">
                                                            <h2>Functional QA</h2>
                                                            <p>Due to the extreme ever-changing expectations and demands of end users, the risks of introducing a new software/application without rigorous quality assurance are now larger than ever. Finding independent QA and testing providers who are not influenced by the development unit is critical to a quality product’s success.</p>
                                                            <p>We value this, and as an independent software testing solution provider, we can report on it. Our goal is to assess the functional components’ performance quality. When it comes to functional, GUI, database, and regression testing, we use a framework-based technique.</p>
                                                            <h4>Capabilities</h4>
                                                            <p>Our team has vast experience in automating functional testing services using the industry’s leading testing and quality assurance tools. We can assist you in making the most of these technologies in order to maximize your return on investment.</p>
                                                            <p>We have some key differentiators in the market, expertise in domain specific independent testing.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="masonaryWrap">
                                                            <ResponsiveMasonry columnsCountBreakPoints={{ 380: 1, 480: 2, 860: 3 }}>
                                                                <Masonry>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={AutomationTestingIco6} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>System Testing</h2>
                                                                            <p>System Testing is a level of testing that validates the complete and fully integrated software product. The purpose of a system test is to evaluate the end-to-end system specifications. Usually, the software is only one element of a larger computer-based system.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={AutomationTestingIco7} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>UAT Testing</h2>
                                                                            <p>We simulate real-world circumstances by performing it from the perspective of the end-user. A significant aspect of our test suite worth mentioning is the UX testing and recommend nice to have features.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={AutomationTestingIco1} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Integration Testing</h2>
                                                                            <p>We ensure that each module works in tandem with the others and does not introduce any bugs into the system.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={AutomationTestingIco4} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Regression Testing</h2>
                                                                            <p>We can uncover bugs that are caused by addressing other bugs using regression testing.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={AutomationTestingIco10} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>API Testing</h2>
                                                                            <p>Automated API Validation Testing facilitates DevOps practice, the preferred mode of software development for quicker time to market.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={AutomationTestingIco11} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Mobile Testing</h2>
                                                                            <p>Testing compatibility, functionality, performance and security of mobiles, mobile applications with various OS versions, Brands and Resolutions. Our broad range of mobile devices, virtual devices testing for compatibility testing help us stand out in the market.</p>
                                                                        </div>
                                                                    </div>
                                                                </Masonry>
                                                            </ResponsiveMasonry>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="testingValueWrap">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="titleWrap">
                                                            <h3>Value Perspective</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="itmeWrap afterContent">
                                                            <div className="noWrap">
                                                                <h2>70%</h2>
                                                            </div>
                                                            <div className="dataWrap">
                                                                <p>Reduction In Manual Regression Test Cycle</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="itmeWrap">
                                                            <div className="noWrap">
                                                                <h2>100% </h2>
                                                            </div>
                                                            <div className="dataWrap">
                                                                <p>Test Coverage With The Help Of Real Devices</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="automationTesting" title="Automation Testing">
                                        <div className="testingWrapper">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="dataWrap">
                                                            <h2>Automation Testing</h2>
                                                            <p>Test automation reduces regression testing time and shortens time to market, resulting in significant long-term cost savings. However, ensuring the optimal return on investment for your automation activities requires a clear automation plan and roadmap.</p>
                                                            <p>To provide high reusability, ease of maintenance, and lower upfront costs, a standardized and consistent automation approach is required with diverse application architecture, numerous environments, third-party connections, and multiple user devices.</p>
                                                            <p>We use Agile testing, continuous integration, and test-driven development best practices to help you get new digital services to market faster, expand market coverage, and respond to market feedback more quickly and efficiently.</p>
                                                            <p>We offer vast hands-on experience with the industry’s most popular Test Automation products. We can assist you in making the most of these technologies in order to maximize your return on investment.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="masonaryWrap">
                                                            <ResponsiveMasonry columnsCountBreakPoints={{ 380: 1, 480: 2, 860: 3 }}>
                                                                <Masonry>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={AutomationTestingIco1} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Web Test Automation</h2>
                                                                            <p> Validating the UI and front-end of an application to ensure that it  functions the same in different browsers, platforms, and devices.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={AutomationTestingIco2} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Mobile Test Automation</h2>
                                                                            <p>In a continuous integration workflow, verify and validate the functionality of mobile apps across iOS and Android.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={AutomationTestingIco3} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Database Test  Automation</h2>
                                                                            <p>For greater speed and cheaper overheads, test and validate end-to-end databases and ensure data integrity using an application.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={AutomationTestingIco4} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Websevice Test Automation</h2>
                                                                            <p>Check the behavior of web services connected to your application, you can ensure that it communicates and accesses functionality appropriately.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="itemWrap">
                                                                        <div className="icoWrap">
                                                                            <img src={AutomationTestingIco5} className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="dataWrap">
                                                                            <h2>Cross Browser Testing</h2>
                                                                            <p>Verify that your application works correctly on a variety of browsers and versions.</p>
                                                                        </div>
                                                                    </div>
                                                                </Masonry>
                                                            </ResponsiveMasonry>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="testingValueWrap">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="titleWrap">
                                                            <h3>Value Perspective</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-center">
                                                    <ul>
                                                        <li>
                                                            <div className="dataWrap">
                                                                <h2>10x</h2>
                                                                <p> Faster Testing Release Cycles</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="dataWrap">
                                                                <h2>50% </h2>
                                                                <p>Reduce Manual Regression Testing By 50%</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="dataWrap">
                                                                <p>Enhance Consistency & Quality of Testing</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="dataWrap">
                                                                <p>Multi-Platform Testing</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="dataWrap">
                                                                <p>End-To-End Comprehensive Automation Solutions</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default Testing;