import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import caseStudyIco1 from './assets/img/caseStudyIco-1.png';
import caseStudyIco2 from './assets/img/caseStudyIco-2.png';
import caseStudyIco3 from './assets/img/caseStudyIco-3.png';
import caseStudyIco4 from './assets/img/caseStudyIco-4.png';
import caseStudyIco5 from './assets/img/caseStudyIco-5.png';
import caseStudyIco6 from './assets/img/caseStudyIco-6.png';
import ManufacturingImg from './assets/img/aboutImg.png';
import FashionListIco from './assets/img/Ico.png'

const Healthcare = () => {
    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="heathCareWrap innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Healthcare</h2>
                                    <p>Transforming Healthcare with Innovative Technology Solutions at Hummingtec. Empower Your Healthcare Operations with Our Comprehensive Suite of Services</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="manuFacturListWrap healtListWrap">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco1} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Efficient Document Management</h2>
                                        <p>Revolutionize healthcare documentation with our advanced systems. Experience the power of Natural Language Processing for seamless management and compliance.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco2} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Remote Patient Monitoring (RPM)</h2>
                                        <p>Embrace the future of healthcare with our RPM tools. Tailor patient care remotely and manage health efficiently with our cutting-edge technology.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco3} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Clinical Decision Support</h2>
                                        <p>Make informed decisions with our computerized support systems. Integrated alerts and diagnostic tools at your fingertips, enhancing clinical accuracy.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco4} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Telehealth Made Simple</h2>
                                        <p>Connect patients and providers like never before. Our telehealth solutions offer easy booking, comprehensive practice management, and secure virtual consultations.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco5} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Population Health Management</h2>
                                        <p>Dive into analytics-driven patient care. Our PHM software analyzes data across systems, optimizing healthcare delivery and patient outcomes.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco5} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Advanced HEDIS Measures</h2>
                                        <p>Evaluate healthcare effectiveness effortlessly. Our tools assist in preventive health strategies and physician profiling, elevating care standards.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco1} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Risk and Adjustment Services</h2>
                                        <p>Navigate healthcare risks with confidence. Our AI-enhanced solutions optimize healthcare plans while ensuring compliance and efficiency.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco2} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Innovative Blood Donation Tracking</h2>
                                        <p>Streamline blood donation efforts with our blockchain-enabled system. Enhance campaign impact and donor engagement, making every drop count.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco3} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Next-Gen Billing and Revenue Management</h2>
                                        <p>Transform your billing process with our precise and compliant solutions. Optimize your revenue cycle for smoother financial operations.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco4} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Secure Electronic Health Records (EHR)</h2>
                                        <p>Manage patient data with unparalleled security and efficiency. Our EHR systems are designed for superior care coordination and data management.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco5} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Robust Cybersecurity Solutions</h2>
                                        <p>Protect sensitive health data with our advanced cybersecurity measures. Safeguard your digital assets against evolving cyber threats.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco5} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Regulatory Compliance and HIPAA Expertise</h2>
                                        <p>Navigate the complexities of healthcare regulations with ease. Our solutions ensure your operations are compliant and up to HIPAA standards.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco1} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>AI-Driven Diagnostic Tools</h2>
                                        <p>Enhance diagnostic accuracy with AI. Our algorithms support clinical decisions, bringing technology to the forefront of healthcare.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco2} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Engaging Patient Platforms</h2>
                                        <p>Bridge the gap between patients and providers. Our engagement tools foster communication, scheduling, and health education.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco3} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Mobile Health Applications</h2>
                                        <p>Stay connected on the go. Our mobile apps offer health monitoring and lifestyle management at your fingertips.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContBefore">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco4} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>IoT Solutions for Real-Time Monitoring</h2>
                                        <p>Harness the power of IoMT for patient care. Our real-time monitoring devices revolutionize patient data collection and analysis.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContBefore">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco5} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Cutting-Edge Research and Development*</h2>
                                        <p>Innovate healthcare practices with our R&D services. We're committed to technological breakthroughs in healthcare.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContBefore noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco5} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Tailored Software Development</h2>
                                        <p>Custom solutions for unique healthcare needs. From laboratory management to pharmacy systems, we craft software that fits your operations.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="CommonBtmWrap">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="dataWrap">
                                    <h2>Ready to Elevate Healthcare with Hummingtec? </h2>
                                    <p>Join us in redefining healthcare technology. At Hummingtec, we're not just developing software; we're crafting a healthier future. Discover how our solutions can transform your healthcare operations today. Book a free consultation to know more about how we can help you.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default Healthcare;