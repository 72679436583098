import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
const Contact = () => {
    const FileInput = () => {
        const [filename, setFilename] = useState('');
        const handleFileChange = (e) => {
            setFilename(e.target.files[0]?.name || '');
        };
        return (
            <div className="uploadFile">
                {!filename && <span className="filename">Attach file</span>}
                <input type="file" onChange={handleFileChange} placeholder="" />
                {filename && <p className="filename">{filename}</p>}
            </div>
        );
    };
    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="bannerWrap innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Contact Us</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contactWrap">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-6 col-xl-5">
                                <div class="titleWrap">
                                    <h2>We'd love to hear from you.</h2>
                                </div>
                                <div class="dataWrap">
                                    <p>At our IT solution company, we are committed to providing exceptional customer service and support. If you are experiencing technical difficulties or need assistance with one of our services,</p>
                                </div>
                                <ul class="contactAdrress">
                                    <li>
                                        <div class="iconWrap">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 512 512">
                                                <g fill="#666666">
                                                    <path d="M485.743,85.333H26.257C11.815,85.333,0,97.148,0,111.589V400.41c0,14.44,11.815,26.257,26.257,26.257h459.487 c14.44,0,26.257-11.815,26.257-26.257V111.589C512,97.148,500.185,85.333,485.743,85.333z M475.89,105.024L271.104,258.626 c-3.682,2.802-9.334,4.555-15.105,4.529c-5.77,0.026-11.421-1.727-15.104-4.529L36.109,105.024H475.89z M366.5,268.761 l111.59,137.847c0.112,0.138,0.249,0.243,0.368,0.368H33.542c0.118-0.131,0.256-0.23,0.368-0.368L145.5,268.761 c3.419-4.227,2.771-10.424-1.464-13.851c-4.227-3.419-10.424-2.771-13.844,1.457l-110.5,136.501V117.332l209.394,157.046 c7.871,5.862,17.447,8.442,26.912,8.468c9.452-0.02,19.036-2.6,26.912-8.468l209.394-157.046v275.534L381.807,256.367 c-3.42-4.227-9.623-4.877-13.844-1.457C363.729,258.329,363.079,264.534,366.5,268.761z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                        <div class="dataWrap">
                                            <p>info@hummingtec.com</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="iconWrap">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 512 512">
                                                <g fill="#666666">
                                                    <path d="m243.519 505.32c2.782 4.173 7.466 6.68 12.481 6.68s9.699-2.506 12.481-6.68c35.499-53.247 87.786-119.008 124.222-185.889 29.134-53.476 43.297-99.085 43.297-139.431 0-99.252-80.748-180-180-180s-180 80.748-180 180c0 40.346 14.163 85.955 43.297 139.431 36.409 66.83 88.796 132.752 124.222 185.889zm12.481-475.32c82.71 0 150 67.29 150 150 0 35.204-12.967 76.118-39.641 125.079-31.407 57.649-76.225 116.128-110.359 165.29-34.129-49.155-78.95-107.638-110.359-165.29-26.674-48.961-39.641-89.875-39.641-125.079 0-82.71 67.29-150 150-150z"></path>
                                                    <path d="m256 270c49.626 0 90-40.374 90-90s-40.374-90-90-90-90 40.374-90 90 40.374 90 90 90zm0-150c33.084 0 60 26.916 60 60s-26.916 60-60 60-60-26.916-60-60 26.916-60 60-60z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                        <div class="dataWrap">
                                            <p>Magarpatta, Hadapsar, Pune, Maharashtra 411028</p>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="socialConnect">
                                    <li>
                                        <Link to={'https://www.facebook.com/hummingtecOfficial/'} target="_blank" rel="nofollow">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="16" height="16" x="0" y="0" viewBox="0 0 155.139 155.139">
                                                <g>
                                                    <path d="M89.584 155.139V84.378h23.742l3.562-27.585H89.584V39.184c0-7.984 2.208-13.425 13.67-13.425l14.595-.006V1.08C115.325.752 106.661 0 96.577 0 75.52 0 61.104 12.853 61.104 36.452v20.341H37.29v27.585h23.814v70.761h28.48z" fill="#010002" data-original="#010002"></path>
                                                </g>
                                            </svg>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"https://www.instagram.com/hummingtec_pvt/"} target="_blank" rel="nofollow">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="16" height="16" x="0" y="0" viewBox="0 0 511 511.9">
                                                <g>
                                                    <path d="M510.95 150.5c-1.2-27.2-5.598-45.898-11.9-62.102-6.5-17.199-16.5-32.597-29.6-45.398-12.802-13-28.302-23.102-45.302-29.5-16.296-6.3-34.898-10.7-62.097-11.898C334.648.3 325.949 0 256.449 0s-78.199.3-105.5 1.5c-27.199 1.2-45.898 5.602-62.097 11.898-17.204 6.5-32.602 16.5-45.403 29.602-13 12.8-23.097 28.3-29.5 45.3-6.3 16.302-10.699 34.9-11.898 62.098C.75 177.801.449 186.5.449 256s.301 78.2 1.5 105.5c1.2 27.2 5.602 45.898 11.903 62.102 6.5 17.199 16.597 32.597 29.597 45.398 12.801 13 28.301 23.102 45.301 29.5 16.3 6.3 34.898 10.7 62.102 11.898 27.296 1.204 36 1.5 105.5 1.5s78.199-.296 105.5-1.5c27.199-1.199 45.898-5.597 62.097-11.898a130.934 130.934 0 0 0 74.903-74.898c6.296-16.301 10.699-34.903 11.898-62.102 1.2-27.3 1.5-36 1.5-105.5s-.102-78.2-1.3-105.5zm-46.098 209c-1.102 25-5.301 38.5-8.801 47.5-8.602 22.3-26.301 40-48.602 48.602-9 3.5-22.597 7.699-47.5 8.796-27 1.204-35.097 1.5-103.398 1.5s-76.5-.296-103.403-1.5c-25-1.097-38.5-5.296-47.5-8.796C94.551 451.5 84.45 445 76.25 436.5c-8.5-8.3-15-18.3-19.102-29.398-3.5-9-7.699-22.602-8.796-47.5-1.204-27-1.5-35.102-1.5-103.403s.296-76.5 1.5-103.398c1.097-25 5.296-38.5 8.796-47.5C61.25 94.199 67.75 84.1 76.352 75.898c8.296-8.5 18.296-15 29.398-19.097 9-3.5 22.602-7.7 47.5-8.801 27-1.2 35.102-1.5 103.398-1.5 68.403 0 76.5.3 103.403 1.5 25 1.102 38.5 5.3 47.5 8.8 11.097 4.098 21.199 10.598 29.398 19.098 8.5 8.301 15 18.301 19.102 29.403 3.5 9 7.699 22.597 8.8 47.5 1.2 27 1.5 35.097 1.5 103.398s-.3 76.301-1.5 103.301zm0 0" fill="#000000" opacity="1" data-original="#000000"></path>
                                                    <path d="M256.45 124.5c-72.598 0-131.5 58.898-131.5 131.5s58.902 131.5 131.5 131.5c72.6 0 131.5-58.898 131.5-131.5s-58.9-131.5-131.5-131.5zm0 216.8c-47.098 0-85.302-38.198-85.302-85.3s38.204-85.3 85.301-85.3c47.102 0 85.301 38.198 85.301 85.3s-38.2 85.3-85.3 85.3zM423.852 119.3c0 16.954-13.747 30.7-30.704 30.7-16.953 0-30.699-13.746-30.699-30.7 0-16.956 13.746-30.698 30.7-30.698 16.956 0 30.703 13.742 30.703 30.699zm0 0" fill="#000000" opacity="1" data-original="#000000"></path>
                                                </g>
                                            </svg>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'https://in.linkedin.com/company/hummingtec-private-limited'} target="_blank" rel="nofollow">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="16" height="16" x="0" y="0" viewBox="0 0 100 100">
                                                <g>
                                                    <path d="M90 90V60.7c0-14.4-3.1-25.4-19.9-25.4-8.1 0-13.5 4.4-15.7 8.6h-.2v-7.3H38.3V90h16.6V63.5c0-7 1.3-13.7 9.9-13.7 8.5 0 8.6 7.9 8.6 14.1v26H90zM11.3 36.6h16.6V90H11.3zM19.6 10c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.7 9.6 9.7 9.6-4.4 9.6-9.7-4.3-9.6-9.6-9.6z" fill="#000000" opacity="1" data-original="#000000"></path>
                                                </g>
                                            </svg>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-6 col-xl-5">
                                <div className="formWrap">
                                    <form>
                                        <ul className="formList">
                                            <li className="full">
                                                <div className="form-field">
                                                    <input id="name" type="text" name="name" className="form-input" placeholder="Full Name" />
                                                </div>
                                            </li>
                                            <li className="half">
                                                <div className="form-field">
                                                    <input id="email" type="email" name="email" className="form-input" placeholder="Email Address" />
                                                </div>
                                            </li>
                                            <li className="half">
                                                <div className="form-field">
                                                    <input id="subjectTxt" type="text" name="subjectTxt" className="form-input" placeholder="Phone Number" />
                                                </div>
                                            </li>
                                            <li className="half">
                                                <div className="form-field">
                                                    <input id="company" type="text" name="company" className="form-input" placeholder="Company" />
                                                </div>
                                            </li>
                                            <li className="full">
                                                <div className="form-field textareaHt">
                                                    <textarea id="message" name="message"
                                                        className="form-textarea form-input" placeholder="How can we help you"></textarea>
                                                </div>
                                            </li>
                                            <li className="full">
                                                <div className="form-field">
                                                    <FileInput/>
                                                </div>
                                            </li>
                                            <li className="ctaContact full">
                                                <button className="btn btnEnquiry" type="submit">Send Message</button>
                                            </li>
                                        </ul>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default Contact;