import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import DedicatedTeamsImg1 from './assets/img/DedicatedTeamsImg-1.png';
import DedicatedTeamsImg2 from './assets/img/DedicatedTeamsImg-2.png';
import DedicatedTeamsImg3 from './assets/img/DedicatedTeamsImg-3.png';
const DedicatedTeams = () => {
    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="bannerWrap innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Dedicated Teams</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="staffAugItroWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="imgWrap">
                                    <img src={DedicatedTeamsImg1} className="img-fluid" alt=""/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="dataWrap dataWrapPadRt">
                                    <h2>Elevate Your Project with Our Dedicated Teams</h2>
                                    <p>In an era where technology evolves at lightning speed, having a dedicated team behind your software project isn't just an asset—it's a necessity. Dedicated Teams from Hummingtec offer a transformative approach to software development, combining the flexibility of external resources with the control and cohesion of an in-house team. Discover how our dedicated teams can become the cornerstone of your project's success.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="staffAugItroWrap flexRevMob">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="dataWrap dataWrapPadLt">
                                    <h2>The Essential Role of Dedicated Teams in Software Development</h2>
                                    <p>Today's digital landscape demands not just agility but also a deep commitment to quality, innovation, and strategic alignment. Our dedicated teams model is designed to meet these demands head-on, offering a dedicated unit that operates as an extension of your business. This model is perfect for long-term projects, large-scale developments, or ongoing maintenance and support tasks.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="imgWrap">
                                    <img src={DedicatedTeamsImg2} className="img-fluid" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="staffAugBenefitsWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="ttlWrap">
                                    <h2>Advantages of Our Dedicated Teams</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <ul>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="imgWrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 512 512">
                                                    <g>
                                                        <path d="M472 16H312a24.028 24.028 0 0 0-24 24v96a24.028 24.028 0 0 0 24 24h36.687l13.656 13.657a8 8 0 0 0 11.314 0L387.313 160H472a24.028 24.028 0 0 0 24-24V40a24.028 24.028 0 0 0-24-24zm8 120a8.009 8.009 0 0 1-8 8h-88a8 8 0 0 0-5.657 2.343L368 156.687l-10.343-10.344A8 8 0 0 0 352 144h-40a8.009 8.009 0 0 1-8-8V40a8.009 8.009 0 0 1 8-8h160a8.009 8.009 0 0 1 8 8z" fill="#9ceebd" opacity="0.45" data-original="#9ceebd"></path>
                                                        <path d="M376 56h-32v16h12.686l-34.343 34.343 11.314 11.314L368 83.314V96h16V64a8 8 0 0 0-8-8zM400 48h64v16h-64zM400 80h64v16h-64zM400 112h64v16h-64zM424 408a40.045 40.045 0 0 0 40-40V184h-16v144H64V128a24.028 24.028 0 0 1 24-24h184V88H88a40.045 40.045 0 0 0-40 40v240a40.045 40.045 0 0 0 40 40h99.867l-17.143 40H136a24 24 0 0 0 0 48h240a24 24 0 0 0 0-48h-34.724l-17.143-40zM64 368v-24h384v24a24.028 24.028 0 0 1-24 24H88a24.028 24.028 0 0 1-24-24zm320 104a8.009 8.009 0 0 1-8 8H136a8 8 0 0 1 0-16h240a8.009 8.009 0 0 1 8 8zm-60.133-24H188.133l17.143-40h101.448z" fill="#9ceebd" opacity="1" data-original="#9ceebd"></path>
                                                        <path d="M248 360h16v16h-16zM88 144v152a8 8 0 0 0 8 8h160v-16h-24v-96h-16v96h-16v-64h-16v64h-16v-80h-16v80h-16v-48h-16v48h-16V144zM416 192H296a8 8 0 0 0-8 8v32a8 8 0 0 0 8 8h120a8 8 0 0 0 8-8v-32a8 8 0 0 0-8-8zm-112 16h64v16h-64zm104 16h-24v-16h24zM416 304a8 8 0 0 0 8-8v-32a8 8 0 0 0-8-8H296a8 8 0 0 0-8 8v32a8 8 0 0 0 8 8zm-8-16h-72v-16h72zm-104-16h16v16h-16z" fill="#9ceebd" opacity="1" data-original="#9ceebd"></path>
                                                        <path d="M120 208h16v16h-16zM152 176h16v16h-16zM184 192h16v16h-16zM216 160h16v16h-16z" fill="#9ceebd" opacity="1" data-original="#9ceebd"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Seamless Integration</h2>
                                                <p>Our teams are skilled in becoming a part of your operations, ensuring a unified approach to your project goals.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="imgWrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 512 512">
                                                    <g>
                                                        <path d="M19.869 207v135c0 5.523 4.477 10 10 10H49.8c2.968-27.649 26.37-49.182 54.807-49.182 28.438 0 51.839 21.533 54.807 49.182h41.779c2.968-27.649 26.37-49.182 54.807-49.182 28.438 0 51.839 21.533 54.807 49.182h41.78c2.968-27.649 26.369-49.182 54.807-49.182s51.839 21.533 54.807 49.182h19.931c5.523 0 10-4.477 10-10V52.069H19.869V172M390 19.46H17.5c-5.523 0-10 4.477-10 10v12.609c0 5.523 4.477 10 10 10h477c5.523 0 10-4.477 10-10V29.46c0-5.523-4.477-10-10-10H425M408 492.54h69.131c8.284 0 15-6.716 15-15v-26.82a29.978 29.978 0 0 0-17.588-27.297l-39.362-17.864c-8.161 4.774-17.651 7.522-27.788 7.522s-19.627-2.748-27.788-7.522l-39.362 17.864a29.906 29.906 0 0 0-8.547 5.857 29.963 29.963 0 0 1 9.041 21.44v41.82H373M171.756 423.423l-39.362-17.864a54.854 54.854 0 0 1-27.788 7.522 54.854 54.854 0 0 1-27.788-7.522l-39.362 17.864a29.976 29.976 0 0 0-17.588 27.297v26.82c0 8.284 6.716 15 15 15h136.393v-41.82a29.963 29.963 0 0 1 9.041-21.44 29.888 29.888 0 0 0-8.546-5.857z" fill="none" stroke="#9ccdee" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" data-original="#9ccdee"></path>
                                                        <path d="m323.15 423.423-39.362-17.864A54.854 54.854 0 0 1 256 413.081a54.854 54.854 0 0 1-27.788-7.522l-39.362 17.864a29.976 29.976 0 0 0-17.588 27.297v41.82h169.476v-41.82a29.976 29.976 0 0 0-17.588-27.297z" fill="none" stroke="#9ccdee" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" data-original="#9ccdee"></path>
                                                        <circle cx="407.394" cy="357.949" r="55.131" transform="rotate(-67.5 407.387 357.94)" fill="none" stroke="#9ccdee" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" data-original="#9ccdee" opacity="0.45"></circle>
                                                        <circle cx="104.607" cy="357.949" r="55.131" transform="rotate(-67.5 104.606 357.942)" fill="none" stroke="#9ccdee" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" data-original="#9ccdee" opacity="0.45"></circle>
                                                        <circle cx="256" cy="357.949" r="55.131" transform="rotate(-67.5 255.996 357.941)" fill="none" stroke="#9ccdee" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" data-original="#9ccdee" opacity="0.45"></circle>
                                                        <path d="M189.729 148.02a5 5 0 0 1-5-5v-26.866c-12.185-7.235-26.405-11.402-41.604-11.402-45.08 0-81.625 36.545-81.625 81.625s36.545 81.625 81.625 81.625 81.625-36.545 81.625-81.625c0-13.867-3.469-26.919-9.569-38.356h-25.452z" fill="none" stroke="#9ccdee" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" data-original="#9ccdee" opacity="0.45"></path>
                                                        <path d="M224.749 186.375h-81.624v-81.624M292.172 148.02H189.729a5 5 0 0 1-5-5V98.047a5 5 0 0 1 5-5h102.443a5 5 0 0 1 5 5v44.972a5 5 0 0 1-5 5.001zM214.714 120.533h52.473M256.213 187.5h11.619M256.213 222.477h11.619M256.213 257.455h11.619M297.408 187.5H450.5M297.408 222.477H395.5M297.408 257.455H450.5M450.5 135.533h-61.364M389.136 105.533H450.5M417.5 222.477h33" fill="none" stroke="#9ccdee" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" data-original="#9ccdee" opacity="0.45"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Full Project Control</h2>
                                                <p>While we handle the management, you retain complete control over project priorities and adjustments.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="imgWrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 66 66">
                                                    <g>
                                                        <path d="M45.93 6.96h-8.5c-.48 0-.88.39-.88.88s.39.88.88.88h8.5c.48 0 .88-.39.88-.88s-.4-.88-.88-.88zM34.07 6.82c-.56 0-1.01.45-1.01 1.01s.45 1.01 1.01 1.01 1.01-.45 1.01-1.01-.45-1.01-1.01-1.01zM45.93 10.89h-8.5c-.48 0-.88.39-.88.88s.39.88.88.88h8.5c.48 0 .88-.39.88-.88s-.4-.88-.88-.88zM34.07 10.75c-.56 0-1.01.45-1.01 1.01s.45 1.01 1.01 1.01 1.01-.45 1.01-1.01-.45-1.01-1.01-1.01zM45.93 14.82h-8.5c-.48 0-.88.39-.88.88s.39.88.88.88h8.5c.48 0 .88-.39.88-.88s-.4-.88-.88-.88zM34.07 14.69c-.56 0-1.01.45-1.01 1.01s.45 1.01 1.01 1.01 1.01-.45 1.01-1.01-.45-1.01-1.01-1.01zM27.71 49.82h-3.98c-.48 0-.88.39-.88.88v8.39h-2.21V46.26c0-.48-.39-.88-.88-.88h-3.98c-.48 0-.88.39-.88.88v12.82h-2.21V40.86c0-.48-.39-.88-.88-.88H7.86c-.48 0-.88.39-.88.88v18.23h-.44c-.48 0-.88.39-.88.88s.39.88.88.88h23.02c.48 0 .88-.39.88-.88s-.39-.88-.88-.88h-.97V50.7c0-.49-.39-.88-.88-.88zm-3.1 1.75h2.23v7.52h-2.23zm-7.94-4.43h2.23v11.95h-2.23zm-7.94-5.41h2.23v17.35H8.73zM47.43 45.12c4.37-4.37 4.37-11.49 0-15.86-2.12-2.12-4.93-3.28-7.93-3.28s-5.81 1.17-7.93 3.29-3.28 4.93-3.28 7.93c0 2.99 1.17 5.81 3.29 7.93s4.94 3.28 7.93 3.28 5.8-1.18 7.92-3.29zm-17.4-7.93c0-2.53.98-4.9 2.77-6.69s4.17-2.77 6.69-2.77c2.53 0 4.9.98 6.69 2.77 3.69 3.69 3.69 9.7 0 13.39a9.403 9.403 0 0 1-6.69 2.77c-2.53 0-4.91-.98-6.69-2.77a9.443 9.443 0 0 1-2.77-6.7z" fill="#cb9cee" opacity="0.45" data-original="#cb9cee"></path>
                                                        <path d="m63.23 55.37-7.93-7.93a2.807 2.807 0 0 0-.41-3.44l-1.21-1.21c.7-1.76 1.07-3.66 1.07-5.61 0-4.08-1.59-7.91-4.47-10.79-5.95-5.95-15.63-5.95-21.58 0a15.158 15.158 0 0 0-4.47 10.79c0 4.07 1.59 7.91 4.47 10.79 2.97 2.97 6.88 4.46 10.79 4.46 1.9 0 3.81-.36 5.6-1.07l1.22 1.22a2.799 2.799 0 0 0 3.44.41l7.93 7.93c.74.74 1.73 1.15 2.77 1.15s2.03-.41 2.77-1.15 1.15-1.73 1.15-2.77-.4-2.03-1.14-2.78zm-33.28-8.63c-2.55-2.55-3.96-5.94-3.96-9.55s1.4-7 3.96-9.55c2.55-2.55 5.94-3.96 9.55-3.96s7 1.41 9.55 3.96S53 33.58 53 37.19c0 1.91-.4 3.75-1.15 5.44-.01.02-.02.03-.02.05-.66 1.49-1.59 2.87-2.78 4.05A13.37 13.37 0 0 1 45 49.51c-.02.01-.04.02-.05.03-1.69.75-3.54 1.15-5.44 1.15-3.62 0-7.01-1.4-9.56-3.95zm17.6 4.61-.77-.77a14.78 14.78 0 0 0 3.5-2.61c1.05-1.05 1.92-2.23 2.61-3.49l.76.76a1.06 1.06 0 0 1 0 1.5l-.15.15-4.3 4.31-.14.14c-.4.41-1.1.41-1.51.01zm14.44 8.33c-.82.82-2.25.82-3.07 0l-7.86-7.86 3.07-3.07 7.86 7.86c.41.41.64.96.64 1.54s-.23 1.12-.64 1.53z" fill="#cb9cee" opacity="0.45" data-original="#cb9cee"></path>
                                                        <path d="M40.37 44.09v-.67c1.57-.39 2.74-1.81 2.74-3.49 0-1.99-1.62-3.61-3.61-3.61-1.03 0-1.86-.84-1.86-1.86s.84-1.86 1.86-1.86 1.86.84 1.86 1.86c0 .48.39.88.88.88s.88-.39.88-.88c0-1.69-1.17-3.1-2.74-3.49v-.67c0-.48-.39-.88-.88-.88s-.88.39-.88.88v.67c-1.57.39-2.74 1.81-2.74 3.49 0 1.99 1.62 3.61 3.61 3.61 1.03 0 1.86.84 1.86 1.86 0 1.03-.84 1.86-1.86 1.86s-1.86-.84-1.86-1.86c0-.48-.39-.88-.88-.88s-.88.39-.88.88c0 1.69 1.17 3.1 2.74 3.49v.67c0 .48.39.88.88.88s.88-.4.88-.88zM25.74 17.29h-8.22V9.08c0-.48-.39-.88-.88-.88-5.5 0-9.97 4.47-9.97 9.97s4.47 9.97 9.97 9.97 9.97-4.47 9.97-9.97a.88.88 0 0 0-.87-.88zm-9.1 9.1c-4.53 0-8.22-3.69-8.22-8.22 0-4.24 3.22-7.73 7.34-8.17v8.17c0 .48.39.88.88.88h8.17c-.43 4.12-3.93 7.34-8.17 7.34z" fill="#cb9cee" opacity="0.45" data-original="#cb9cee"></path>
                                                        <path d="M29.64 15.14c0-5.5-4.47-9.97-9.97-9.97-.48 0-.88.39-.88.88v9.09c0 .48.39.88.88.88h9.09c.49-.01.88-.4.88-.88zm-9.09-.88v-7.3c3.83.41 6.89 3.46 7.3 7.3z" fill="#cb9cee" opacity="1" data-original="#cb9cee"></path>
                                                        <path d="M50.37 55.29c-.48 0-.88.39-.88.88v3.8c0 1.75-1.42 3.17-3.17 3.17H6.54c-1.75 0-3.17-1.42-3.17-3.17V6.04c0-1.75 1.42-3.17 3.17-3.17h39.79c1.75 0 3.17 1.42 3.17 3.17v16.32c0 .48.39.88.88.88s.88-.39.88-.88V6.04a4.93 4.93 0 0 0-4.92-4.92H6.54a4.93 4.93 0 0 0-4.92 4.92v53.92a4.93 4.93 0 0 0 4.92 4.92h39.79a4.93 4.93 0 0 0 4.92-4.92v-3.8a.886.886 0 0 0-.88-.87z" fill="#cb9cee" opacity="1" data-original="#cb9cee"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Cost-Effective Scaling</h2>
                                                <p>Scale your team up or down with ease, optimizing your budget without compromising on deliverables.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="imgWrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 512 512">
                                                    <g>
                                                        <path d="M421.713 263.57V244.5c0-33.137-26.863-60-60-60h-40c-33.137 0-60 26.863-60 60v19.07" fill="none" stroke="#dcdc30" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" data-original="#dcdc30"></path>
                                                        <path d="M301.713 263.57V244.5c0-11.046 8.954-20 20-20h40c11.046 0 20 8.954 20 20v19.07M181.713 314.077v151.791c0 21.336 17.296 38.632 38.632 38.632h242.736c21.336 0 38.632-17.296 38.632-38.632V314.077M388.506 386.834h-93.577" fill="none" stroke="#dcdc30" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" data-original="#dcdc30"></path>
                                                        <path d="M242.963 386.687c-34.208-2.275-61.25-30.743-61.25-65.527v-18.028c0-21.336 17.296-38.632 38.632-38.632h242.736c21.336 0 38.632 17.296 38.632 38.632v18.028c0 34.632-26.807 63.004-60.803 65.496" fill="none" stroke="#dcdc30" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" data-original="#dcdc30"></path>
                                                        <path d="M243.777 502.5v-133c0-13.807 11.193-25 25-25h0c13.807 0 25 11.193 25 25v133M389.649 455.884V502.5M439.649 502.5v-133c0-13.807-11.193-25-25-25h0c-13.807 0-25 11.193-25 25v51.235M279.269 296.729v11.999M404.158 296.729v11.999M325.25 308.371c4.003 4.593 9.892 7.5 16.463 7.5 6.571 0 12.46-2.907 16.463-7.5M83.23 55.695C118.902 25.622 164.978 7.5 215.287 7.5c113.218 0 205 91.782 205 205 0 4.286-.132 8.541-.391 12.762M180.319 414.529C83.758 397.934 10.287 313.797 10.287 212.5c0-50.433 18.211-96.612 48.417-132.32" fill="none" stroke="#dcdc30" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" data-original="#dcdc30" opacity="0.45"></path>
                                                        <path d="m386.431 188.935-8.489-16.021a64.72 64.72 0 0 0-95.608-21.779l-18.121 13.368c-9.564 7.055-22.875 6.81-31.805-1.032-9.789-8.597-11.347-23.217-3.661-33.672l12.28-16.705a71.313 71.313 0 0 0 5.713-75.326h0a71.045 71.045 0 0 0-10.452-14.904A71.507 71.507 0 0 0 217.933 8.4M38.64 312.726c13.061 1.073 25.711-5.783 31.821-17.706l28.285-55.196a29.473 29.473 0 0 1 11.113-11.864v0a29.487 29.487 0 0 1 15.754-4.176h0c15.005.313 27.371 11.839 28.758 26.758.959 10.313 6.747 19.658 15.997 24.317 6.184 3.115 12.187 4.25 17.854 4.046M289.764 23.49l13.071 34.297c19.719 51.74 69.852 48.839 85.562 48.844M180.684 351.222a54.999 54.999 0 0 1-14.702 2.805 55.028 55.028 0 0 1-27.54-5.716c-21.271-10.635-46.473-5.08-61.471 12.13" fill="none" stroke="#dcdc30" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" data-original="#dcdc30" opacity="0.45"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Access to Top Talent</h2>
                                                <p>Leverage our global talent pool to staff your project with leading professionals in software development.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="imgWrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 64 64">
                                                    <g>
                                                        <path d="M32 48a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8Zm0-14a6 6 0 1 0 6 6 6.006 6.006 0 0 0-6-6ZM44 61a1 1 0 0 1-1-1v-6c0-1.018-3.883-3-11-3s-11 1.982-11 3v6a1 1 0 0 1-2 0v-6c0-3.283 6.54-5 13-5s13 1.717 13 5v6a1 1 0 0 1-1 1ZM49 43a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8Zm0-14a6 6 0 1 0 6 6 6.006 6.006 0 0 0-6-6Z" fill="#ff9393" opacity="0.45" data-original="#ff9393"></path>
                                                        <path d="M61 56a1 1 0 0 1-1-1v-6c0-1.018-3.883-3-11-3-4.1 0-7.9.715-9.9 1.866a1 1 0 1 1-1-1.732C40.425 44.8 44.5 44 49 44c6.46 0 13 1.717 13 5v6a1 1 0 0 1-1 1ZM15 43a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8Zm0-14a6 6 0 1 0 6 6 6.006 6.006 0 0 0-6-6ZM3 56a1 1 0 0 1-1-1v-6c0-3.283 6.54-5 13-5 4.5 0 8.575.8 10.894 2.134a1 1 0 1 1-1 1.732C22.9 46.715 19.105 46 15 46c-7.117 0-11 1.982-11 3v6a1 1 0 0 1-1 1ZM32 24a1 1 0 0 1-.707-1.707L46.364 7.222a1 1 0 0 1 1.414 1.414L32.707 23.707A1 1 0 0 1 32 24Z" fill="#ff9393" opacity="0.45" data-original="#ff9393"></path>
                                                        <path d="M46 15a1.01 1.01 0 0 1-.243-.03l-4-1a1 1 0 0 1-.464-1.677l5-5a1 1 0 0 1 .95-.263l4 1a1 1 0 0 1 .464 1.677l-5 5A1 1 0 0 1 46 15Zm-2.044-2.542 1.737.435 3.351-3.351-1.737-.435Z" fill="#ff9393" opacity="1" data-original="#ff9393"></path>
                                                        <path d="M42 14a1.016 1.016 0 0 1-.267-.036 1 1 0 0 1-.7-.721l-1-4a1 1 0 0 1 .263-.95l5-5a1 1 0 0 1 1.677.464l1 4a1 1 0 0 1-.263.95l-5 5A1 1 0 0 1 42 14Zm.107-4.693.435 1.737 3.351-3.351-.435-1.737ZM15.115 26a1 1 0 0 1-.992-.884A18.429 18.429 0 0 1 14 23 18.009 18.009 0 0 1 39.412 6.592a1 1 0 0 1-.824 1.823A16.008 16.008 0 0 0 16 23a16.423 16.423 0 0 0 .109 1.884 1 1 0 0 1-.877 1.109.918.918 0 0 1-.117.007ZM48.885 26a.918.918 0 0 1-.117-.007 1 1 0 0 1-.877-1.109A16.423 16.423 0 0 0 48 23a15.87 15.87 0 0 0-1.415-6.588 1 1 0 0 1 1.823-.824A17.867 17.867 0 0 1 50 23a18.429 18.429 0 0 1-.123 2.116 1 1 0 0 1-.992.884ZM32 29a6 6 0 1 1 6-6 6.006 6.006 0 0 1-6 6Zm0-10a4 4 0 1 0 4 4 4 4 0 0 0-4-4Z" fill="#ff9393" opacity="1" data-original="#ff9393"></path>
                                                        <path d="M38.319 33a1 1 0 0 1-.577-1.818 10 10 0 1 0-11.484 0 1 1 0 1 1-1.152 1.636 12 12 0 1 1 13.788 0 1 1 0 0 1-.575.182Z" fill="#ff9393" opacity="1" data-original="#ff9393"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Focus on Core Objectives</h2>
                                                <p>With a dedicated team in place, your internal resources can concentrate on the core business, while we take care of the technology.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="staffAugItroWrap staffAugValueWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="imgWrap martTop">
                                    <img src={DedicatedTeamsImg3} className="img-fluid" alt=""/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="dataWrap dataWrapPadRt">
                                    <h2>The Value We Bring to Your Project</h2>
                                    <p>Our approach to dedicated teams goes beyond mere staffing. We invest in understanding your business and technology needs, ensuring our team not only delivers on your current project but also contributes to your long-term success. Our promise includes:</p>
                                    <ul>
                                        <li><strong>1. Tailored Team Composition:</strong> We assemble your team based on the specific skills and expertise your project requires.</li>
                                        <li><strong>2. Continuous Improvement:</strong> Our teams are committed to evolving with your project, ensuring they always bring fresh ideas and the latest technological advancements to the table.</li>
                                        <li><strong>3. Transparent Communication:</strong> Regular updates, reports, and meetings ensure you're always in the loop and in control of your project's direction.</li>
                                        <li><strong>4. Commitment to Excellence:</strong> We foster a culture of quality, innovation, and responsibility, ensuring your dedicated team is always pushing towards excellence.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="staffOnBoardWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="ttlWrap">
                                    <h2>Our Onboarding Process: Smooth and Structured</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <ul>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="noWrap">
                                                <h2>01</h2>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Initial Consultation</h2>
                                                <p>We start with a thorough analysis of your project needs, goals, and organizational culture.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="noWrap">
                                                <h2>02</h2>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Team Assembly</h2>
                                                <p>Based on our consultation, we handpick professionals who not only meet the technical requirements but also align with your company’s ethos.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="noWrap">
                                                <h2>03</h2>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Integration Plan</h2>
                                                <p>We develop a customized integration plan to ensure the team adapts quickly and efficiently to your processes and workflows.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="noWrap">
                                                <h2>04</h2>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Kick-off</h2>
                                                <p>With a solid plan in place, your dedicated team begins working on your project, fully supported by our infrastructure and resources.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="noWrap">
                                                <h2>05</h2>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Ongoing Management and Support</h2>
                                                <p>We provide continuous oversight, support, and adjustments as needed to ensure peak performance and alignment with your objectives.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="staffAugFaqWrap">
                    <div class="container d-flex">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="ttlWrap">
                                    <h2>FAQ</h2>
                                </div>
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                How quickly can a dedicated team be assembled and operational?
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p>Typically, we can have your dedicated team up and running within a few weeks, depending on the specific skills and team size required.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Can I adjust the team size or skillset over time?
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p>Absolutely. Our model is designed for flexibility, allowing you to scale your team or adjust its composition as your project evolves.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingThree">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                How do we ensure the team aligns with our company culture?
                                            </button>
                                        </h2>
                                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p>We place a strong emphasis on cultural fit during the selection process and foster open communication and integration activities to ensure seamless alignment.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingFour">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                What kind of projects are best suited for dedicated teams?
                                            </button>
                                        </h2>
                                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p>Dedicated teams are ideal for long-term projects with evolving scopes, such as product development, platform builds, and continuous improvement initiatives.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 ht100p">
                                <div className="rightWrap">
                                    <h2>Transform Your Project with a Dedicated Team</h2>
                                    <p>Let Hummingtec's dedicated teams propel your project to new heights. With a commitment to excellence, innovation, and strategic partnership, we're here to ensure your project not only meets but exceeds your expectations. <Link to={"/Contact"}>Reach out to us today </Link> to discover how our dedicated teams can deliver unparalleled value to your software development journey.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default DedicatedTeams;