import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
const TermsConditions = () => {

    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="bannerWrap innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Terms and Conditions</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="commonPagesDataWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dataWrap">
                                    <p>These terms and conditions ("Terms") govern your use of the Hummingtec website (the "Website"). By accessing or using the Website, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you must not use the Website.</p>
                                    <h4>1. Intellectual Property</h4>
                                    <p>All content, logos, trademarks, and materials displayed on the Website are the property of Hummingtec or its licensors and are protected by intellectual property laws. You may not use, reproduce, or distribute any content from the Website without prior written permission from Hummingtec.</p>
                                    <h4>2. Use of Website</h4>
                                    <p>You agree to use the Website only for lawful purposes and in compliance with these Terms and all applicable laws and regulations. You may not use the Website in any manner that could damage, disable, overburden, or impair the Website or interfere with any other party's use of the Website.</p>
                                    <h4>3. Privacy Policy</h4>
                                    <p>Your use of the Website is also subject to our Privacy Policy, which governs how we collect, use, and disclose your personal information. By using the Website, you consent to the collection and use of your information as described in the Privacy Policy.</p>
                                    <h4>4. Links to Third-Party Websites</h4>
                                    <p>The Website may contain links to third-party websites or services that are not owned or controlled by Hummingtec. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that Hummingtec shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.</p>
                                    <h4>5. Limitation of Liability</h4>
                                    <p>To the fullest extent permitted by law, Hummingtec shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses resulting from your use of the Website.</p>
                                    <h4>6. Changes to Terms</h4>
                                    <p>Hummingtec reserves the right to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
                                    <h4>7. Governing Law</h4>
                                    <p>These Terms shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions.</p>
                                    <h4>8. Contact Us</h4>
                                    <p>If you have any questions about these Terms, please contact us at hummingtec.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default TermsConditions;