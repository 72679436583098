import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import caseStudyIco1 from './assets/img/caseStudyIco-1.png';
import caseStudyIco2 from './assets/img/caseStudyIco-2.png';
import caseStudyIco3 from './assets/img/caseStudyIco-3.png';
import caseStudyIco4 from './assets/img/caseStudyIco-4.png';
import caseStudyIco5 from './assets/img/caseStudyIco-5.png';
import caseStudyIco6 from './assets/img/caseStudyIco-6.png';
import ManufacturingImg from './assets/img/aboutImg.png';
const Manufacturing = () => {
    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="manuFacturWrap innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Manufacturing</h2>
                                    <p>Our In-House ERP Solution: Comprehensive Business Management Welcome to Hummingtec Private Limited's In-House ERP Solution, a robust and fully integrated platform designed to streamline your business processes. Explore the key features of our modules below:</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="manuFacturListWrap">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco1} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Inventory Management</h2>
                                        <p>Efficiently manage your inventory with our advanced Inventory Management module. Gain real-time insights into stock levels, automate reorder processes, and minimize stockouts. Track items across multiple locations and ensure optimal stock levels to meet customer demand.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco2} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Payroll Management</h2>
                                        <p>Simplify payroll processing with precision and ease. Our Payroll Management module handles everything from salary calculations and deductions to tax compliance. Ensure accurate and timely payroll processing while maintaining compliance with regulatory requirements.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco3} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Vehicle Management</h2>
                                        <p>Optimize your fleet operations with our Vehicle Management module. Track vehicle details, maintenance schedules, and fuel consumption. Improve route planning, monitor driver performance, and ensure the reliability and efficiency of your entire vehicle fleet.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContBefore">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco4} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Admin Management</h2>
                                        <p>Empower your administrators with tools for seamless administrative control. Our Admin Management module provides a centralized hub to oversee user access, security settings, and system configurations. Efficiently manage user roles and permissions to ensure data integrity and confidentiality.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContBefore">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco5} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Quality Management</h2>
                                        <p>Elevate your product and service quality with our Quality Management module. Implement standardized processes, conduct inspections, and track quality metrics. Identify and address issues promptly, ensuring that your business consistently delivers high-quality products and services.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContBefore noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco6} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Cost Management</h2>
                                        <p>Gain control over your costs with our Cost Management module. Track and analyze expenses across different departments and projects. Set budgetary controls, monitor spending patterns, and optimize resource allocation for improved financial performance.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="manufacturSecWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="imgWrap">
                                    <img src={ManufacturingImg} className="img-fluid" alt=""/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="dataWrap">
                                    <h2>Why Choose Our ERP Solution</h2>
                                    <ul>
                                        <li>
                                            <span>1. Tailored to Your Business:</span>
                                            Our modular approach allows for customization based on your unique business needs.
                                        </li>
                                        <li>
                                            <span>2. User-Friendly Interface:</span>
                                            Intuitive design for easy navigation and quick adoption across your organization.
                                        </li>
                                        <li>
                                            <span>3. Scalable and Flexible:</span>
                                            Grow your business without worrying about outgrowing your ERP solution
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default Manufacturing;