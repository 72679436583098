import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import IcoImg from "./assets/img/Ico.png";
import CustomAppDev from "./assets/video/CustomAppDev.mp4";
import AngularJs from "./assets/img/TechStack/AngularJs.png";
import Docker from "./assets/img/TechStack/Docker.png";
import Flutter from "./assets/img/TechStack/Flutter.png";
import Git from "./assets/img/TechStack/Git.png";
import GraphQL from "./assets/img/TechStack/GraphQL.png";
import HTML from "./assets/img/TechStack/HTML.png";
import Java from "./assets/img/TechStack/Java.png";
import Jenkins from "./assets/img/TechStack/Jenkins.png";
import Kotlin from "./assets/img/TechStack/Kotlin.png";
import Kubernetes from "./assets/img/TechStack/Kubernetes.png";
import MongoDB from "./assets/img/TechStack/MongoDB.png";
import MySQL from "./assets/img/TechStack/MySQL.png";
import NoSQL from "./assets/img/TechStack/NoSQL.png";
import NodeJs from "./assets/img/TechStack/NodeJs.png";
import Oracle from "./assets/img/TechStack/Oracle.png";
import PHP from "./assets/img/TechStack/PHP.png";
import PostgreSQL from "./assets/img/TechStack/PostgreSQL.png";
import PowerBI from "./assets/img/TechStack/PowerBI.png";
import Python from "./assets/img/TechStack/Python.png";
import ReactJs from "./assets/img/TechStack/ReactJs.png";
import ReactNative from "./assets/img/TechStack/ReactNative.png";
import SQLServer from "./assets/img/TechStack/SQLServer.png";
import Tableau from "./assets/img/TechStack/Tableau.png";
import WordPress from "./assets/img/TechStack/WordPress.png";
import dotNET from "./assets/img/TechStack/dotNET.png";
import iOSAndroid from "./assets/img/TechStack/iOSAndroid.png";

const CustomApplication = () => {

    const [category, setCategory] = useState('All');
    const handleSortItems = (selectedCategory) => {
        setCategory(selectedCategory);
    };


    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="bannerWrap innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Custom Application Development</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="aboutSectionWrap customAppDevWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <div class="videoWrap">
                                    <svg class="position-absolute" viewBox="0 0 413 367" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M297.773 365.385C240.904 379.987 171.136 291.086 138.891 279.276C75.5722 256.299 -32.89 263.6 9.61542 210.775C29.2559 186.295 70.2956 153.011 85.539 133.9C116.612 95.0327 55.6386 25.4583 110.749 5.05841C165.566 -15.1267 212.176 30.612 266.114 45.6435C301.584 55.736 339.692 50.7971 376.042 55.9508C400.959 59.6013 422.944 80.0012 408.287 99.9716C318.293 222.8 394.216 340.476 297.773 365.385Z" fill="#701A75"></path></svg>
                                    <video autoPlay loop muted playsInline>
                                        <source src={CustomAppDev} type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="dataWrap">
                                    <p>In the realm of Custom Application Development, we're seeing a renaissance of trends that are reshaping the landscape. AI and ML aren't just buzzwords; they're the new power tools in our kit, enabling applications that learn, adapt, and outsmart. The blockchain is our new vault, an impenetrable ledger that keeps transactions transparent yet secure.</p>
                                    <p>The cloud is our open sky, where applications soar free of the constraints of physical infrastructure. And in this cloud, our apps are native, born and bred to leverage the endless expanse of computational power and flexibility.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dataWrap">
                                    <p>We're embracing the low-code revolution, empowering organizations to bring their ideas to life at lightning speed, and we're doing it with a touch of elegance, ensuring that our applications aren't just functional but also a joy to use. As we march into the future, these trends aren't just guiding our way; they're opening up new horizons. Stay tuned as we decode these trends and more, right here on your trusted blog for all things tech.</p>
                                    <p>At Hummingtec, we pride ourselves on leveraging a broad spectrum of modern technologies to deliver top-notch solutions. From front-end development to mobile app creation, our team is skilled in a variety of tools and languages to bring your projects to life.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="techWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="titleWrap">
                                    <h2>Tech stack</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="topBtnlistWrap">
                                    <li>
                                        <button className="btn" onClick={() => handleSortItems('All')}>All</button>
                                    </li>
                                    <li>
                                        <button className="btn" onClick={() => handleSortItems('FrontEndTechnologies')}>Front-End Technologies</button>
                                    </li>
                                    <li>
                                        <button className="btn" onClick={() => handleSortItems('BackEndTechnologies')}>Back-End Technologies</button>
                                    </li>
                                    <li>
                                        <button className="btn" onClick={() => handleSortItems('DatabaseManagement')}>Database Management</button>
                                    </li>
                                    <li>
                                        <button className="btn" onClick={() => handleSortItems('DashboardingAnalytics')}>Dashboarding and Analytics</button>
                                    </li>
                                    <li>
                                        <button className="btn" onClick={() => handleSortItems('MobileAppDevelopment')}>Mobile App Development</button>
                                    </li>
                                    <li>
                                        <button className="btn" onClick={() => handleSortItems('AdditionalToolsTech')}>Additional Tools and Technologies</button>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-12">
                                <ul className="sortListWrap">
                                    {category === 'All' ? (
                                        <>
                                            <li className="FrontEndTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={ReactJs} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>React.js</h2>
                                                        <p>Crafting dynamic and responsive user interfaces.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="FrontEndTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={AngularJs} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Angular.js</h2>
                                                        <p>Building scalable web applications with a robust framework.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="FrontEndTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={HTML} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>HTML, CSS, JS</h2>
                                                        <p>The building blocks for web development, ensuring sleek and functional designs.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="FrontEndTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={WordPress} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>WordPress</h2>
                                                        <p>For versatile and easy-to-manage websites, from blogs to business portals.</p>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="BackEndTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={Python} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Python</h2>
                                                        <p>Versatile programming for everything from web applications to data analysis.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="BackEndTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={NodeJs} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Node.js</h2>
                                                        <p>Efficient, scalable server-side development.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="BackEndTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={dotNET} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>.NET</h2>
                                                        <p>Microsoft's framework for robust web and desktop applications.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="BackEndTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={Java} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Java</h2>
                                                        <p>For enterprise-level backend solutions.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="BackEndTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={PHP} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>PHP</h2>
                                                        <p>Server scripting for dynamic web page content.</p>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="DatabaseTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={Oracle} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Oracle</h2>
                                                        <p>High-performance database solutions for large-scale applications.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="DatabaseTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={MongoDB} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>MongoDB</h2>
                                                        <p>Leading NoSQL database, perfect for handling large data sets.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="DatabaseTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={MySQL} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>MySQL</h2>
                                                        <p>Popular open-source relational database.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="DatabaseTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={SQLServer} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>SQL Server</h2>
                                                        <p>Microsoft’s enterprise database solution.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="DatabaseTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={PostgreSQL} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>PostgreSQL</h2>
                                                        <p>Advanced open-source relational database.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="DatabaseTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={GraphQL} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>GraphQL</h2>
                                                        <p>A query language for APIs.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="DatabaseTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={NoSQL} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>NoSQL</h2>
                                                        <p>For high-performance, non-relational data storage.</p>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="DashboardTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={PowerBI} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Power BI</h2>
                                                        <p>Microsoft's interactive data visualization tool.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="DashboardTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={Tableau} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Tableau</h2>
                                                        <p>Advanced analytics and business intelligence platform.</p>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="MobileAppTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={iOSAndroid} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>iOS & Android</h2>
                                                        <p>Native app development for the two major mobile platforms.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="MobileAppTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={Kotlin} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Kotlin</h2>
                                                        <p>Modern programming language for Android development.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="MobileAppTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={ReactNative} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>React Native</h2>
                                                        <p>Cross-platform mobile application development.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="MobileAppTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={Flutter} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Flutter</h2>
                                                        <p>Google’s UI toolkit for natively compiled applications.</p>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="ToolsTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={Docker} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Docker</h2>
                                                        <p>Containerization platform for simplifying application deployment.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="ToolsTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={Jenkins} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Jenkins</h2>
                                                        <p>Automation server for continuous integration and delivery.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="ToolsTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={Git} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Git</h2>
                                                        <p>Version control system for tracking changes in source code.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="ToolsTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={Kubernetes} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Kubernetes</h2>
                                                        <p>Automated container deployment, scaling, and management.</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </>
                                    ) : category === 'FrontEndTechnologies' ? (
                                        <>
                                            <li className="FrontEndTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={ReactJs} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>React.js</h2>
                                                        <p>Crafting dynamic and responsive user interfaces.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="FrontEndTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={AngularJs} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Angular.js</h2>
                                                        <p>Building scalable web applications with a robust framework.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="FrontEndTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={HTML} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>HTML, CSS, JS</h2>
                                                        <p>The building blocks for web development, ensuring sleek and functional designs.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="FrontEndTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={WordPress} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>WordPress</h2>
                                                        <p>For versatile and easy-to-manage websites, from blogs to business portals.</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </>
                                    ) : category === 'BackEndTechnologies' ? (
                                        <>
                                            <li className="BackEndTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={Python} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Python</h2>
                                                        <p>Versatile programming for everything from web applications to data analysis.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="BackEndTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={NodeJs} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Node.js</h2>
                                                        <p>Efficient, scalable server-side development.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="BackEndTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={dotNET} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>.NET</h2>
                                                        <p>Microsoft's framework for robust web and desktop applications.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="BackEndTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={Java} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Java</h2>
                                                        <p>For enterprise-level backend solutions.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="BackEndTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={PHP} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>PHP</h2>
                                                        <p>Server scripting for dynamic web page content.</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </>
                                    ) : category === 'DatabaseManagement' ? (
                                        <>
                                            <li className="DatabaseTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={Oracle} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Oracle</h2>
                                                        <p>High-performance database solutions for large-scale applications.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="DatabaseTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={MongoDB} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>MongoDB</h2>
                                                        <p>Leading NoSQL database, perfect for handling large data sets.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="DatabaseTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={MySQL} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>MySQL</h2>
                                                        <p>Popular open-source relational database.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="DatabaseTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={SQLServer} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>SQL Server</h2>
                                                        <p>Microsoft’s enterprise database solution.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="DatabaseTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={PostgreSQL} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>PostgreSQL</h2>
                                                        <p>Advanced open-source relational database.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="DatabaseTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={GraphQL} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>GraphQL</h2>
                                                        <p>A query language for APIs.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="DatabaseTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={NoSQL} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>NoSQL</h2>
                                                        <p>For high-performance, non-relational data storage.</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </>
                                    ) : category === 'DashboardingAnalytics' ? (
                                        <>
                                            <li className="DashboardTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={PowerBI} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Power BI</h2>
                                                        <p>Microsoft's interactive data visualization tool.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="DashboardTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={Tableau} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Tableau</h2>
                                                        <p>Advanced analytics and business intelligence platform.</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </>
                                    ) : category === 'MobileAppDevelopment' ? (
                                        <>
                                            <li className="MobileAppTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={iOSAndroid} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>iOS & Android</h2>
                                                        <p>Native app development for the two major mobile platforms.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="MobileAppTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={Kotlin} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Kotlin</h2>
                                                        <p>Modern programming language for Android development.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="MobileAppTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={ReactNative} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>React Native</h2>
                                                        <p>Cross-platform mobile application development.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="MobileAppTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={Flutter} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Flutter</h2>
                                                        <p>Google’s UI toolkit for natively compiled applications.</p>
                                                    </div>
                                                </div>
                                            </li>

                                        </>
                                    ) : (
                                        <>
                                            <li className="ToolsTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={Docker} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Docker</h2>
                                                        <p>Containerization platform for simplifying application deployment.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="ToolsTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={Jenkins} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Jenkins</h2>
                                                        <p>Automation server for continuous integration and delivery.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="ToolsTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={Git} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Git</h2>
                                                        <p>Version control system for tracking changes in source code.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="ToolsTech">
                                                <div className="itemWrap">
                                                    <div className="iconWrap">
                                                        <img src={Kubernetes} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="dataWrap">
                                                        <h2>Kubernetes</h2>
                                                        <p>Automated container deployment, scaling, and management.</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default CustomApplication;