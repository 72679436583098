import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import FashionListIco1 from './assets/img/FashionListIco-1.png';
import FashionListIco2 from './assets/img/SocialMediaIco-5.png';
import FashionListIco3 from './assets/img/ZohoCRM.png';
import FashionListIco4 from './assets/img/SMBsIco-1.png';
import FashionListIco5 from './assets/img/SocialMediaIco-6.png';
import FashionListIco6 from './assets/img/seoIco-4.png';
import IcoImg from "./assets/img/Ico.png";
const Fashion = () => {
    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="fashionBanner innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Fashion</h2>
                                    <p>At Hummingtec Solutions, we're transforming the fashion industry with our innovative technology and digital solutions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="innerPageIntroData">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dataWrap">
                                    <p>At Hummingtec Solutions, we're transforming the fashion industry with our innovative technology and digital solutions. Specializing in cutting-edge tools such as AI-driven trend forecasting, virtual try-on applications, and robust e-commerce platforms, we empower fashion brands to stay ahead in the fast-paced market. Our AI solutions offer predictive analytics for trend spotting, enabling designers to create collections that resonate with upcoming styles and consumer preferences. This not only enhances product relevancy but also optimizes inventory management, reducing waste and increasing sustainability.</p>
                                    <p>Our virtual try-on technology revolutionizes the shopping experience, allowing customers to visualize how garments look on them through their devices, thereby increasing confidence in online purchases and reducing return rates. For e-commerce, we deliver seamless, user-friendly platforms that integrate smoothly with CRM and ERP systems, facilitating efficient operations from inventory management to customer engagement.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fashionWrap aboutSectionWrap">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-md-5">
                                <div className="dataWrap sticyDiv">
                                    <h2>Strategy & Consulting</h2>
                                    <h3>Organic Growth:</h3>
                                    <ul>
                                        <li>1. Engage with Followers.</li>
                                        <li>2. Demography & Audience Analysis</li>
                                        <li>3. Create Shareable Content</li>
                                        <li>4. Leverage Trends</li>
                                        <li>5. Utilize Instagram Features</li>
                                        <li>6. Collaborate and Cross-Promote</li>
                                        <li>7. SOptimize Posting Schedule</li>
                                        <li>8. Hashtag Strategy</li>
                                        <li>9. Utilize Instagram Analytics</li>
                                    </ul>
                                    <h3>One-on-One Consulting:</h3>
                                    <ul>
                                        <li>1. Personalized support with individual action plans</li>
                                        <li>2.Community building opportunities through networking</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="masonaryWrap">
                                    <ResponsiveMasonry columnsCountBreakPoints={{ 380: 1, 480: 2 }}>
                                        <Masonry>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={FashionListIco1} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Trend Monitoring</h2>
                                                    <ul>
                                                        <li>Analyze trending content using Facebook & Instagram Insights</li>
                                                        <li>CRM Integration for unified analytics</li>
                                                        <li>Utilize historical data for trend prediction</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="itemWrap">
                                                <div class="icoWrap">
                                                    <img src={FashionListIco2} class="img-fluid" alt="" />
                                                </div>
                                                <div class="dataWrap">
                                                    <h2>Analytics Integration</h2>
                                                    <ul>
                                                        <li>Integrate CRM & Meta Analytics for a unified dataset</li>
                                                        <li>Customizable Dashboards for real-time tracking</li>
                                                        <li>Cross-platform insights for comprehensive evaluation</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="itemWrap">
                                                <div class="icoWrap">
                                                    <img src={FashionListIco3} class="img-fluid" alt="" />
                                                </div>
                                                <div class="dataWrap">
                                                    <h2>Engagement</h2>
                                                    <ul>
                                                        <li>Centralize customer data in CRM</li>
                                                        <li>Utilize own SMS solution for targeted messaging</li>
                                                        <li>Engage on WhatsApp with Wati</li>
                                                        <li>Design personalized email campaigns</li>
                                                        <li>Integrate channels within CM for consistent messaging</li>
                                                        <li>Encourage feedback through all channels</li>
                                                        <li>Implement automation tools within CRM</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="itemWrap">
                                                <div class="icoWrap">
                                                    <img src={FashionListIco4} class="img-fluid" alt="" />
                                                </div>
                                                <div class="dataWrap">
                                                    <h2>E-commerce Integration</h2>
                                                    <ul>
                                                        <li>Connect Shopify & other marketplaces for streamlined operations</li>
                                                        <li>Unified product listings for a cohesive experience</li>
                                                        <li>Inventory sync for real-time updates</li>
                                                        <li>Order processing optimization with automated workflows</li>
                                                        <li>Customer data centralization for personalized marketing</li>
                                                        <li>Analytics-driven optimization for data-driven decisions</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="itemWrap">
                                                <div class="icoWrap">
                                                    <img src={FashionListIco6} class="img-fluid" alt="" />
                                                </div>
                                                <div class="dataWrap">
                                                    <h2>Targeted Advertising</h2>
                                                    <ul>
                                                        <li>Analyze audience with insights</li>
                                                        <li>Create custom ad campaigns for targeted segments</li>
                                                        <li>Optimize ad placement on Facebook & Instagram</li>
                                                        <li>Allocate budget strategically based on data</li>
                                                        <li>Schedule ads for peak engagement times</li>
                                                        <li>Review analytics continuously for adaptive strategies</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="itemWrap">
                                                <div class="icoWrap">
                                                    <img src={FashionListIco5} class="img-fluid" alt="" />
                                                </div>
                                                <div class="dataWrap">
                                                    <h2>Benefits for the Influencer</h2>
                                                    <ul>
                                                        <li>Holistic growth through organic and inorganic strategies</li>
                                                        <li>Data-driven decision-making with advanced analytics</li>
                                                        <li>Monetization opportunities through optimized e-commerce and advertising</li>
                                                        <li>Community loyalty with a dedicated and engaged following</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Masonry>
                                    </ResponsiveMasonry>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fashionBtmWrap">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <div className="itemWrap bgClr1">
                                    <h2>Digital Marketing and SEO Services</h2>
                                    <ul>
                                        <li>Advanced SEO Strategies</li>
                                        <li>Google/Yelp Review Management</li>
                                        <li>SMS Notifications for various purposes</li>
                                        <li>Social Media Management</li>
                                        <li>QR Code Feedback Mechanism</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="itemWrap bgClr2">
                                    <h2>Website Development and Enhancement</h2>
                                    <ul>
                                        <li>Basic and Comprehensive Website Revamp</li>
                                        <li>Chatbot Integration Telehealth Services</li>
                                        <li>Inventory Management</li>
                                        <li>Customized Patient Portal</li>
                                        <li>Mobile App Development</li>
                                        <li>Document Management Tool</li>
                                        <li>ERP Sloutions</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="itemWrap bgClr3">
                                    <h2>Engagement and Analytics Solutions</h2>
                                    <ul>
                                        <li>Automated Appointment Reminders via SMS</li>
                                        <li>Interactive Online Forms</li>
                                        <li>Data Analytics Dashboards</li>
                                        <li>Gamified Staff Engagement Platform</li>
                                        <li>Quizzes and Training Modules</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="whyHumingtecSec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dataWrap">
                                    <p>Hummingtec's commitment to the fashion industry goes beyond technology. We provide strategic consulting to navigate digital transformation, ensuring our clients leverage the digital landscape for brand growth, operational efficiency, and enhanced customer engagement. Partner with Hummingtec Solutions to redefine your fashion business for the digital age.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default Fashion;