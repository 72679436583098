import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import FashionListIco1 from './assets/img/FashionListIco-1.png';
import FashionListIco2 from './assets/img/SocialMediaIco-5.png';
import FashionListIco3 from './assets/img/ZohoCRM.png';
import FashionListIco4 from './assets/img/SMBsIco-1.png';
import FashionListIco5 from './assets/img/SocialMediaIco-6.png';
import FashionListIco6 from './assets/img/seoIco-4.png';
import IcoImg from "./assets/img/Ico.png";
const Construction = () => {
    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="fashionBanner innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Construction Industry</h2>
                                    <p>Transforming the Construction Industry with Innovative Technology</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="innerPageIntroData">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dataWrap">
                                    <p>At Hummingtec Solutions, we are dedicated to transforming the construction industry with our innovative technology and digital solutions. Specializing in cutting-edge tools and services, we empower construction companies to enhance efficiency, reduce costs, and improve project outcomes. Our solutions range from predictive analytics and project management software to advanced CRM and ERP systems integration.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="staffAugBenefitsWrap constructionIndustWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="imgWrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 64 64">
                                                    <g>
                                                        <path d="M32 64a23.58 23.58 0 0 0 9.24-1.87 1 1 0 1 0-.76-1.78 22 22 0 0 1-17 0 1 1 0 0 0-.76 1.78A23.58 23.58 0 0 0 32 64ZM9.38 37.45h.16a1 1 0 0 0 1-.81 21.62 21.62 0 0 1 6.09-11.8 1 1 0 1 0-1.37-1.37 23.57 23.57 0 0 0-6.67 12.87 1 1 0 0 0 .79 1.11ZM52.08 31.78a21.76 21.76 0 0 1 1.42 4.87 1 1 0 0 0 1 .81h.16a1 1 0 0 0 .79-1.11 23.57 23.57 0 0 0-6.63-12.86 1 1 0 1 0-1.37 1.37 21.4 21.4 0 0 1 4.63 6.92ZM48.3 46.46a5.72 5.72 0 1 0 5.7-5.71 5.73 5.73 0 0 0-5.7 5.71Zm9.49 0A3.78 3.78 0 1 1 54 42.69a3.77 3.77 0 0 1 3.79 3.77Z" fill="#9ceebd" opacity="1" data-original="#9ceebd"></path>
                                                        <path d="M54 52.49a10 10 0 0 0-10 10V63a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-.56a10 10 0 0 0-10-9.95Zm-8 9.57a8 8 0 0 1 16.06 0ZM32 11.43a5.72 5.72 0 1 0-5.71-5.71A5.72 5.72 0 0 0 32 11.43Zm0-9.49a3.78 3.78 0 1 1-3.78 3.78A3.78 3.78 0 0 1 32 1.94Z" fill="#9ceebd" opacity="1" data-original="#9ceebd"></path>
                                                        <path d="M23 23.25h18a1 1 0 0 0 1-1v-.56a10 10 0 1 0-20 0v.56a1 1 0 0 0 1 1Zm9-9.57a8 8 0 0 1 8 7.63H24a8 8 0 0 1 8-7.63ZM10 52.18a5.72 5.72 0 1 0-5.71-5.72A5.73 5.73 0 0 0 10 52.18Zm0-9.49a3.78 3.78 0 1 1-3.77 3.77A3.78 3.78 0 0 1 10 42.69Z" fill="#9ceebd" opacity="1" data-original="#9ceebd"></path>
                                                        <path d="M1 64h18a1 1 0 0 0 1-1v-.56a10 10 0 0 0-20 0V63a1 1 0 0 0 1 1Zm9-9.57a8 8 0 0 1 8 7.63H2a8.06 8.06 0 0 1 8-7.63ZM17 39.66l2.24 1a11.53 11.53 0 0 0 0 2l-2.26.91a1 1 0 0 0-.53.52 1 1 0 0 0 0 .75l2.27 5.62a1 1 0 0 0 1.26.54l2.19-1a15 15 0 0 0 1.37 1.39l-.95 2.24a1 1 0 0 0 .52 1.27l5.58 2.37a1 1 0 0 0 1.31-.53l.95-2.23a12.69 12.69 0 0 0 1.95 0l.92 2.26a1 1 0 0 0 .52.53 1 1 0 0 0 .38.07.92.92 0 0 0 .36-.07l5.58-2.3a1 1 0 0 0 .54-1.26l-.91-2.25a12.93 12.93 0 0 0 1.39-1.38l2.24 1a1 1 0 0 0 .74 0 1 1 0 0 0 .53-.52L47.56 45a1 1 0 0 0-.51-1.28l-2.24-.94a11.66 11.66 0 0 0 0-2l2.26-.91a1 1 0 0 0 .53-.52 1 1 0 0 0 0-.74L45.34 33a1 1 0 0 0-1.26-.53l-2.25.91A13.82 13.82 0 0 0 40.46 32l.95-2.24a1 1 0 0 0-.52-1.27l-5.58-2.37a1 1 0 0 0-.74 0 1 1 0 0 0-.53.52l-.95 2.23a12.69 12.69 0 0 0-2 0l-.92-2.26a.93.93 0 0 0-.52-.53 1 1 0 0 0-.74 0l-5.62 2.28a1 1 0 0 0-.54 1.26l.91 2.25a12.83 12.83 0 0 0-1.39 1.37l-2.24-1a1 1 0 0 0-1.27.51l-2.37 5.59a1 1 0 0 0 .61 1.32Zm3.27-5.21 2 .86a1 1 0 0 0 1.14-.3A11.31 11.31 0 0 1 25.46 33a1 1 0 0 0 .32-1.14l-.82-2 3.83-1.55.82 2a1 1 0 0 0 1 .6 11 11 0 0 1 2.92 0 1 1 0 0 0 1-.58l.85-2 3.8 1.61-.85 2a1 1 0 0 0 .3 1.14 11.31 11.31 0 0 1 2.06 2.09 1 1 0 0 0 1.14.32l2-.82 1.54 3.83-2 .82a1 1 0 0 0-.6 1 10.53 10.53 0 0 1 0 2.86v.09a1 1 0 0 0 .59 1l2 .85-1.61 3.8-2-.86a1 1 0 0 0-1.14.3 11 11 0 0 1-2.09 2.06 1 1 0 0 0-.32 1.14l.82 2-3.83 1.55-.82-2a1 1 0 0 0-.9-.61h-.12a11 11 0 0 1-2.92 0 1 1 0 0 0-1 .58l-.85 2-3.8-1.61.85-2a1 1 0 0 0-.3-1.15 11 11 0 0 1-2.06-2.08.94.94 0 0 0-1.13-.32l-2 .81-1.54-3.82 2-.82a1 1 0 0 0 .6-1 10.38 10.38 0 0 1 0-2.84.43.43 0 0 1 0-.11 1 1 0 0 0-.59-1l-2-.86Z" fill="#9ceebd" opacity="1" data-original="#9ceebd"></path>
                                                        <path d="M32 49.86a8.17 8.17 0 1 0-8.17-8.16A8.18 8.18 0 0 0 32 49.86Zm0-14.39a6.23 6.23 0 1 1-6.23 6.23A6.24 6.24 0 0 1 32 35.47Z" fill="#9ceebd" opacity="0.45" data-original="#9ceebd"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Predictive Analytics for Demand Forecasting</h2>
                                                <p>Our AI-driven predictive analytics models forecast demand for materials and resources, helping construction companies optimize resource allocation, reduce waste, and avoid project delays.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="imgWrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 512 512">
                                                    <g>
                                                        <path d="M421.713 263.57V244.5c0-33.137-26.863-60-60-60h-40c-33.137 0-60 26.863-60 60v19.07" fill="none" stroke="#9ccdee" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" data-original="#9ccdee"></path>
                                                        <path d="M301.713 263.57V244.5c0-11.046 8.954-20 20-20h40c11.046 0 20 8.954 20 20v19.07M181.713 314.077v151.791c0 21.336 17.296 38.632 38.632 38.632h242.736c21.336 0 38.632-17.296 38.632-38.632V314.077M388.506 386.834h-93.577" fill="none" stroke="#9ccdee" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" data-original="#9ccdee"></path>
                                                        <path d="M242.963 386.687c-34.208-2.275-61.25-30.743-61.25-65.527v-18.028c0-21.336 17.296-38.632 38.632-38.632h242.736c21.336 0 38.632 17.296 38.632 38.632v18.028c0 34.632-26.807 63.004-60.803 65.496" fill="none" stroke="#9ccdee" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" data-original="#9ccdee"></path>
                                                        <path d="M243.777 502.5v-133c0-13.807 11.193-25 25-25h0c13.807 0 25 11.193 25 25v133M389.649 455.884V502.5M439.649 502.5v-133c0-13.807-11.193-25-25-25h0c-13.807 0-25 11.193-25 25v51.235M279.269 296.729v11.999M404.158 296.729v11.999M325.25 308.371c4.003 4.593 9.892 7.5 16.463 7.5 6.571 0 12.46-2.907 16.463-7.5M83.23 55.695C118.902 25.622 164.978 7.5 215.287 7.5c113.218 0 205 91.782 205 205 0 4.286-.132 8.541-.391 12.762M180.319 414.529C83.758 397.934 10.287 313.797 10.287 212.5c0-50.433 18.211-96.612 48.417-132.32" fill="none" stroke="#9ccdee" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" data-original="#9ccdee" opacity="0.45"></path>
                                                        <path d="m386.431 188.935-8.489-16.021a64.72 64.72 0 0 0-95.608-21.779l-18.121 13.368c-9.564 7.055-22.875 6.81-31.805-1.032-9.789-8.597-11.347-23.217-3.661-33.672l12.28-16.705a71.313 71.313 0 0 0 5.713-75.326h0a71.045 71.045 0 0 0-10.452-14.904A71.507 71.507 0 0 0 217.933 8.4M38.64 312.726c13.061 1.073 25.711-5.783 31.821-17.706l28.285-55.196a29.473 29.473 0 0 1 11.113-11.864v0a29.487 29.487 0 0 1 15.754-4.176h0c15.005.313 27.371 11.839 28.758 26.758.959 10.313 6.747 19.658 15.997 24.317 6.184 3.115 12.187 4.25 17.854 4.046M289.764 23.49l13.071 34.297c19.719 51.74 69.852 48.839 85.562 48.844M180.684 351.222a54.999 54.999 0 0 1-14.702 2.805 55.028 55.028 0 0 1-27.54-5.716c-21.271-10.635-46.473-5.08-61.471 12.13" fill="none" stroke="#9ccdee" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" data-original="#9ccdee" opacity="0.45"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Project Management Software</h2>
                                                <p>We provide comprehensive project management software that integrates with your existing systems, offering real-time tracking, automated alerts, and efficient workflow management to ensure projects stay on schedule and within budget.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="imgWrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 66 66">
                                                    <g>
                                                        <path d="M45.93 6.96h-8.5c-.48 0-.88.39-.88.88s.39.88.88.88h8.5c.48 0 .88-.39.88-.88s-.4-.88-.88-.88zM34.07 6.82c-.56 0-1.01.45-1.01 1.01s.45 1.01 1.01 1.01 1.01-.45 1.01-1.01-.45-1.01-1.01-1.01zM45.93 10.89h-8.5c-.48 0-.88.39-.88.88s.39.88.88.88h8.5c.48 0 .88-.39.88-.88s-.4-.88-.88-.88zM34.07 10.75c-.56 0-1.01.45-1.01 1.01s.45 1.01 1.01 1.01 1.01-.45 1.01-1.01-.45-1.01-1.01-1.01zM45.93 14.82h-8.5c-.48 0-.88.39-.88.88s.39.88.88.88h8.5c.48 0 .88-.39.88-.88s-.4-.88-.88-.88zM34.07 14.69c-.56 0-1.01.45-1.01 1.01s.45 1.01 1.01 1.01 1.01-.45 1.01-1.01-.45-1.01-1.01-1.01zM27.71 49.82h-3.98c-.48 0-.88.39-.88.88v8.39h-2.21V46.26c0-.48-.39-.88-.88-.88h-3.98c-.48 0-.88.39-.88.88v12.82h-2.21V40.86c0-.48-.39-.88-.88-.88H7.86c-.48 0-.88.39-.88.88v18.23h-.44c-.48 0-.88.39-.88.88s.39.88.88.88h23.02c.48 0 .88-.39.88-.88s-.39-.88-.88-.88h-.97V50.7c0-.49-.39-.88-.88-.88zm-3.1 1.75h2.23v7.52h-2.23zm-7.94-4.43h2.23v11.95h-2.23zm-7.94-5.41h2.23v17.35H8.73zM47.43 45.12c4.37-4.37 4.37-11.49 0-15.86-2.12-2.12-4.93-3.28-7.93-3.28s-5.81 1.17-7.93 3.29-3.28 4.93-3.28 7.93c0 2.99 1.17 5.81 3.29 7.93s4.94 3.28 7.93 3.28 5.8-1.18 7.92-3.29zm-17.4-7.93c0-2.53.98-4.9 2.77-6.69s4.17-2.77 6.69-2.77c2.53 0 4.9.98 6.69 2.77 3.69 3.69 3.69 9.7 0 13.39a9.403 9.403 0 0 1-6.69 2.77c-2.53 0-4.91-.98-6.69-2.77a9.443 9.443 0 0 1-2.77-6.7z" fill="#cb9cee" opacity="0.45" data-original="#cb9cee"></path>
                                                        <path d="m63.23 55.37-7.93-7.93a2.807 2.807 0 0 0-.41-3.44l-1.21-1.21c.7-1.76 1.07-3.66 1.07-5.61 0-4.08-1.59-7.91-4.47-10.79-5.95-5.95-15.63-5.95-21.58 0a15.158 15.158 0 0 0-4.47 10.79c0 4.07 1.59 7.91 4.47 10.79 2.97 2.97 6.88 4.46 10.79 4.46 1.9 0 3.81-.36 5.6-1.07l1.22 1.22a2.799 2.799 0 0 0 3.44.41l7.93 7.93c.74.74 1.73 1.15 2.77 1.15s2.03-.41 2.77-1.15 1.15-1.73 1.15-2.77-.4-2.03-1.14-2.78zm-33.28-8.63c-2.55-2.55-3.96-5.94-3.96-9.55s1.4-7 3.96-9.55c2.55-2.55 5.94-3.96 9.55-3.96s7 1.41 9.55 3.96S53 33.58 53 37.19c0 1.91-.4 3.75-1.15 5.44-.01.02-.02.03-.02.05-.66 1.49-1.59 2.87-2.78 4.05A13.37 13.37 0 0 1 45 49.51c-.02.01-.04.02-.05.03-1.69.75-3.54 1.15-5.44 1.15-3.62 0-7.01-1.4-9.56-3.95zm17.6 4.61-.77-.77a14.78 14.78 0 0 0 3.5-2.61c1.05-1.05 1.92-2.23 2.61-3.49l.76.76a1.06 1.06 0 0 1 0 1.5l-.15.15-4.3 4.31-.14.14c-.4.41-1.1.41-1.51.01zm14.44 8.33c-.82.82-2.25.82-3.07 0l-7.86-7.86 3.07-3.07 7.86 7.86c.41.41.64.96.64 1.54s-.23 1.12-.64 1.53z" fill="#cb9cee" opacity="0.45" data-original="#cb9cee"></path>
                                                        <path d="M40.37 44.09v-.67c1.57-.39 2.74-1.81 2.74-3.49 0-1.99-1.62-3.61-3.61-3.61-1.03 0-1.86-.84-1.86-1.86s.84-1.86 1.86-1.86 1.86.84 1.86 1.86c0 .48.39.88.88.88s.88-.39.88-.88c0-1.69-1.17-3.1-2.74-3.49v-.67c0-.48-.39-.88-.88-.88s-.88.39-.88.88v.67c-1.57.39-2.74 1.81-2.74 3.49 0 1.99 1.62 3.61 3.61 3.61 1.03 0 1.86.84 1.86 1.86 0 1.03-.84 1.86-1.86 1.86s-1.86-.84-1.86-1.86c0-.48-.39-.88-.88-.88s-.88.39-.88.88c0 1.69 1.17 3.1 2.74 3.49v.67c0 .48.39.88.88.88s.88-.4.88-.88zM25.74 17.29h-8.22V9.08c0-.48-.39-.88-.88-.88-5.5 0-9.97 4.47-9.97 9.97s4.47 9.97 9.97 9.97 9.97-4.47 9.97-9.97a.88.88 0 0 0-.87-.88zm-9.1 9.1c-4.53 0-8.22-3.69-8.22-8.22 0-4.24 3.22-7.73 7.34-8.17v8.17c0 .48.39.88.88.88h8.17c-.43 4.12-3.93 7.34-8.17 7.34z" fill="#cb9cee" opacity="0.45" data-original="#cb9cee"></path>
                                                        <path d="M29.64 15.14c0-5.5-4.47-9.97-9.97-9.97-.48 0-.88.39-.88.88v9.09c0 .48.39.88.88.88h9.09c.49-.01.88-.4.88-.88zm-9.09-.88v-7.3c3.83.41 6.89 3.46 7.3 7.3z" fill="#cb9cee" opacity="1" data-original="#cb9cee"></path>
                                                        <path d="M50.37 55.29c-.48 0-.88.39-.88.88v3.8c0 1.75-1.42 3.17-3.17 3.17H6.54c-1.75 0-3.17-1.42-3.17-3.17V6.04c0-1.75 1.42-3.17 3.17-3.17h39.79c1.75 0 3.17 1.42 3.17 3.17v16.32c0 .48.39.88.88.88s.88-.39.88-.88V6.04a4.93 4.93 0 0 0-4.92-4.92H6.54a4.93 4.93 0 0 0-4.92 4.92v53.92a4.93 4.93 0 0 0 4.92 4.92h39.79a4.93 4.93 0 0 0 4.92-4.92v-3.8a.886.886 0 0 0-.88-.87z" fill="#cb9cee" opacity="1" data-original="#cb9cee"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Advanced CRM and ERP Systems Integration</h2>
                                                <p>Our robust CRM and ERP integration solutions streamline operations, from inventory management to customer engagement, ensuring efficient and cohesive project execution.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fashionWrap aboutSectionWrap">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-md-5">
                                <div className="dataWrap sticyDiv">
                                    <h2>Strategy & Consulting</h2>
                                    <h3>Organic Growth:</h3>
                                    <ul>
                                        <li>1. Engage with Stakeholders</li>
                                        <li>2. Demography & Audience Analysis</li>
                                        <li>3. Create Shareable Content</li>
                                        <li>4. Leverage Industry Trends</li>
                                        <li>5. Utilize Digital Features</li>
                                        <li>6. Collaborate and Cross-Promote</li>
                                        <li>7. Optimize Communication Schedule</li>
                                        <li>8. Hashtag Strategy for Social Media</li>
                                        <li>9. Utilize Analytics Tools</li>
                                    </ul>
                                    <h3>One-on-One Consulting:</h3>
                                    <ul>
                                        <li>1. Personalized support with individual action plans</li>
                                        <li>2. Community building opportunities through networking</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="masonaryWrap">
                                    <ResponsiveMasonry columnsCountBreakPoints={{ 380: 1, 480: 2 }}>
                                        <Masonry>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={FashionListIco1} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Trend Monitoring</h2>
                                                    <ul>
                                                        <li>Analyze industry trends using advanced analytics tools</li>
                                                        <li>CRM Integration for unified data analysis</li>
                                                        <li>Utilize historical data for trend prediction</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="itemWrap">
                                                <div class="icoWrap">
                                                    <img src={FashionListIco2} class="img-fluid" alt="" />
                                                </div>
                                                <div class="dataWrap">
                                                    <h2>Engagement</h2>
                                                    <ul>
                                                        <li>Centralize stakeholder data in CRM</li>
                                                        <li>Utilize SMS solutions for targeted messaging</li>
                                                        <li>Engage through multiple communication channels</li>
                                                        <li>Design personalized email campaigns</li>
                                                        <li>Integrate all communication channels for consistent messaging</li>
                                                        <li>Encourage feedback through all platforms</li>
                                                        <li>Implement automation tools within CRM</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="itemWrap">
                                                <div class="icoWrap">
                                                    <img src={FashionListIco3} class="img-fluid" alt="" />
                                                </div>
                                                <div class="dataWrap">
                                                    <h2>Targeted Advertising</h2>
                                                    <ul>
                                                        <li>Analyze audience with insights</li>
                                                        <li>Create custom ad campaigns for targeted segments</li>
                                                        <li>Optimize ad placement across various platforms</li>
                                                        <li>Allocate budget strategically based on data</li>
                                                        <li>Schedule ads for peak engagement times</li>
                                                        <li>Review analytics continuously for adaptive strategies</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="itemWrap">
                                                <div class="icoWrap">
                                                    <img src={FashionListIco4} class="img-fluid" alt="" />
                                                </div>
                                                <div class="dataWrap">
                                                    <h2>Analytics Integration</h2>
                                                    <ul>
                                                        <li>Integrate CRM & project management analytics for a unified dataset</li>
                                                        <li>Customizable dashboards for real-time tracking</li>
                                                        <li>Cross-platform insights for comprehensive evaluation</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="itemWrap">
                                                <div class="icoWrap">
                                                    <img src={FashionListIco6} class="img-fluid" alt="" />
                                                </div>
                                                <div class="dataWrap">
                                                    <h2>E-commerce Integration</h2>
                                                    <ul>
                                                        <li>Connect construction marketplaces for streamlined operations</li>
                                                        <li>Unified product and service listings for a cohesive experience</li>
                                                        <li>Inventory sync for real-time updates</li>
                                                        <li>Order processing optimization with automated workflows</li>
                                                        <li>Centralized customer data for personalized marketing</li>
                                                        <li>Analytics-driven optimization for data-driven decisions</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="itemWrap">
                                                <div class="icoWrap">
                                                    <img src={FashionListIco5} class="img-fluid" alt="" />
                                                </div>
                                                <div class="dataWrap">
                                                    <h2>Digital Marketing and SEO Services</h2>
                                                    <ul>
                                                        <li>Advanced SEO Strategies</li>
                                                        <li>Online Review Management</li>
                                                        <li>SMS Notifications for various purposes</li>
                                                        <li>Social Media Management</li>
                                                        <li>QR Code Feedback Mechanism</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="itemWrap">
                                                <div class="icoWrap">
                                                    <img src={FashionListIco5} class="img-fluid" alt="" />
                                                </div>
                                                <div class="dataWrap">
                                                    <h2>Website Development and Enhancement</h2>
                                                    <ul>
                                                        <li>Basic and Comprehensive Website Revamp</li>
                                                        <li>Chatbot Integration</li>
                                                        <li>Inventory Management</li>
                                                        <li>Customized Client Portal</li>
                                                        <li>Mobile App Development</li>
                                                        <li>Document Management Tools</li>
                                                        <li>ERP Solutions</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="itemWrap">
                                                <div class="icoWrap">
                                                    <img src={FashionListIco5} class="img-fluid" alt="" />
                                                </div>
                                                <div class="dataWrap">
                                                    <h2>Engagement and Analytics Solutions</h2>
                                                    <ul>
                                                        <li>Automated Appointment Reminders via SMS</li>
                                                        <li>Interactive Online Forms</li>
                                                        <li>Data Analytics Dashboards</li>
                                                        <li>Gamified Staff Engagement Platform</li>
                                                        <li>Quizzes and Training Modules</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Masonry>
                                    </ResponsiveMasonry>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="whyHumingtecSec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dataWrap">
                                    <p>Hummingtec's commitment to the construction industry goes beyond technology. We provide strategic consulting to navigate digital transformation, ensuring our clients leverage the digital landscape for operational efficiency, cost reduction, and enhanced project outcomes. Partner with Hummingtec Solutions to redefine your construction business for the digital age.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default Construction;