import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from './assets/img/logo.png';
import Modal from 'react-bootstrap/Modal';
import emailjs from 'emailjs-com';
const Footer = () => {
    const [lgShow, setLgShow] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);
    const [showCookieMessage, setShowCookieMessage] = useState(true);
    const [acceptedCookies, setAcceptedCookies] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        interest: '',
        message: ''
    });
    const [thankYouPopup, setThankYouPopup] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
      
        try {
            const templateParams = {
                ...formData,
            };
            await emailjs.send('service_fo6avff', 'template_bvon8go', templateParams, 'G815HLz7iB0QllWGn');
            setThankYouPopup(true);
            setFormData({
                name: '',
                email: '',
                mobile: '',
                interest: '',
                message: ''
            });
            setLgShow(false);
            setShowThankYou(true);
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('An error occurred while submitting the form. Please try again later.');
        }
    };

    const location = useLocation();
    const isAllowedPage = [
        '/', '/Manufacturing', '/Fashion', '/Education', '/Healthcare', '/Mobility',
        '/Hospitality', '/LearningManagement', '/MetaHOS', '/Symbio', '/Verge',
        '/CustomApplication', '/DigitalTransformation', '/websiteDevelopment', 
        '/seo', '/SocialMediaManage', '/CustomerEngagement', '/cms', 
        '/WorkflowAutomation', '/MobileApplication', '/Testing', '/CRM', 
        '/CRMDetails', '/salesforceCrm', '/zohoCrm', '/CustomCrm', '/SugarCRM', 
        '/AIML', '/AIMLDetails', '/aiPowered', '/opticalCharRec', '/aiDrivenIn', '/forecastingAndPrediction', '/OCR', 
        '/generativeAi', '/digitalHealth', '/vehicleRouteOpt', '/GPTModelTraining', 
        '/AIChatbots', '/leadGeneration', '/Solutions', '/SMBs', '/Startups', 
        '/EnterprisesCustomers', '/CaseStudy', '/AboutUs', '/Leadership', 
        '/Careers', '/careers/:title', '/VissionMission', '/PrivacyPolicy', 
        '/Disclaimer', '/TermsConditions', '/Pricing', '/Contact'
    ].includes(location.pathname);


    useEffect(() => {
        const hideCookieMessage = localStorage.getItem('hideCookieMessage');
        if (hideCookieMessage || acceptedCookies) {
            setShowCookieMessage(false);
        }
    }, [acceptedCookies]);

    const handleCloseCookieMessage = () => {
        setShowCookieMessage(false);
        localStorage.setItem('hideCookieMessage', 'true');
    };

    const handleAcceptCookies = () => {
        setAcceptedCookies(true);
        localStorage.setItem('hideCookieMessage', 'true');
    };

    const handleRejectCookies = () => {
        setShowCookieMessage(false);
        localStorage.removeItem('hideCookieMessage');
    };

    return (
        <>
            {isAllowedPage && (
                <div className="reachOutWrap">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div className="dataWrap">
                                    <h2>Looking for a Technology Partner with proven track record to bring your <i>ideas</i> to life?</h2>
                                    <button className="btn" onClick={() => setLgShow(true)}>Contact Us</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <footer>
                <div class="footerWrap">
                    <div class="container">
                        <div class="row justify-content-between">
                            <div class="col-xl-4 col-lg-4 col-md-4">
                                <div class="logoWrap">
                                    <Link to="/">
                                        <img src={Logo} class="img-fluid" alt="" title="" />
                                    </Link>
                                </div>
                                <div class="footerInfoWrap">
                                    <p>To compete in the digital age, you need to stay ahead of emerging technologies. We partner with you to envision, execute and expedite innovative digital and mobile solutions.</p>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-5  col-md-6">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="footerMenu">
                                            <h2>Quick Links</h2>
                                            <ul>
                                                <li><Link to="/">Home</Link></li>
                                                <li><Link to="/">About Us</Link></li>
                                                <li><Link to="/">Services</Link></li>
                                                <li><Link to="/">Products</Link></li>
                                                <li><Link to="/Contact">Contact us</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="footerMenu">
                                            <h2>Industries</h2>
                                            <ul>
                                                <li><Link to="/Healthcare">Healthcare</Link></li>
                                                <li><Link to="/Manufacturing">Manufacturing</Link></li>
                                                <li><Link to="/Mobility">Mobility</Link></li>
                                                <li><Link to="/Hospitality">Hospitality</Link></li>
                                                <li><Link to="/Construction">Construction</Link></li>
                                                <li><Link to="/Education">Education</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="copyrightWrap">
                            <div class="row">
                                <div class="col-xl-4 col-lg-3 col-md-3 pad0">
                                    <div class="dataWrap">
                                        <p>Copyright @2024, All Rights Reserved</p>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4">
                                    <ul>
                                        <li><Link to="/TermsConditions">Terms Of Use</Link></li>
                                        <li><Link to="/PrivacyPolicy">Privacy Policy</Link></li>
                                        <li><Link to="/Disclaimer">Disclaimer</Link></li>
                                    </ul>
                                </div>
                                <div className="col-xl-4 col-lg-5 col-md-5">
                                    <div className="locationWrap">
                                        <p>Location - </p>
                                        <ul>
                                            <li>India</li>
                                            <li>USA</li>
                                            <li>Norway</li>
                                            <li>UAE</li>
                                            <li>Australia</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showCookieMessage && (
                        <div className="cookiesWrap">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-md-10">
                                        <div className="dataWrap">
                                            <p>We use cookies (and other similar technologies) to improve your experience on our site. By using this website you agree to our Cookie Policy. You can change your preferences at any time.</p>
                                            <div className="ctaWrap">
                                                <button className="btn btn-accept" onClick={handleAcceptCookies}>Accept</button>
                                                <button className="btn btn-reject" onClick={handleRejectCookies}>Reject</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </footer>
            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                className="contactUsPopup"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="titleWrap">
                        <h2>Let's get in touch</h2>
                        <p>Kindly fill out the form below, and our team will promptly respond to your inquiries.</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <ul className="formList">
                            <li>
                                <div className="inputField">
                                    <label>Name</label>
                                    <input type="text" name="name" value={formData.name} onChange={handleChange} />
                                </div>
                            </li>
                            <li>
                                <div className="inputField">
                                    <label>Email Address</label>
                                    <input type="email" name="email" value={formData.email} onChange={handleChange} />
                                </div>
                            </li>
                            <li>
                                <div className="inputField">
                                    <label>Mobile Number</label>
                                    <input type="tel" name="mobile" value={formData.mobile} onChange={handleChange} />
                                </div>
                            </li>
                            <li>
                                <div className="inputField selectField">
                                    <label>I'm interested in:</label>
                                    <select name="interest" value={formData.interest} onChange={handleChange}>
                                        <option value="">Select an option</option>
                                        <option>Artificial Intelligence</option>
                                        <option>Machine Learning</option>
                                        <option>Custom GPT</option>
                                        <option>Blockchain/Etherium</option>
                                        <option>E-Commerce/Shopping Cart</option>
                                        <option>Salesforce CRM</option>
                                        <option>Zoho CRM</option>
                                        <option>Custom CRM</option>
                                        <option>Website Development</option>
                                        <option>SEO</option>
                                        <option>Social Media Management</option>
                                        <option>Mobile Application Development</option>
                                        <option>Custom Application Development</option>
                                        <option>Security and Testing</option>
                                        <option>Other</option>
                                    </select>
                                </div>
                            </li>
                            <li className="full">
                                <div className="inputField">
                                    <label>Message</label>
                                    <textarea name="message" value={formData.message} onChange={handleChange}></textarea>
                                </div>
                            </li>
                            <li className="full">
                                <div className="ctaWrap">
                                    <button type="submit" className="btnSubmti">Submit</button>
                                </div>
                            </li>
                        </ul>
                    </form>
                </Modal.Body>
            </Modal>
            <Modal
                size="md"
                show={showThankYou}
                onHide={() => setShowThankYou(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                className="contactUsPopup"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="thankYouPopup">
                        <h3>Thank you for contacting us!</h3>
                        <p>We will respond to your inquiry shortly.</p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default Footer;