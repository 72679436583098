import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import aboutImg from "./assets/img/aboutImg.png";
import serviceImg1 from "./assets/img/serviceImg-1.png";
import serviceImg2 from "./assets/img/serviceImg-2.png";
import serviceImg3 from "./assets/img/serviceImg-3.png";
import serviceImg4 from "./assets/img/serviceImg-4.png";
import ProjectVid from "./assets/video/Project.mp4";
const Verge = () => {
    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="bannerWrap innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Products</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ProjectSecWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="videoWrap">
                                    <div className="video">
                                        <video autoPlay loop muted playsInline>
                                            <source src={ProjectVid} type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="dataWrap">
                                    <h2>Verge.io</h2>
                                    <h3>Elevate Your IT Infrastructure with Hummingtec and Verge.io</h3>
                                    <p>Hummingtec, in partnership with Verge.io, brings to your business the next generation of IT infrastructure solutions. Discover the transformative potential of Verge.io through our expert services. Certainly! Here's the revised content for your company, Hummingtec, showcasing your partnership with Verge.io and the services you offer:</p>

                                    <h4>Elevate Your IT Infrastructure with Hummingtec and Verge.io</h4>
                                    <ul className="padLess">
                                        <li>
                                            <div className="dataWrap">
                                                <h2>Revolutionizing Data Center Solutions</h2>
                                                <ul>
                                                    <li> <strong>Empower Small Data Centers:</strong> Offer Verge.io solutions ideal for mid-range data centers, streamlining multiple infrastructures into one efficient operating system, perfect for small IT teams.</li>
                                                    <li><strong>Beyond Traditional HCI:</strong> Overcome the limitations of standard HCI with Verge.io’s ultraconverged infrastructure, offering unparalleled scalability and significant cost savings.</li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataWrap">
                                                <h2>Elevate Data Security and Storage Efficiency</h2>
                                                <ul>
                                                    <li> <strong>Advanced Disaster Recovery with IO protect:</strong> Implement Verge.io's IOprotect for robust disaster recovery, ensuring near-real-time replication and superior protection against ransomware threats.</li>
                                                    <li> <strong>Innovative SAN/NAS Replacement:</strong> Transition to VergeIO’s advanced storage solutions, featuring instant snapshots, global deduplication, and comprehensive backup capabilities.</li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                    <h4>Revolutionize IT with Ultraconverged Infrastructure</h4>
                                    <ul className="padLess">
                                        <li>
                                            <div className="dataWrap">
                                                <h2>Comprehensive Virtualization</h2>
                                                <p>Leverage Verge.io to unify compute, network, storage, and GPU resources, creating versatile environments for diverse workloads.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataWrap">
                                                <h2>Cost Reduction and Simplification</h2>
                                                <p>Dramatically decrease IT expenses and simplify operations, scaling effortlessly with Verge.io's efficient infrastructure.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataWrap">
                                                <h2>Optimized Performance</h2>
                                                <p>Enhance your system's performance using existing hardware, reducing server count and maximizing resource efficiency.</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="aboutSectionWrap zohoCrmIntroWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="dataWrap">
                                    <h2>Why Partner with Hummingtec and Verge.io</h2>
                                    <ul>
                                        <li>
                                            <span>1. Seamless Integration:</span>
                                            Our team at Hummingtec specializes in integrating Verge.io solutions into your business seamlessly, ensuring an effective transition.
                                        </li>
                                        <li>
                                            <span>2. Customized Business Solutions:</span>
                                            We understand the uniqueness of your business. Hummingtec excels in tailoring Verge.io’s technology to meet your specific needs.
                                        </li>
                                        <li>
                                            <span>3. Continuous Advancement:</span>
                                            Stay ahead with Hummingtec’s commitment to continuous learning and the latest developments from Verge.io.
                                        </li>
                                        <li>
                                            <span>4. Unmatched Customer Support:</span>
                                            Our partnership with Verge.io is fortified by our dedication to providing exceptional customer service and support throughout your digital transformation.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="imgWrap">
                                    <img src={aboutImg} className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="howWeWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="dataWrap">
                                    <h3>We also make sure that:</h3>
                                    <ul>
                                        <li>We assign the proper specialist for the delivery of the solution as per the client’s request.</li>
                                        <li>Provide Free Project Estimations.</li>
                                        <li>Equip and integrate the latest technologies, tools and trends.</li>
                                        <li>Create a stable team of experts with proper work and cultural code.</li>
                                        <li>Work Flexible Hours to manage clients query from all over the world.</li>
                                        <li>Assured Quality Controls & Checks.</li>
                                        <li>We believe in providing solutions to your business and not just codes.</li>
                                        <li>The extra security of your data and guarantee IP rights.</li>
                                        <li>From backend to front-end, we develop, promote, market and take care of your business.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 padLt">
                                <div className="topService">
                                    <div className="topLeft">
                                        <span>Popular</span>
                                        <h4>Design for how people think</h4>
                                        <p>Aliquam ut euismod condimentum elementum ultricies volutpat sit non. </p>
                                        <a href="#">Take Lesson</a>
                                    </div>
                                    <div className="topRight">
                                        <img src={serviceImg1} className="img-fluid" alt="" />
                                        <div className="posImg">
                                            <img src={serviceImg2} className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="bottomService">
                                    <div className="leftImg">
                                        <img src={serviceImg3} className="img-fluid" alt="" />
                                    </div>
                                    <div className="rightImg">
                                        <img src={serviceImg4} className="img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default Verge;