import { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet';
import Modal from 'react-bootstrap/Modal';
import emailjs from 'emailjs-com';
import Footer from "./Footer";
import Header from "./Header";
import aboutImg from "./assets/img/aboutImg.png";
import opticalCharRecImg1 from "./assets/img/opticalCharRecImg-1.png";
import opticalCharRecImg2 from "./assets/img/opticalCharRecImg-2.png";
import opticalCharRecImg3 from "./assets/img/opticalCharRecImg-3.png";

const IntelligentChatAutomation = () => {
    const location = useLocation();
    const [lgShow, setLgShow] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        interest: '',
        message: ''
    });
    const [thankYouPopup, setThankYouPopup] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
      
        try {
            const templateParams = {
                ...formData,
            };
            await emailjs.send('service_fo6avff', 'template_bvon8go', templateParams, 'G815HLz7iB0QllWGn');
            setThankYouPopup(true);
            setFormData({
                name: '',
                email: '',
                mobile: '',
                interest: '',
                message: ''
            });
            setLgShow(false);
            setShowThankYou(true);
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('An error occurred while submitting the form. Please try again later.');
        }
    };

    const empowerIndusSlider = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="topPageBanner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="titleWrap">
                                    <h2>Intelligent Chat Automation</h2>
                                    <p>Elevating Customer Engagement Across Platforms</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="aiMlWrap commonCotentWrap AIChatbotsWrap">
                    <div className="container">
                        {/* <div className="row">
                            <div className="col-md-12">
                                <ul className="bredCrumbsWrap">
                                    <li>
                                        <Link to="/AIML">AI/ML</Link>
                                    </li>
                                    <li>OCR</li>
                                </ul>
                            </div>
                        </div> */}
                        <div className="row justify-content-between">
                            <div className="col-md-12">
                                <div className="titleWrap">
                                    <h3>Revolutionize Your Customer Support with Text, Email, Messenger, and WhatsApp Integration</h3>
                                    <p>In today's fast-paced digital landscape, customer expectations are higher than ever. Our intelligent chat automation solution helps businesses of all sizes stay ahead by delivering immediate, accurate, and personalized support. Whether it's through SMS, email, Facebook Messenger, or WhatsApp, our chatbot adapts to the channels your customers prefer, ensuring seamless interaction while handling everything from routine inquiries to order management.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="staffAugItroWrap staffAugValueWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="imgWrap">
                                    <img src={opticalCharRecImg1} className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="dataWrap dataWrapPadLt">
                                    <h2>Key Benefits of Our Chat Automation Solution</h2>
                                    <ul>
                                        <li><strong>1. 24/7 Customer Engagement:</strong> Provide consistent support, regardless of business hours or time zones.</li>
                                        <li><strong>2. Cost Efficiency:</strong> Cut operational costs through automation while reallocating resources to high-value tasks.</li>
                                        <li><strong>3. Accurate and Consistent:</strong> Ensure reliable information is provided across all interactions.</li>
                                        <li><strong>4. Personalized Engagement:</strong> Deliver customized responses based on customer preferences and behavior.</li>
                                        <li><strong>5. Actionable Insights:</strong> Leverage analytics to refine your customer service strategies.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="empowerIndWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="tileWrap">
                                    <h2>Empowering Industries with Smart Conversations</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Slider {...empowerIndusSlider}>
                                    <div className="itemOuter">
                                    <div className="itemWrap clr1">
                                        <div className="icoWrap">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 64 64">
                                                <g>
                                                    <path d="M46 29a16.946 16.946 0 0 0-12 4.97V6a5.006 5.006 0 0 0-5-5H6a5.006 5.006 0 0 0-5 5v43a5.006 5.006 0 0 0 5 5h23a4.935 4.935 0 0 0 1.831-.367A16.992 16.992 0 1 0 46 29ZM29.05 47H3V8h29v28.378A16.896 16.896 0 0 0 29 46c0 .338.031.667.05 1ZM6 3h23a3.003 3.003 0 0 1 3 3H3a3.003 3.003 0 0 1 3-3Zm23 49H6a3.003 3.003 0 0 1-3-3h26.281a16.816 16.816 0 0 0 .76 2.801A2.944 2.944 0 0 1 29 52Zm17 9a15 15 0 1 1 15-15 15.017 15.017 0 0 1-15 15Z" fill="#9ceebd" opacity="1" data-original="#9ceebd"></path>
                                                    <path d="m39.888 40-.961-2.375A1 1 0 0 0 38 37h-3v2h2.326l5.26 12.998A3.005 3.005 0 0 0 45.415 54H53v-2h-7.585a1.02 1.02 0 0 1-.959-.71l-.117-.29H53a.999.999 0 0 0 .948-.684l3-9A1 1 0 0 0 56 40Zm12.391 9h-8.75l-2.832-7h13.915Z" fill="#9ceebd" opacity="1" data-original="#9ceebd"></path>
                                                    <rect width="2" height="2" x="44" y="55" rx="1" fill="#9ceebd" opacity="0.45" data-original="#9ceebd"></rect>
                                                    <rect width="2" height="2" x="51" y="55" rx="1" fill="#9ceebd" opacity="0.45" data-original="#9ceebd"></rect>
                                                    <path d="m29.896 20.224-1.092-4.678A1.99 1.99 0 0 0 26.856 14H8.443a1.991 1.991 0 0 0-1.947 1.546l-1.079 4.63a2.923 2.923 0 0 0 1.233 3.308V38a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V23.484a2.986 2.986 0 0 0 .745-.678 2.948 2.948 0 0 0 .5-2.582Zm-1.936.502A1 1 0 0 1 26.999 22h-2.466l-.75-6h3.073ZM12.783 22l.75-6h3.117v6Zm5.867-6h3.118l.75 6H18.65ZM7.353 20.678 8.443 16h3.075l-.75 6H8.302a.994.994 0 0 1-.8-.398 1.022 1.022 0 0 1-.15-.924ZM20.65 37h-6v-5h6Zm2 0v-6a1 1 0 0 0-1-1h-8a1 1 0 0 0-1 1v6h-4V24h18v13Z" fill="#9ceebd" opacity="0.45" data-original="#9ceebd"></path>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="dataWrap">
                                            <h2>Retail and E-Commerce</h2>
                                            <p><strong>Elevate Customer Experience –</strong> Our chatbot responds to product inquiries, processes returns, and offers personalized shopping advice, making it easier for customers to find what they need.</p>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="itemOuter">
                                    <div className="itemWrap clr2">
                                        <div className="icoWrap">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 500 500">
                                                <g>
                                                    <path d="M240.19 486.86H68.91a41.547 41.547 0 0 1-41.5-41.5V54.64a41.547 41.547 0 0 1 41.5-41.5h171.28a41.482 41.482 0 0 1 34.41 18.293 6.5 6.5 0 1 1-10.773 7.277 28.5 28.5 0 0 0-23.637-12.57H68.91a28.532 28.532 0 0 0-28.5 28.5v390.72a28.532 28.532 0 0 0 28.5 28.5h171.28a28.58 28.58 0 0 0 27.921-22.749 6.5 6.5 0 1 1 12.736 2.606 41.626 41.626 0 0 1-40.657 33.143z" fill="#9ccdee" opacity="1" data-original="#9ccdee"></path>
                                                    <path d="M192.97 58.69h-75.3A21.4 21.4 0 0 1 97.721 45.2L88.43 22.062a6.5 6.5 0 0 1 6.031-8.922h121.721a6.5 6.5 0 0 1 6.031 8.922L212.922 45.2a21.4 21.4 0 0 1-19.952 13.49zm-88.894-32.55 5.708 14.217a8.461 8.461 0 0 0 7.888 5.333h75.3a8.46 8.46 0 0 0 7.888-5.333l5.709-14.217zM251.02 268.63a6.5 6.5 0 0 1-6.5-6.5v-73.81a6.5 6.5 0 1 1 13 0v73.81a6.5 6.5 0 0 1-6.5 6.5zM217.83 342.44a6.5 6.5 0 0 1-6.5-6.5V188.32a6.5 6.5 0 0 1 13 0v147.62a6.5 6.5 0 0 1-6.5 6.5zM332.55 268.63a6.5 6.5 0 0 1-6.5-6.5v-73.81a6.5 6.5 0 0 1 13 0v73.81a6.5 6.5 0 0 1-6.5 6.5zM299.36 268.63a6.5 6.5 0 0 1-6.5-6.5v-73.81a6.5 6.5 0 0 1 13 0v73.81a6.5 6.5 0 0 1-6.5 6.5zM136.3 342.44a6.5 6.5 0 0 1-6.5-6.5V188.32a6.5 6.5 0 0 1 13 0v147.62a6.5 6.5 0 0 1-6.5 6.5zM169.49 342.44a6.5 6.5 0 0 1-6.5-6.5V188.32a6.5 6.5 0 0 1 13 0v147.62a6.5 6.5 0 0 1-6.5 6.5zM414.09 268.63a6.5 6.5 0 0 1-6.5-6.5v-73.81a6.5 6.5 0 0 1 13 0v73.81a6.5 6.5 0 0 1-6.5 6.5zM380.89 268.63a6.5 6.5 0 0 1-6.5-6.5v-73.81a6.5 6.5 0 0 1 13 0v73.81a6.5 6.5 0 0 1-6.5 6.5z" fill="#9ccdee" opacity="0.45" data-original="#9ccdee"></path>
                                                    <path d="M459.993 194.824H90.39a12.256 12.256 0 0 1-11.745-9.14 12.947 12.947 0 0 1 5.478-14.737l181.966-113.1a17.138 17.138 0 0 1 18.2 0l181.967 113.1a12.948 12.948 0 0 1 5.478 14.738 12.256 12.256 0 0 1-11.741 9.139zm-368.743-13h367.883l-181.7-112.94a4.117 4.117 0 0 0-4.481 0zM225.766 385.33H117.3a16.519 16.519 0 0 1-16.5-16.5v-22.89a16.519 16.519 0 0 1 16.5-16.5h108.466a6.5 6.5 0 1 1 0 13H117.3a3.5 3.5 0 0 0-3.5 3.5v22.89a3.5 3.5 0 0 0 3.5 3.5h108.466a6.5 6.5 0 1 1 0 13zM452.276 430.139H261.021a16.519 16.519 0 0 1-16.5-16.5v-117.49a16.519 16.519 0 0 1 16.5-16.5h191.255a16.519 16.519 0 0 1 16.5 16.5v117.49a16.518 16.518 0 0 1-16.5 16.5zm-191.255-137.49a3.505 3.505 0 0 0-3.5 3.5v117.49a3.505 3.505 0 0 0 3.5 3.5h191.255a3.5 3.5 0 0 0 3.5-3.5v-117.49a3.5 3.5 0 0 0-3.5-3.5z" fill="#9ccdee" opacity="1" data-original="#9ccdee"></path>
                                                    <path d="M462.276 352.394H251.021a6.5 6.5 0 0 1-6.5-6.5v-33.7a6.5 6.5 0 0 1 6.5-6.5h211.255a6.5 6.5 0 0 1 6.5 6.5v33.7a6.5 6.5 0 0 1-6.5 6.5zm-204.755-13h198.255v-20.7H257.521zM291.713 411.053a25.33 25.33 0 1 1 25.329-25.329 25.358 25.358 0 0 1-25.329 25.329zm0-37.659a12.33 12.33 0 1 0 12.329 12.33 12.344 12.344 0 0 0-12.329-12.33zM275.191 148.968a28.2 28.2 0 1 1 28.2-28.2 28.235 28.235 0 0 1-28.2 28.2zm0-43.407a15.2 15.2 0 1 0 15.2 15.2 15.22 15.22 0 0 0-15.2-15.2zM214.871 460.816H94.232a6.5 6.5 0 0 1 0-13h120.639a6.5 6.5 0 1 1 0 13zM436.16 377.287h-82.341a6.5 6.5 0 0 1 0-13h82.341a6.5 6.5 0 0 1 0 13zM436.16 402.181h-82.341a6.5 6.5 0 0 1 0-13h82.341a6.5 6.5 0 0 1 0 13z" fill="#9ccdee" opacity="0.45" data-original="#9ccdee"></path>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="dataWrap">
                                            <h2>Banking and Finance</h2>
                                            <p><strong>Secure and Efficient –</strong> Handle routine banking questions and offer personalized product recommendations, all while adhering to stringent security protocols.</p>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="itemOuter">
                                    <div className="itemWrap clr3">
                                        <div className="icoWrap">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 492 492">
                                                <g>
                                                    <path d="M375.72 482.42H116.8c-19.06 0-34.56-15.5-34.56-34.56V45.62c0-19.06 15.5-34.56 34.56-34.56h258.93c19.06 0 34.56 15.5 34.56 34.56v402.24c-.01 19.06-15.51 34.56-34.57 34.56zm-258.92-456c-10.59 0-19.2 8.61-19.2 19.2v402.24c0 10.59 8.61 19.2 19.2 19.2h258.93c10.59 0 19.2-8.61 19.2-19.2V45.62c0-10.59-8.61-19.2-19.2-19.2z" fill="#cb9cee" opacity="1" data-original="#cb9cee"></path>
                                                    <path d="M402.6 424.19H89.92c-4.24 0-7.68-3.44-7.68-7.68s3.44-7.68 7.68-7.68H402.6c4.24 0 7.68 3.44 7.68 7.68s-3.43 7.68-7.68 7.68zM305.13 75.74H187.39c-14.53 0-27.22-8.78-32.34-22.38l-12.02-31.92c-1.5-3.97.51-8.4 4.48-9.89 3.97-1.5 8.4.51 9.89 4.48l12.02 31.92c2.89 7.67 9.77 12.43 17.97 12.43h117.74c8.19 0 15.08-4.76 17.97-12.43l12.02-31.92c1.5-3.97 5.93-5.98 9.89-4.48 3.97 1.5 5.98 5.92 4.48 9.89l-12.02 31.92c-5.12 13.6-17.82 22.38-32.34 22.38z" fill="#cb9cee" opacity="0.45" data-original="#cb9cee"></path>
                                                    <path d="M267.3 51.08h-42.09c-4.24 0-7.68-3.44-7.68-7.68s3.44-7.68 7.68-7.68h42.09c4.24 0 7.68 3.44 7.68 7.68s-3.43 7.68-7.68 7.68zM198.95 51.08h-6.69c-4.24 0-7.68-3.44-7.68-7.68s3.44-7.68 7.68-7.68h6.69c4.24 0 7.68 3.44 7.68 7.68s-3.44 7.68-7.68 7.68zM246.26 364.78c-1.97 0-3.93-.75-5.43-2.25-55.36-55.36-112.61-112.61-112.61-170.97 0-34.66 28.2-62.86 62.86-62.86 27.3 0 42.81 12.82 55.18 33.1 12.37-20.28 27.88-33.1 55.18-33.1 34.66 0 62.86 28.2 62.86 62.86 0 58.36-57.25 115.61-112.61 170.97a7.647 7.647 0 0 1-5.43 2.25zm-55.18-220.72c-26.19 0-47.5 21.31-47.5 47.5 0 50.23 51.28 103.22 102.68 154.68 51.4-51.45 102.68-104.45 102.68-154.68 0-26.19-21.31-47.5-47.5-47.5-22.24 0-34.43 9.37-48.31 37.14-1.3 2.6-3.96 4.25-6.87 4.25s-5.57-1.64-6.87-4.25c-13.88-27.77-26.07-37.14-48.31-37.14z" fill="#cb9cee" opacity="1" data-original="#cb9cee"></path>
                                                    <path d="M272.88 277.29a7.69 7.69 0 0 1-7.05-4.63l-19.92-46.07h-38.15c-2.63 0-5.09-1.35-6.49-3.58l-15.43-24.43-15.43 24.43a7.68 7.68 0 0 1-6.49 3.58h-43.2c-4.24 0-7.68-3.44-7.68-7.68s3.44-7.68 7.68-7.68h38.97l19.66-31.13a7.68 7.68 0 0 1 6.49-3.58c2.64 0 5.09 1.35 6.49 3.58l19.66 31.13h38.97a7.69 7.69 0 0 1 7.05 4.63l14.87 34.4 14.87-34.4a7.69 7.69 0 0 1 7.05-4.63h77.01c4.24 0 7.68 3.44 7.68 7.68s-3.44 7.68-7.68 7.68h-71.96l-19.92 46.07a7.69 7.69 0 0 1-7.05 4.63z" fill="#cb9cee" opacity="0.45" data-original="#cb9cee" ></path>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="dataWrap">
                                            <h2>Healthcare</h2>
                                            <p><strong>Access and Assistance –</strong> From appointment scheduling to symptom assessments, our chatbot ensures patients receive the information and care they need quickly.</p>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="itemOuter">
                                    <div className="itemWrap clr4">
                                        <div className="icoWrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 682.667 682.667">
                                                <g>
                                                    <defs>
                                                        <clipPath id="a" clipPathUnits="userSpaceOnUse">
                                                            <path d="M0 512h512V0H0Z" fill="#dcdc30" opacity="1" data-original="#dcdc30"></path>
                                                        </clipPath>
                                                    </defs>
                                                    <g clip-path="url(#a)" transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
                                                        <path d="M0 0h177.615c12.042 0 21.803-9.762 21.803-21.803v-67.669" transform="translate(88.678 504.5)" fill="none" stroke="#dcdc30" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" data-original="#dcdc30"  ></path>
                                                        <path d="M0 0c-3.661-6.899-10.917-11.598-19.272-11.598h-236.99c-12.042 0-21.803 9.762-21.803 21.803v453.394c0 12.041 9.761 21.803 21.803 21.803h18.85" transform="translate(285.565 19.098)" fill="none" stroke="#dcdc30" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" data-original="#dcdc30"></path>
                                                        <path d="M0 0h-198.833v361.486H81.763v-38.25" transform="translate(206.333 91.792)" fill="none" stroke="#dcdc30" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" data-original="#dcdc30" opacity="0.45"></path>
                                                        <path d="M0 0h-44.385c-6.928 0-12.544 5.616-12.544 12.544 0 6.928 5.616 12.543 12.544 12.543H0c6.928 0 12.543-5.615 12.543-12.543C12.543 5.616 6.928 0 0 0Z" transform="translate(169.99 37.056)" fill="none" stroke="#dcdc30" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" data-original="#dcdc30"></path>
                                                        <path d="M0 0c-18.9-7.994-39.68-12.415-61.492-12.415-87.248 0-157.976 70.729-157.976 157.977 0 57.964 31.217 108.637 77.756 136.123" transform="translate(408.015 19.915)" fill="none" stroke="#dcdc30" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" data-original="#dcdc30"></path>
                                                        <path d="M0 0a159.157 159.157 0 0 0 24.713 1.921c87.248 0 157.977-70.728 157.977-157.976 0-49.6-22.858-93.861-58.615-122.823" transform="translate(321.81 321.532)" fill="none" stroke="#dcdc30" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" data-original="#dcdc30"></path>
                                                        <path d="m0 0 10.274-9.897 12.014-39.377c3.81-12.491 19.656-16.265 28.687-6.831l31.14 32.523 43.159 3.692a17.087 17.087 0 0 1 14.377 10.598l17.886 44.05c-25.303 52.426-78.716 88.73-140.676 89.376l5.957-52.609-26.447-45.869A21.033 21.033 0 0 1 0 0Z" transform="translate(331.34 199.299)" fill="none" stroke="#dcdc30" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" data-original="#dcdc30" opacity="0.45"></path>
                                                        <path d="m0 0-36.247-5.074a19.997 19.997 0 0 0-10.827 1.5l-9.863 4.34A20.001 20.001 0 0 0-68.76 21.281l4.212 37.903a20.002 20.002 0 0 1-5.189 15.782l-48.351 52.326C-144.595 22.032-61.389-77.18 45.975-69.916L21.16-11.937A20 20 0 0 1 0 0Z" transform="translate(311.647 77.81)" fill="none" stroke="#dcdc30" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" data-original="#dcdc30" opacity="0.45"></path>
                                                        <path d="m0 0-52.041 46.493 28.941 8.472 79.961-28.799" transform="translate(267.843 375.677)" fill="none" stroke="#dcdc30" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" data-original="#dcdc30"></path>
                                                        <path d="m0 0 90.818 26.625c16.223 4.75 19.846 26.147 6.051 35.917-17.082 12.097-41.93 21.097-72.515 7.938-60.426-25.996-190.922-88.051-190.922-88.051l-19.049 26.589-18.312-5.361 10.906-37.256c3.404-11.63 15.592-18.298 27.222-14.894l95.51 27.958" transform="translate(344.46 350.98)" fill="none" stroke="#dcdc30" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" data-original="#dcdc30"></path>
                                                        <path d="m0 0-22.423-80.36 28.942 8.472 73.237 95.234" transform="translate(279.727 347.168)" fill="none" stroke="#dcdc30" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" data-original="#dcdc30" ></path>
                                                        <path d="m0 0 44.575 12.922a20.355 20.355 0 0 1-5.094 5.163C22.399 30.182-2.448 39.182-33.034 26.023l-.686-.295 4.377-10.428C-25.183 3.212-12.293-3.508 0 0Z" transform="translate(401.848 395.437)" fill="none" stroke="#dcdc30" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" data-original="#dcdc30" opacity="0.45"></path>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="dataWrap">
                                            <h2>Travel and Hospitality</h2>
                                            <p><strong>Streamlined Travel –</strong> Assist travelers in booking accommodations, adjusting reservations, and receiving travel recommendations, all while reducing workload on human agents.</p>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="itemOuter">
                                    <div className="itemWrap clr5">
                                        <div className="icoWrap">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 68 68">
                                                <g>
                                                    <path d="M20.78 34.3a1 1 0 0 1-.994-.89 12.922 12.922 0 0 1 2.785-9.556 12.92 12.92 0 0 1 8.728-4.789 1.006 1.006 0 0 1 1.102.886 1 1 0 0 1-.885 1.103c-6.032.66-10.402 6.105-9.742 12.138a1 1 0 0 1-.995 1.109z" fill="#ff9393" opacity="0.45" data-original="#ff9393"></path>
                                                    <path d="M25.553 35.441a1 1 0 0 1-.993-.89c-.286-2.606.462-5.168 2.107-7.217s3.984-3.334 6.589-3.62a.995.995 0 0 1 1.103.885 1 1 0 0 1-.884 1.103c-4.29.47-7.398 4.342-6.927 8.63a1 1 0 0 1-.995 1.11z" fill="#ff9393" opacity="0.45" data-original="#ff9393"></path>
                                                    <path d="M30.213 36.47a1 1 0 0 1-.993-.892 6.653 6.653 0 0 1 5.883-7.328 1 1 0 0 1 .217 1.988 4.61 4.61 0 0 0-3.115 1.712 4.61 4.61 0 0 0-.997 3.411 1 1 0 0 1-.995 1.109zM31.63 48.942a8.128 8.128 0 0 1-4.385-1.284 2.125 2.125 0 0 1-.937-1.343 2.123 2.123 0 0 1 .286-1.61l2.614-4.1a3.303 3.303 0 0 1 4.555-1.005c.062.04.141.026.182-.027a74.708 74.708 0 0 0 3.15-4.536 74.22 74.22 0 0 0 2.919-4.975c.032-.06.008-.135-.053-.174a3.28 3.28 0 0 1-1.447-2.07 3.28 3.28 0 0 1 .44-2.485l2.62-4.106a2.144 2.144 0 0 1 2.953-.654l.001.001a8.158 8.158 0 0 1 2.683 10.946c-1.421 2.48-2.925 4.96-4.47 7.374a174.88 174.88 0 0 1-4.446 6.597 8.15 8.15 0 0 1-6.664 3.451zm.361-7.86a1.3 1.3 0 0 0-1.097.6l-2.615 4.098c-.035.056-.012.158.042.193a6.154 6.154 0 0 0 8.341-1.637 170.775 170.775 0 0 0 8.813-13.81 6.158 6.158 0 0 0-2.022-8.265.14.14 0 0 0-.193.042l-2.62 4.106c-.187.292-.248.639-.173.978s.277.628.57.814a2.142 2.142 0 0 1 .74 2.806 76.057 76.057 0 0 1-6.233 9.768 2.146 2.146 0 0 1-2.857.51 1.289 1.289 0 0 0-.696-.203z" fill="#ff9393" opacity="0.45" data-original="#ff9393"></path>
                                                    <path d="M48.849 66H19.156c-2.863 0-5.192-2.335-5.192-5.205V7.19A5.198 5.198 0 0 1 19.156 2h29.693a5.198 5.198 0 0 1 5.191 5.191v53.604c0 2.87-2.33 5.205-5.191 5.205zM19.156 4a3.195 3.195 0 0 0-3.192 3.191v53.604A3.202 3.202 0 0 0 19.156 64h29.693a3.202 3.202 0 0 0 3.191-3.205V7.19A3.195 3.195 0 0 0 48.849 4z" fill="#ff9393" opacity="1" data-original="#ff9393"></path>
                                                    <path d="M53.043 12.117H14.957a1 1 0 1 1 0-2h38.086a1 1 0 1 1 0 2zM36.193 8.162h-4.386a1 1 0 1 1 0-2h4.386a1 1 0 1 1 0 2zM34.352 62.31h-.703a1 1 0 1 1 0-2h.703a1 1 0 1 1 0 2zM53.043 58.269H14.957a1 1 0 1 1 0-2h38.086a1 1 0 1 1 0 2z" fill="#ff9393" opacity="1" data-original="#ff9393"></path>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="dataWrap">
                                            <h2>Telecommunications</h2>
                                            <p><strong>Instant Support –</strong> Resolve common technical issues, manage account settings, and facilitate upgrades with rapid response times.</p>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="itemOuter">
                                    <div className="itemWrap clr6">
                                        <div className="icoWrap">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 48 48">
                                                <g>
                                                    <path d="M43.62 35.75c-.24-.51-.62-.92-1.08-1.2.37-.47.6-1.06.6-1.71V13.46c0-1.54-1.25-2.79-2.79-2.79h-1.41V8.34l1.21-.45c.29-.11.49-.39.49-.7s-.19-.59-.49-.7L24.26.55a.803.803 0 0 0-.53 0L7.85 6.48c-.29.11-.49.39-.49.7s.19.59.49.7l4.36 1.63v1.15H7.65c-1.54 0-2.79 1.25-2.79 2.79v19.38c0 .65.23 1.23.6 1.71-.46.28-.84.69-1.08 1.2L.87 43.31c-.25.42-.38.91-.38 1.4 0 1.54 1.25 2.79 2.79 2.79H44.7c1.54 0 2.79-1.25 2.79-2.79 0-.49-.13-.97-.34-1.34zM24 2.05l13.06 4.88-13.03-.55-.06 1.5 10.71.45L24 12.32 10.26 7.18zm-.26 11.77c.08.03.17.05.26.05s.18-.02.26-.05l10.03-3.75v7.08c0 .25-.15.48-.38.58l-9.34 3.99c-.36.15-.77.15-1.12 0l-9.34-3.99a.635.635 0 0 1-.38-.58v-7.08zm-17.38-.36c0-.71.58-1.29 1.29-1.29h4.56v4.98c0 .86.51 1.63 1.29 1.96l9.34 3.99c.37.16.76.23 1.15.23s.78-.08 1.15-.23l9.34-3.99c.79-.34 1.3-1.11 1.3-1.96V9.52l1.65-.62v11.13h1.5v-7.86h1.41c.71 0 1.29.58 1.29 1.29v19.38c0 .71-.58 1.29-1.29 1.29H7.65c-.71 0-1.29-.58-1.29-1.29zM44.71 46H3.29C2.58 46 2 45.42 2 44.71c0-.23.06-.45.2-.71l3.54-7.63c.21-.45.67-.75 1.17-.75H41.09c.5 0 .96.29 1.17.75l3.57 7.69c.11.2.17.42.17.65 0 .71-.58 1.29-1.29 1.29z" fill="#7a7ae0" opacity="1" data-original="#7a7ae0"></path>
                                                    <path d="M7.94 37.46h1.5v1.5h-1.5zM11.34 37.46h1.5v1.5h-1.5zM14.74 37.46h1.5v1.5h-1.5zM18.15 37.46h1.5v1.5h-1.5zM21.55 37.46h1.5v1.5h-1.5zM24.95 37.46h1.5v1.5h-1.5zM28.35 37.46h1.5v1.5h-1.5zM31.76 37.46h1.5v1.5h-1.5zM35.16 37.46h1.5v1.5h-1.5zM38.56 37.46h1.5v1.5h-1.5zM6.74 39.87h1.61v1.5H6.74zM10.4 39.87h1.61v1.5H10.4zM14.05 39.87h1.61v1.5h-1.61zM17.71 39.87h1.61v1.5h-1.61zM21.37 39.87h1.61v1.5h-1.61zM25.02 39.87h1.61v1.5h-1.61zM28.68 39.87h1.61v1.5h-1.61zM32.34 39.87h1.61v1.5h-1.61zM35.99 39.87h1.61v1.5h-1.61zM39.65 39.87h1.61v1.5h-1.61zM5.34 42.68h1.74v1.5H5.34zM9.3 42.68h1.74v1.5H9.3zM13.25 42.68h1.74v1.5h-1.74zM17.2 42.68h1.74v1.5H17.2zM21.15 42.68h9.65v1.5h-9.65zM33.01 42.68h1.74v1.5h-1.74zM36.96 42.68h1.74v1.5h-1.74zM40.91 42.68h1.74v1.5h-1.74z" fill="#7a7ae0" opacity="0.45" data-original="#7a7ae0"></path>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="dataWrap">
                                            <h2>Education</h2>
                                            <p><strong>Effortless Learning –</strong> Simplify interactions for prospective students with course recommendations, admissions guidance, and class scheduling assistance.</p>
                                        </div>
                                    </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="staffAugItroWrap staffAugValueWrap flexRevMob">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="dataWrap dataWrapPadLt">
                                    <h2>Seamless Setup and Integration</h2>
                                    <p><strong>Effortless Implementation –</strong> At Hummingtec Pvt Ltd, we understand the importance of a hassle-free setup. Our team will handle everything from initial configuration to deployment, minimizing service disruption while maximizing the chatbot's potential.</p>
                                    <ul>
                                        <li><strong>1. Custom Configuration:</strong> Tailor the chatbot's responses to align with your business's goals.</li>
                                        <li><strong>2. Training and Support:</strong>Benefit from comprehensive training for your team and ongoing technical support.</li>
                                        <li><strong>3. 	Scalability and Flexibility:</strong> The solution grows with your business, adapting to increasing demand and new communication channels.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="imgWrap">
                                    <img src={opticalCharRecImg2} className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
                <div className="reachOutWrap">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div className="dataWrap">
                                    <h2>Ready to Transform Customer Service?</h2>
                                    <p>Contact us today to discover how our intelligent chat automation solution can transform your customer support strategy.</p>
                                    <button className="btn" onClick={() => setLgShow(true)}>Contact Us</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                className="contactUsPopup"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="titleWrap">
                        <h2>Let's get in touch</h2>
                        <p>Kindly fill out the form below, and our team will promptly respond to your inquiries.</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <ul className="formList">
                            <li>
                                <div className="inputField">
                                    <label>Name</label>
                                    <input type="text" name="name" value={formData.name} onChange={handleChange} />
                                </div>
                            </li>
                            <li>
                                <div className="inputField">
                                    <label>Email Address</label>
                                    <input type="email" name="email" value={formData.email} onChange={handleChange} />
                                </div>
                            </li>
                            <li>
                                <div className="inputField">
                                    <label>Mobile Number</label>
                                    <input type="tel" name="mobile" value={formData.mobile} onChange={handleChange} />
                                </div>
                            </li>
                            <li>
                                <div className="inputField selectField">
                                    <label>I'm interested in:</label>
                                    <select name="interest" value={formData.interest} onChange={handleChange}>
                                        <option value="">Select an option</option>
                                        <option>Artificial Intelligence</option>
                                        <option>Machine Learning</option>
                                        <option>Custom GPT</option>
                                        <option>Blockchain/Etherium</option>
                                        <option>E-Commerce/Shopping Cart</option>
                                        <option>Salesforce CRM</option>
                                        <option>Zoho CRM</option>
                                        <option>Custom CRM</option>
                                        <option>Website Development</option>
                                        <option>SEO</option>
                                        <option>Social Media Management</option>
                                        <option>Mobile Application Development</option>
                                        <option>Custom Application Development</option>
                                        <option>Security and Testing</option>
                                        <option>Other</option>
                                    </select>
                                </div>
                            </li>
                            <li className="full">
                                <div className="inputField">
                                    <label>Message</label>
                                    <textarea name="message" value={formData.message} onChange={handleChange}></textarea>
                                </div>
                            </li>
                            <li className="full">
                                <div className="ctaWrap">
                                    <button type="submit" className="btnSubmti">Submit</button>
                                </div>
                            </li>
                        </ul>
                    </form>
                </Modal.Body>
            </Modal>
            <Modal
                size="md"
                show={showThankYou}
                onHide={() => setShowThankYou(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                className="contactUsPopup"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="thankYouPopup">
                        <h3>Thank you for contacting us!</h3>
                        <p>We will respond to your inquiry shortly.</p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default IntelligentChatAutomation;