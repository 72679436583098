import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
const Disclaimer = () => {

    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="bannerWrap innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Website Disclaimer</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="commonPagesDataWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dataWrap">
                                    <p>The information provided on this website is for general informational purposes only. While we strive to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>
                                    <p>In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.</p>
                                    <p>Through this website, you are able to link to other websites that are not under the control of Hummingtec. We have no control over the nature, content, and availability of those sites. The inclusion of any links does</p>
                                    <p>This content may violate our content policy or terms of use. If you believe this to be in error, please submit your feedback — your input will aid our research in this area.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default Disclaimer;