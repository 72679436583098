import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import Footer from "./Footer";
import Header from "./Header";
import IcoImg from "./assets/img/Ico.png";
import SMBsIco1 from "./assets/img/WebsiteDevelopmentIco-3.png";
import SMBsIco2 from "./assets/img/StartupsIco-3.png";
import SMBsIco3 from "./assets/img/ZohoCRM.png";
import SMBsIco4 from "./assets/img/CustomerEngIco-7.png";
import SMBsIco5 from "./assets/img/WebsiteDevelopmentIco-4.png";
import SMBsIco6 from "./assets/img/WebsiteDevelopmentIco-7.png";
import SMBsIco7 from "./assets/img/MobileAppDevIco-2.png";
import SMBsIco8 from "./assets/img/CustomerEngIco-5.png";
import SMBsIco9 from "./assets/img/MobileAppDevIco-3.png";
import SMBsIco10 from "./assets/img/AutomationTestingIco-3.png";
import SMBsIco11 from "./assets/img/WorkflowAutoIco-5.png";
import SMBsIco12 from "./assets/img/SMBsIco-1.png";
import SMBsIco13 from "./assets/img/StartupsIco-7.png";
import SMBsIco14 from "./assets/img/CMSIco-5.png";
import SMBsIco15 from "./assets/img/StartupsIco-10.png";
const SMBs = () => {
    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="smbBanner innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>SMBs</h2>
                                    <p>Hummingtec has worked with many medium and small enterprises to help them digitize their operations. Whether it is to implement customized CRM solutions, or to build your Reports and mobile applications - Hummingtec does it all.</p>
                                    {/* <p>We are undergoing a generational change in technology. This is the “Eureka” Moment! Just like during the dot com revolution, the current AI revolution is going to catapult several medium and small business into Global Brands. Hummingtec can help you leverage technology and transform your business into a Global brand. </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="aboutSectionWrap">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-md-5">
                                <div className="dataWrap sticyDiv">
                                    <h2>Empower Your Business with Cutting-Edge Software Solutions!</h2>
                                    <p>At Hummingtec, we're passionate about fueling the growth of small and medium-sized businesses with our tailor-made software technology. Our mission is to transform your business challenges into opportunities with innovative, efficient, and scalable solutions. Here's how we make the magic happen:</p>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="mobileAppMasonary masonaryWrap">
                                    <ResponsiveMasonry columnsCountBreakPoints={{ 380: 1, 480: 2}}>
                                        <Masonry>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={SMBsIco1} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Unleash Potential with Custom Software</h2>
                                                    <p>Dive into the world of custom software that’s crafted just for you! We focus on boosting your operational efficiency and skyrocketing your productivity.</p>
                                                </div>
                                            </div>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={SMBsIco2} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Cloud Power</h2>
                                                    <p>Elevate your business to the cloud! Our cloud-based solutions offer the ultimate in affordability, scalability, and flexibility, keeping you ahead in today's fast-paced world.</p>
                                                </div>
                                            </div>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={SMBsIco3} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>SMB-friendly CRM</h2>
                                                    <p>Manage your customer relationships like never before! Our CRM systems are not just powerful; they're also affordable and grow with you.</p>
                                                </div>
                                            </div>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={SMBsIco12} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>E-Commerce Excellence</h2>
                                                    <p>Launch your online store with our robust e-commerce platforms. Expand your reach and thrive in the digital marketplace!</p>
                                                </div>
                                            </div>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={SMBsIco7} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Mobile Marvels</h2>
                                                    <p>Connect with customers on-the-go with our sleek mobile apps, designed for maximum engagement and outstanding user experiences.</p>
                                                </div>
                                            </div>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={SMBsIco4} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Cybersecurity Shield</h2>
                                                    <p>Safeguard your digital assets with our top-notch cybersecurity services. We're here to protect you from cyber threats and keep your data safe and sound.</p>
                                                </div>
                                            </div>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={SMBsIco13} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Strategic IT Consulting</h2>
                                                    <p>Let’s align your tech with your ambitions! Our consulting services ensure your technology investments drive your business goals.</p>
                                                </div>
                                            </div>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={SMBsIco8} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Data-Driven Decisions</h2>
                                                    <p>Harness the power of data with our analytics tools. Make smarter, data-informed decisions to steer your business to success.</p>
                                                </div>
                                            </div>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={SMBsIco9} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Boost Productivity</h2>
                                                    <p>Collaborate and produce like never before with our suite of productivity tools. Teamwork makes the dream work!</p>
                                                </div>
                                            </div>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={SMBsIco6} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Empowering Training & Support</h2>
                                                    <p>We don’t just provide solutions; we make sure you're a pro at using them with our comprehensive training and ongoing support.</p>
                                                </div>
                                            </div>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={SMBsIco5} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Digital Marketing & SEO</h2>
                                                    <p>Get noticed online! Our digital marketing and SEO expertise put you right where your customers are looking.</p>
                                                </div>
                                            </div>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={SMBsIco10} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Smart Automation</h2>
                                                    <p>Say goodbye to repetitive tasks! Our business process automation saves time, reduces errors, and lets you focus on growth.</p>
                                                </div>
                                            </div>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={SMBsIco11} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Seamless Integrations</h2>
                                                    <p>We’re the tech matchmakers – integrating your favorite tools to create a streamlined, efficient workflow.</p>
                                                </div>
                                            </div>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={SMBsIco14} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Managed IT Services</h2>
                                                    <p>Relax, we’ve got your IT covered! Our managed services take the hassle out of technology maintenance.</p>
                                                </div>
                                            </div>
                                            <div className="itemWrap">
                                                <div className="icoWrap">
                                                    <img src={SMBsIco15} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dataWrap">
                                                    <h2>Compliance Confidence</h2>
                                                    <p>Navigate the complex world of compliance with ease. We ensure your tech is up to date with industry standards.</p>
                                                </div>
                                            </div>
                                        </Masonry>
                                    </ResponsiveMasonry>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default SMBs;