// import { useEffect, useState, useRef } from "react";
// import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import caseStudyIco1 from './assets/img/caseStudyIco-1.png';
import caseStudyIco2 from './assets/img/caseStudyIco-2.png';
import caseStudyIco3 from './assets/img/caseStudyIco-3.png';
import caseStudyIco4 from './assets/img/caseStudyIco-4.png';
import caseStudyIco5 from './assets/img/caseStudyIco-5.png';
import caseStudyIco6 from './assets/img/caseStudyIco-6.png';
import ManufacturingImg from './assets/img/aboutImg.png';
import FashionListIco from './assets/img/Ico.png'

const Mobility = () => {
    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="mobilityBanner innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Mobility</h2>
                                    <p>Elevate Your Mobility Solutions with Hummingtec. Discover the Future of Mobility with Our Cutting-Edge Technology Services:</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <div className="manuFacturListWrap mobiListWrap">
                    <div className="container">
                        <div className="row justify-content-center">
                        <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco1} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Bespoke Mobility Software Development</h2>
                                        <p>Crafting custom software solutions that redefine mobility. From ride-sharing apps to fleet management systems, we're engineering the future of transit.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco2} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Innovative Mobile App Development</h2>
                                        <p>Transforming ideas into reality with our state-of-the-art mobile applications. Experience unparalleled connectivity on iOS and Android platforms.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco3} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Smart IoT Integration for Vehicles</h2>
                                        <p>Embrace the power of IoT for enhanced vehicle connectivity. Real-time tracking, remote diagnostics, and more at your fingertips.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco4} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>AI and Machine Learning Mastery</h2>
                                        <p>Revolutionize your mobility services with AI-driven route optimization, demand forecasting, and autonomous capabilities.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco5} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Advanced Data Analytics and Reporting</h2>
                                        <p>Unlock valuable insights from data to optimize routes, reduce operational costs, and elevate service quality.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco5} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Blockchain Solutions for Secure Transactions</h2>
                                        <p>Enhance security and transparency in your operations with our blockchain expertise. From smart contracts to shared mobility trust systems.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco1} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Tailored E-Mobility Solutions</h2>
                                        <p>Leading the charge in electric vehicle innovations with software for charging stations, battery management, and smart grid integration.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco2} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Scalable Cloud Computing Infrastructure</h2>
                                        <p>Leverage the cloud for flexible, scalable, and resilient mobility service infrastructures.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco3} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Robust Cybersecurity for Mobility Platforms</h2>
                                        <p>Protecting your digital landscape with advanced cybersecurity measures. Your safety, our priority.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco4} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Intuitive UX/UI Design for Mobility Apps</h2>
                                        <p>Engage and delight users with our beautifully designed mobility interfaces. Seamless experiences, happy users.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco5} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Precision Geospatial and Mapping Solutions</h2>
                                        <p>Navigate the world with our sophisticated mapping and geospatial services. Precision at every turn.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco5} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Regulatory Compliance Expertise</h2>
                                        <p>Navigating the maze of transportation regulations made easy with our expert consultancy.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco1} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Sustainable Mobility Consulting</h2>
                                        <p>Pioneering eco-friendly transportation solutions. Your partner in sustainable mobility.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco2} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Autonomous Vehicle Technological Integration</h2>
                                        <p>Be at the forefront of self-driving technology with our innovative autonomous vehicle solutions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco3} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Efficient Fleet Management and Telematics</h2>
                                        <p>Optimize your fleet operations with our comprehensive management tools. Efficiency in motion.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContBefore">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco4} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Seamless Payment Gateway Integration</h2>
                                        <p>Facilitate easy and secure payments within your mobility services. Convenience meets innovation.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContBefore noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco5} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Augmented Reality for Enhanced Navigation</h2>
                                        <p>Bringing navigation to life with AR technology. Interactive, real-time travel info like never before.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>        
                <div className="CommonBtmWrap">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="dataWrap">
                                    <h2>Join Us on the Journey to Smarter Mobility with Hummingtec! </h2>
                                    <p>At Hummingtec, we’re not just developing technology; we’re shaping the future of mobility. Let’s drive innovation and create solutions that move the world forward.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>   
                {/* <div className="CommonBtmWrap">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 botm-p">   
                            <h2>Discover how our technology can transform your mobility services</h2>                             
                                <p>Book a free consultation to know more about how we can help you.</p>                                   
                               
                            </div>                            
                        </div>
                    </div>
                </div>         */}
            </div>
            <Footer></Footer>
        </>
    );
}
export default Mobility;