import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
const PrivacyPolicy = () => {

    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="bannerWrap innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Privacy Policy</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="commonPagesDataWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dataWrap">
                                    <p>This Privacy Policy governs the manner in which Hummingtec collects, uses, maintains, and discloses information collected from users (each, a "User") of the Hummingtec website ("Site"). This privacy policy applies to the Site and all products and services offered by Hummingtec.</p>
                                    <p>Personal Identification Information</p>
                                    <p>This content may violate our content policy or terms of use. If you believe this to be in error, please submit your feedback — your input will aid our research in this area.</p>
                                    <h3>Cookies Policy</h3>
                                    <p>This Cookies Policy explains how Hummingtec ("we", "us", or "our") uses cookies and similar technologies on our website. By accessing or using our website, you consent to the use of cookies as described in this policy.</p>
                                    <h4>What are Cookies?</h4>
                                    <p>Cookies are small pieces of data stored on your device (computer or mobile device) when you visit a website. They are widely used to make websites work more efficiently and to provide information to website owners and third parties.</p>
                                    <h4>How We Use Cookies</h4>
                                    <ul>
                                        <li>We use cookies for a variety of purposes, including to enhance your browsing experience, analyze website traffic, and personalize content.</li>
                                        <li>We may use both session cookies (which expire when you close your web browser) and persistent cookies (which remain on your device for a set period of time or until you delete them).</li>
                                    </ul>
                                    <h4>Types of Cookies We Use</h4>
                                    <ul>
                                        <li><strong>Essential Cookies:</strong> These cookies are necessary for the website to function properly. They enable core functionalities such as security, network management, and accessibility.</li>
                                        <li><strong>Analytical/Performance Cookies:</strong> These cookies allow us to analyze how visitors use our website, including which pages they visit and how long they spend on each page. This information helps us improve our website and better understand user preferences.</li>
                                        <li><strong>Functionality Cookies:</strong> These cookies enable enhanced functionality and personalization, such as remembering your preferences and settings.</li>
                                        <li><strong>Third-Party Cookies:</strong> We may also use third-party cookies provided by trusted partners for advertising, social media, and analytics purposes.</li>
                                    </ul>
                                    <h4>Your Cookie Choices</h4>
                                    <ul>
                                        <li>Most web browsers allow you to control cookies through their settings preferences. You can usually configure your browser to refuse cookies or to alert you when cookies are being sent. However, please note that disabling cookies may affect your ability to access certain features and functionality of our website.</li>
                                        <li>By continuing to use our website without changing your browser settings, you consent to our use of cookies as described in this policy.</li>
                                    </ul>
                                    <h3>Updates to this Policy</h3>
                                    <p>We may update this Cookies Policy from time to time to reflect changes in our practices or legal requirements. We encourage you to review this policy periodically for the latest information on our use of cookies.</p>
                                    <h3>Contact Us</h3>
                                    <p>If you have any questions or concerns about our Cookies Policy, please contact us at [email address].</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default PrivacyPolicy;