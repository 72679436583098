import { useEffect, useState, useRef } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { jobDescriptions } from "./JobList";
import Modal from 'react-bootstrap/Modal';
import emailjs from 'emailjs-com';
const CareersDetails = () => {
    const [thankYouPopup, setThankYouPopup] = useState(false);
    const [lgShow, setLgShow] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        interest: '',
        message: ''
    });

    const { title } = useParams();
    const job = jobDescriptions.find((job) => job.title === title);
    if (!job) {
        return <div>Job not found</div>;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
      
        try {
            const templateParams = {
                ...formData,
            };
            await emailjs.send('service_fo6avff', 'template_bvon8go', templateParams, 'G815HLz7iB0QllWGn');
            setThankYouPopup(true);
            setFormData({
                name: '',
                email: '',
                mobile: '',
                interest: '',
                message: ''
            });
            setLgShow(false);
            setShowThankYou(true);
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('An error occurred while submitting the form. Please try again later.');
        }
    };

    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="bannerWrap innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Careers</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="jobDetails">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <h2>{job.title}</h2>
                                <p>{job.description}</p>
                                <h3>Requirements:</h3>
                                <ul>
                                    {job.requirements.map((requirement, index) => (
                                        <li key={index}>{requirement}</li>
                                    ))}
                                </ul>
                                <h3>Benefits:</h3>
                                <ul>
                                    {job.benefits.map((benefit, index) => (
                                        <li key={index}>{benefit}</li>
                                    ))}
                                </ul>
                               <div className="ctaWrap">
                                    <Link to="/careers">Back to Careers</Link>
                                    <Link to="/Contact">Apply Now</Link>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="reachOutWrap">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <div className="dataWrap">
                                <h2>Looking for a Technology Partner with proven track record to bring your <i>ideas</i> to life?</h2>
                                <button className="btn" onClick={() => setLgShow(true)}>Contact Us</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                className="contactUsPopup"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="titleWrap">
                        <h2>Let's get in touch</h2>
                        <p>Kindly fill out the form below, and our team will promptly respond to your inquiries.</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <ul className="formList">
                            <li>
                                <div className="inputField">
                                    <label>Name</label>
                                    <input type="text" name="name" value={formData.name} onChange={handleChange} />
                                </div>
                            </li>
                            <li>
                                <div className="inputField">
                                    <label>Email Address</label>
                                    <input type="email" name="email" value={formData.email} onChange={handleChange} />
                                </div>
                            </li>
                            <li>
                                <div className="inputField">
                                    <label>Mobile Number</label>
                                    <input type="tel" name="mobile" value={formData.mobile} onChange={handleChange} />
                                </div>
                            </li>
                            <li>
                                <div className="inputField selectField">
                                    <label>I'm interested in:</label>
                                    <select name="interest" value={formData.interest} onChange={handleChange}>
                                        <option value="">Select an option</option>
                                        <option>Artificial Intelligence</option>
                                        <option>Machine Learning</option>
                                        <option>Custom GPT</option>
                                        <option>Blockchain/Etherium</option>
                                        <option>E-Commerce/Shopping Cart</option>
                                        <option>Salesforce CRM</option>
                                        <option>Zoho CRM</option>
                                        <option>Custom CRM</option>
                                        <option>Website Development</option>
                                        <option>SEO</option>
                                        <option>Social Media Management</option>
                                        <option>Mobile Application Development</option>
                                        <option>Custom Application Development</option>
                                        <option>Security and Testing</option>
                                        <option>Other</option>
                                    </select>
                                </div>
                            </li>
                            <li className="full">
                                <div className="inputField">
                                    <label>Message</label>
                                    <textarea name="message" value={formData.message} onChange={handleChange}></textarea>
                                </div>
                            </li>
                            <li className="full">
                                <div className="ctaWrap">
                                    <button type="submit" className="btnSubmti">Submit</button>
                                </div>
                            </li>
                        </ul>
                    </form>
                </Modal.Body>
            </Modal>
            <Modal
                size="md"
                show={showThankYou}
                onHide={() => setShowThankYou(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                className="contactUsPopup"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="thankYouPopup">
                        <h3>Thank you for contacting us!</h3>
                        <p>We will respond to your inquiry shortly.</p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default CareersDetails;