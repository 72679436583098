import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import aboutImg from "./assets/img/aboutImg.png";
import serviceImg1 from "./assets/img/serviceImg-1.png";
import serviceImg2 from "./assets/img/serviceImg-2.png";
import serviceImg3 from "./assets/img/serviceImg-3.png";
import serviceImg4 from "./assets/img/serviceImg-4.png";
import ProjectVid from "./assets/video/Project.mp4";
const MetaHOS = () => {
    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="bannerWrap innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Products</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ProjectSecWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="videoWrap">
                                    <div className="video">
                                        <video autoPlay loop muted playsInline>
                                            <source src={ProjectVid} type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="dataWrap">
                                    <h2>Meta hOS: A Comprehensive Healthcare Management System</h2>
                                    <p>Meta hOS is a sophisticated platform that integrates these key features to provide a holistic solution for healthcare management. It emphasizes improving patient care, operational efficiency, and financial management in healthcare organizations.</p>
                                    <h4>A Comprehensive Healthcare Management System</h4>
                                    <ul className="padLess">
                                        <li>
                                            <div className="dataWrap">
                                                <h2>Telehealth: Redefining Patient Care</h2>
                                                <ul>
                                                    <li><strong>Remote Consultations:</strong> Offers a platform for patients to connect with healthcare providers from any location, ensuring continuous care and accessibility.</li>
                                                    <li><strong>Virtual Care Solutions:</strong> Integrates various telemedicine tools to provide holistic care, including follow-ups, diagnostics, and remote monitoring.</li>
                                                    <li><strong>Benefits:</strong> Enhances patient convenience, reduces the need for physical visits, and expands the reach of healthcare services to remote areas.</li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataWrap">
                                                <h2>Population Health Management: Towards a Healthier Community</h2>
                                                <ul>
                                                    <li><strong>Data Analysis:</strong> Utilizes patient data to identify health trends, risk factors, and preventive care opportunities within specific populations.</li>
                                                    <li><strong>Strategy Implementation:</strong> Develops targeted healthcare strategies to address community health needs, focusing on preventive and personalized care.</li>
                                                    <li><strong>Outcome Optimization:</strong> Aims at improving overall health outcomes by managing chronic diseases, reducing hospital readmissions, and enhancing patient engagement.</li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataWrap">
                                            <h2>Billing and Finance: Financial Efficiency in Healthcare</h2>
                                                <ul>
                                                    <li><strong>Streamlined Billing Process:</strong> Simplifies the billing cycle with automated processes, reducing administrative workload and errors.</li>
                                                    <li><strong>Contactless Payments:</strong> Facilitates easy and secure payment methods, enhancing patient convenience.</li>
                                                    <li><strong>Financial Reporting:</strong> Provides comprehensive financial reports, aiding in better financial decision-making and transparency.</li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataWrap">
                                            <h2>Enhanced Communication and Operations</h2>
                                                <ul>
                                                    <li><strong>24x7 Call Center + Intercomm:</strong>  Offers an integrated communication system for efficient patient-provider interaction.</li>
                                                    <li><strong>Patient Relationship Management:</strong> Manages patient journeys, improves communication, and enables effective follow-ups.</li>
                                                    <li><strong>Digital OPD & IPD EMR (HIS):</strong>  Facilitates digital patient registration, case management, and integrates laboratory and pharmacy records for seamless operations.</li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="howWeWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="dataWrap">
                                    <h3>We also make sure that:</h3>
                                    <ul>
                                        <li>We assign the proper specialist for the delivery of the solution as per the client’s request.</li>
                                        <li>Provide Free Project Estimations.</li>
                                        <li>Equip and integrate the latest technologies, tools and trends.</li>
                                        <li>Create a stable team of experts with proper work and cultural code.</li>
                                        <li>Work Flexible Hours to manage clients query from all over the world.</li>
                                        <li>Assured Quality Controls & Checks.</li>
                                        <li>We believe in providing solutions to your business and not just codes.</li>
                                        <li>The extra security of your data and guarantee IP rights.</li>
                                        <li>From backend to front-end, we develop, promote, market and take care of your business.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 padLt">
                                <div className="topService">
                                    <div className="topLeft">
                                        <span>Popular</span>
                                        <h4>Design for how people think</h4>
                                        <p>Aliquam ut euismod condimentum elementum ultricies volutpat sit non. </p>
                                        <a href="#">Take Lesson</a>
                                    </div>
                                    <div className="topRight">
                                        <img src={serviceImg1} className="img-fluid" alt="" />
                                        <div className="posImg">
                                            <img src={serviceImg2} className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="bottomService">
                                    <div className="leftImg">
                                        <img src={serviceImg3} className="img-fluid" alt="" />
                                    </div>
                                    <div className="rightImg">
                                        <img src={serviceImg4} className="img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default MetaHOS;