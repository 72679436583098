import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import caseStudyIco1 from './assets/img/caseStudyIco-1.png';
import caseStudyIco2 from './assets/img/caseStudyIco-2.png';
import caseStudyIco3 from './assets/img/caseStudyIco-3.png';
import caseStudyIco4 from './assets/img/caseStudyIco-4.png';
import caseStudyIco5 from './assets/img/caseStudyIco-5.png';
import caseStudyIco6 from './assets/img/caseStudyIco-6.png';
import caseStudyIco7 from './assets/img/caseStudyIco-6.png';
const EnterprisesCustomers = () => {
    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="enterpCustWrap innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Enterprise Customers</h2>
                                    <p>At Hummingtec Solutions, we specialize in empowering enterprise customers with comprehensive digital and technological support designed to streamline operations, enhance efficiency, and drive innovation.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="innerPageIntroData">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dataWrap">
                                    <p>At Hummingtec Solutions, we specialize in empowering enterprise customers with comprehensive digital and technological support designed to streamline operations, enhance efficiency, and drive innovation. Our suite of services includes advanced cloud solutions, data analytics, cybersecurity measures, and custom software development tailored to meet the unique needs of large-scale organizations. We leverage the latest in AI, machine learning, and IoT technologies to provide predictive insights and automated solutions that transform business processes. By partnering with Hummingtec, enterprises gain a strategic advantage, benefiting from our expertise in navigating complex digital transformations and our commitment to delivering scalable, secure, and innovative solutions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="manuFacturListWrap">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco1} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>AI & Machine Learning Marvels</h2>
                                        <ul>
                                            <li>Unleash the power of Custom AI Models to revolutionize your decision-making and efficiency.</li>
                                            <li>Predict the future with our Predictive Analytics, turning data into your competitive edge.</li>
                                            <li>Embrace innovation effortlessly with Machine Learning as a Service (MLaaS) – it's AI, simplified!</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco2} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Reporting & Analytics Revolution</h2>
                                        <ul>
                                            <li>Illuminate your data's story with our Business Intelligence Tools, turning insights into action.</li>
                                            <li>Master your data universe with Data Warehousing, where organization meets innovation.</li>
                                            <li>Command your metrics with Custom Analytics Dashboards – every KPI, one snapshot away.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco3} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>ERP Solutions, Tailored for Titans</h2>
                                        <ul>
                                            <li>Integrate power and flexibility with ERP System Integration – your operations, supercharged.</li>
                                            <li>Expand your ERP’s horizons with Custom Module Development – because one size doesn't fit all.</li>
                                            <li>Conquer complexity with ERP Training and Support – we're with you, every step of the way.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco4} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>CRM Customization & Care</h2>
                                        <ul>
                                            <li>Transform customer relations with Tailored CRM Solutions – where every interaction counts.</li>
                                            <li>Seamlessly integrate heart and tech with CRM Data Migration and Integration.</li>
                                            <li>Experience unwavering support and evolution with our Ongoing CRM Support – we grow with you.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco5} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>NLP - Your Digital Conversationalist</h2>
                                        <ul>
                                            <li>Engage and understand like never before with Chatbots and Virtual Assistants.</li>
                                            <li>Feel the pulse of your audience with Sentiment Analysis – every opinion, a new opportunity.</li>
                                            <li>Unravel textual mysteries with Text Analytics and Processing – every word, a wealth of insight.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco6} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Image Processing – Picture Perfect Solutions</h2>
                                        <ul>
                                            <li>Decode visual data effortlessly with Automated Image Analysis – every pixel, a story.</li>
                                            <li>See beyond the surface with Computer Vision Applications – where vision meets precision.</li>
                                            <li>Elevate your imagery with Image Enhancement and Processing – perfection in every frame.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco7} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Test Automation – Assuring Quality at Speed</h2>
                                        <ul>
                                            <li>Propel efficiency with Automated Testing Frameworks – because quality can't wait.</li>
                                            <li>Integrate flawlessness with Continuous Testing Integration – every build, a masterpiece.</li>
                                            <li>Craft perfection with Test Script Development – every scenario, flawlessly scripted.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco1} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Code Refactoring – Crafting Excellence in Code</h2>
                                        <ul>
                                            <li>Rejuvenate legacy systems with Legacy Code Modernization – old code, new vigor.</li>
                                            <li>Elevate your codebase with Code Quality Improvement – because excellence is a standard.</li>
                                            <li>Slice through complexities with Technical Debt Reduction – making every line count.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContAfter">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco2} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Security & Compliance – Your Digital Shield</h2>
                                        <ul>
                                            <li>Fortify your defenses with Cybersecurity Assessments – be prepared, stay protected.</li>
                                            <li>Navigate the maze of standards with Compliance Management – compliance, uncomplicated.</li>
                                            <li>Guard your data’s sanctity with Data Privacy and Protection – your trust, our command.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContBefore">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco3} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Cloud Infrastructure Mastery – Sky’s the Limit</h2>
                                        <ul>
                                            <li>Chart your cloud journey with Cloud Strategy and Consulting – envision, execute, excel.</li>
                                            <li>Transition seamlessly with Cloud Migration and Integration – your business, elevated.</li>
                                            <li>Experience uninterrupted excellence with Ongoing Cloud Management – we’ve got your cloud.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContBefore">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco4} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Data Mining & Transformation – Unearth Hidden Gems</h2>
                                        <ul>
                                            <li>Discover data's true potential with Advanced Data Mining Techniques – insights await discovery.</li>
                                            <li>Transform raw data into gold with Data Transformation – every bit, maximized.</li>
                                            <li>Decisions empowered with Enhanced Data-driven Decision Making – navigate with confidence.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pad0">
                                <div className="itemWrap noContAfter noContBefore">
                                    <div className="icoWrap">
                                        <img src={caseStudyIco5} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dataWrap">
                                        <h2>Business Automation – Efficiency, Redefined</h2>
                                        <ul>
                                            <li>Streamline for supremacy with Workflow Automation – where every process, perfected.</li>
                                            <li>Command your business narrative with Automated Reporting Systems – stories told in numbers.</li>
                                            <li>Optimize operations with Process Optimization – every move, a masterstroke.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default EnterprisesCustomers;