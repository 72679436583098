import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import DedicatedTeamsImg1 from './assets/img/DedicatedTeamsImg-1.png';
import DedicatedTeamsImg2 from './assets/img/DedicatedTeamsImg-2.png';
import DedicatedTeamsImg3 from './assets/img/DedicatedTeamsImg-3.png';
const StaffAugmentation = () => {
    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="bannerWrap innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Staff Augmentation</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="staffAugItroWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="imgWrap">
                                    <img src={DedicatedTeamsImg1} className="img-fluid" alt=""/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="dataWrap dataWrapPadRt">
                                    <h2>Unlock Your Project's Potential with Expert Staff Augmentation</h2>
                                    <p>In the fast-evolving landscape of software development, agility and adaptability are more than just buzzwords—they're the backbone of successful projects. Staff Augmentation emerges as a pivotal strategy for businesses seeking to scale their capabilities, adapt to market demands, and push the boundaries of innovation without the overhead of traditional hiring processes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="staffAugItroWrap flexRevMob">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="dataWrap dataWrapPadLt">
                                    <h2>Why Staff Augmentation is a Game-Changer in Software Development</h2>
                                    <p>The digital era demands speed, efficiency, and precision. Staff augmentation aligns perfectly with these needs by offering a flexible approach to scaling your team. Whether you're grappling with tight deadlines, specific skill set requirements, or budget constraints, incorporating augmented staff into your workforce can turn these challenges into opportunities.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="imgWrap">
                                    <img src={DedicatedTeamsImg2} className="img-fluid" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="staffAugBenefitsWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="ttlWrap">
                                    <h2>Benefits of Choosing Staff Augmentation</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <ul>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="imgWrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 64 64">
                                                    <g>
                                                        <path d="M32 64a23.58 23.58 0 0 0 9.24-1.87 1 1 0 1 0-.76-1.78 22 22 0 0 1-17 0 1 1 0 0 0-.76 1.78A23.58 23.58 0 0 0 32 64ZM9.38 37.45h.16a1 1 0 0 0 1-.81 21.62 21.62 0 0 1 6.09-11.8 1 1 0 1 0-1.37-1.37 23.57 23.57 0 0 0-6.67 12.87 1 1 0 0 0 .79 1.11ZM52.08 31.78a21.76 21.76 0 0 1 1.42 4.87 1 1 0 0 0 1 .81h.16a1 1 0 0 0 .79-1.11 23.57 23.57 0 0 0-6.63-12.86 1 1 0 1 0-1.37 1.37 21.4 21.4 0 0 1 4.63 6.92ZM48.3 46.46a5.72 5.72 0 1 0 5.7-5.71 5.73 5.73 0 0 0-5.7 5.71Zm9.49 0A3.78 3.78 0 1 1 54 42.69a3.77 3.77 0 0 1 3.79 3.77Z" fill="#9ceebd" opacity="1" data-original="#9ceebd"></path>
                                                        <path d="M54 52.49a10 10 0 0 0-10 10V63a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-.56a10 10 0 0 0-10-9.95Zm-8 9.57a8 8 0 0 1 16.06 0ZM32 11.43a5.72 5.72 0 1 0-5.71-5.71A5.72 5.72 0 0 0 32 11.43Zm0-9.49a3.78 3.78 0 1 1-3.78 3.78A3.78 3.78 0 0 1 32 1.94Z" fill="#9ceebd" opacity="1" data-original="#9ceebd"></path>
                                                        <path d="M23 23.25h18a1 1 0 0 0 1-1v-.56a10 10 0 1 0-20 0v.56a1 1 0 0 0 1 1Zm9-9.57a8 8 0 0 1 8 7.63H24a8 8 0 0 1 8-7.63ZM10 52.18a5.72 5.72 0 1 0-5.71-5.72A5.73 5.73 0 0 0 10 52.18Zm0-9.49a3.78 3.78 0 1 1-3.77 3.77A3.78 3.78 0 0 1 10 42.69Z" fill="#9ceebd" opacity="1" data-original="#9ceebd"></path>
                                                        <path d="M1 64h18a1 1 0 0 0 1-1v-.56a10 10 0 0 0-20 0V63a1 1 0 0 0 1 1Zm9-9.57a8 8 0 0 1 8 7.63H2a8.06 8.06 0 0 1 8-7.63ZM17 39.66l2.24 1a11.53 11.53 0 0 0 0 2l-2.26.91a1 1 0 0 0-.53.52 1 1 0 0 0 0 .75l2.27 5.62a1 1 0 0 0 1.26.54l2.19-1a15 15 0 0 0 1.37 1.39l-.95 2.24a1 1 0 0 0 .52 1.27l5.58 2.37a1 1 0 0 0 1.31-.53l.95-2.23a12.69 12.69 0 0 0 1.95 0l.92 2.26a1 1 0 0 0 .52.53 1 1 0 0 0 .38.07.92.92 0 0 0 .36-.07l5.58-2.3a1 1 0 0 0 .54-1.26l-.91-2.25a12.93 12.93 0 0 0 1.39-1.38l2.24 1a1 1 0 0 0 .74 0 1 1 0 0 0 .53-.52L47.56 45a1 1 0 0 0-.51-1.28l-2.24-.94a11.66 11.66 0 0 0 0-2l2.26-.91a1 1 0 0 0 .53-.52 1 1 0 0 0 0-.74L45.34 33a1 1 0 0 0-1.26-.53l-2.25.91A13.82 13.82 0 0 0 40.46 32l.95-2.24a1 1 0 0 0-.52-1.27l-5.58-2.37a1 1 0 0 0-.74 0 1 1 0 0 0-.53.52l-.95 2.23a12.69 12.69 0 0 0-2 0l-.92-2.26a.93.93 0 0 0-.52-.53 1 1 0 0 0-.74 0l-5.62 2.28a1 1 0 0 0-.54 1.26l.91 2.25a12.83 12.83 0 0 0-1.39 1.37l-2.24-1a1 1 0 0 0-1.27.51l-2.37 5.59a1 1 0 0 0 .61 1.32Zm3.27-5.21 2 .86a1 1 0 0 0 1.14-.3A11.31 11.31 0 0 1 25.46 33a1 1 0 0 0 .32-1.14l-.82-2 3.83-1.55.82 2a1 1 0 0 0 1 .6 11 11 0 0 1 2.92 0 1 1 0 0 0 1-.58l.85-2 3.8 1.61-.85 2a1 1 0 0 0 .3 1.14 11.31 11.31 0 0 1 2.06 2.09 1 1 0 0 0 1.14.32l2-.82 1.54 3.83-2 .82a1 1 0 0 0-.6 1 10.53 10.53 0 0 1 0 2.86v.09a1 1 0 0 0 .59 1l2 .85-1.61 3.8-2-.86a1 1 0 0 0-1.14.3 11 11 0 0 1-2.09 2.06 1 1 0 0 0-.32 1.14l.82 2-3.83 1.55-.82-2a1 1 0 0 0-.9-.61h-.12a11 11 0 0 1-2.92 0 1 1 0 0 0-1 .58l-.85 2-3.8-1.61.85-2a1 1 0 0 0-.3-1.15 11 11 0 0 1-2.06-2.08.94.94 0 0 0-1.13-.32l-2 .81-1.54-3.82 2-.82a1 1 0 0 0 .6-1 10.38 10.38 0 0 1 0-2.84.43.43 0 0 1 0-.11 1 1 0 0 0-.59-1l-2-.86Z" fill="#9ceebd" opacity="1" data-original="#9ceebd"></path>
                                                        <path d="M32 49.86a8.17 8.17 0 1 0-8.17-8.16A8.18 8.18 0 0 0 32 49.86Zm0-14.39a6.23 6.23 0 1 1-6.23 6.23A6.24 6.24 0 0 1 32 35.47Z" fill="#9ceebd" opacity="0.45" data-original="#9ceebd"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Flexibility and Scalability</h2>
                                                <p>Quickly adapt your team size based on project needs without the long-term commitments of hiring full-time staff.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="imgWrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 512 512">
                                                    <g>
                                                        <path d="M421.713 263.57V244.5c0-33.137-26.863-60-60-60h-40c-33.137 0-60 26.863-60 60v19.07" fill="none" stroke="#9ccdee" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" data-original="#9ccdee"></path>
                                                        <path d="M301.713 263.57V244.5c0-11.046 8.954-20 20-20h40c11.046 0 20 8.954 20 20v19.07M181.713 314.077v151.791c0 21.336 17.296 38.632 38.632 38.632h242.736c21.336 0 38.632-17.296 38.632-38.632V314.077M388.506 386.834h-93.577" fill="none" stroke="#9ccdee" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" data-original="#9ccdee"></path>
                                                        <path d="M242.963 386.687c-34.208-2.275-61.25-30.743-61.25-65.527v-18.028c0-21.336 17.296-38.632 38.632-38.632h242.736c21.336 0 38.632 17.296 38.632 38.632v18.028c0 34.632-26.807 63.004-60.803 65.496" fill="none" stroke="#9ccdee" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" data-original="#9ccdee"></path>
                                                        <path d="M243.777 502.5v-133c0-13.807 11.193-25 25-25h0c13.807 0 25 11.193 25 25v133M389.649 455.884V502.5M439.649 502.5v-133c0-13.807-11.193-25-25-25h0c-13.807 0-25 11.193-25 25v51.235M279.269 296.729v11.999M404.158 296.729v11.999M325.25 308.371c4.003 4.593 9.892 7.5 16.463 7.5 6.571 0 12.46-2.907 16.463-7.5M83.23 55.695C118.902 25.622 164.978 7.5 215.287 7.5c113.218 0 205 91.782 205 205 0 4.286-.132 8.541-.391 12.762M180.319 414.529C83.758 397.934 10.287 313.797 10.287 212.5c0-50.433 18.211-96.612 48.417-132.32" fill="none" stroke="#9ccdee" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" data-original="#9ccdee" opacity="0.45"></path>
                                                        <path d="m386.431 188.935-8.489-16.021a64.72 64.72 0 0 0-95.608-21.779l-18.121 13.368c-9.564 7.055-22.875 6.81-31.805-1.032-9.789-8.597-11.347-23.217-3.661-33.672l12.28-16.705a71.313 71.313 0 0 0 5.713-75.326h0a71.045 71.045 0 0 0-10.452-14.904A71.507 71.507 0 0 0 217.933 8.4M38.64 312.726c13.061 1.073 25.711-5.783 31.821-17.706l28.285-55.196a29.473 29.473 0 0 1 11.113-11.864v0a29.487 29.487 0 0 1 15.754-4.176h0c15.005.313 27.371 11.839 28.758 26.758.959 10.313 6.747 19.658 15.997 24.317 6.184 3.115 12.187 4.25 17.854 4.046M289.764 23.49l13.071 34.297c19.719 51.74 69.852 48.839 85.562 48.844M180.684 351.222a54.999 54.999 0 0 1-14.702 2.805 55.028 55.028 0 0 1-27.54-5.716c-21.271-10.635-46.473-5.08-61.471 12.13" fill="none" stroke="#9ccdee" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" data-original="#9ccdee" opacity="0.45"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Access to Specialized Skills</h2>
                                                <p>Fill the gaps in your team's expertise by bringing in specialists with the exact skill set required for your project.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="imgWrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 66 66">
                                                    <g>
                                                        <path d="M45.93 6.96h-8.5c-.48 0-.88.39-.88.88s.39.88.88.88h8.5c.48 0 .88-.39.88-.88s-.4-.88-.88-.88zM34.07 6.82c-.56 0-1.01.45-1.01 1.01s.45 1.01 1.01 1.01 1.01-.45 1.01-1.01-.45-1.01-1.01-1.01zM45.93 10.89h-8.5c-.48 0-.88.39-.88.88s.39.88.88.88h8.5c.48 0 .88-.39.88-.88s-.4-.88-.88-.88zM34.07 10.75c-.56 0-1.01.45-1.01 1.01s.45 1.01 1.01 1.01 1.01-.45 1.01-1.01-.45-1.01-1.01-1.01zM45.93 14.82h-8.5c-.48 0-.88.39-.88.88s.39.88.88.88h8.5c.48 0 .88-.39.88-.88s-.4-.88-.88-.88zM34.07 14.69c-.56 0-1.01.45-1.01 1.01s.45 1.01 1.01 1.01 1.01-.45 1.01-1.01-.45-1.01-1.01-1.01zM27.71 49.82h-3.98c-.48 0-.88.39-.88.88v8.39h-2.21V46.26c0-.48-.39-.88-.88-.88h-3.98c-.48 0-.88.39-.88.88v12.82h-2.21V40.86c0-.48-.39-.88-.88-.88H7.86c-.48 0-.88.39-.88.88v18.23h-.44c-.48 0-.88.39-.88.88s.39.88.88.88h23.02c.48 0 .88-.39.88-.88s-.39-.88-.88-.88h-.97V50.7c0-.49-.39-.88-.88-.88zm-3.1 1.75h2.23v7.52h-2.23zm-7.94-4.43h2.23v11.95h-2.23zm-7.94-5.41h2.23v17.35H8.73zM47.43 45.12c4.37-4.37 4.37-11.49 0-15.86-2.12-2.12-4.93-3.28-7.93-3.28s-5.81 1.17-7.93 3.29-3.28 4.93-3.28 7.93c0 2.99 1.17 5.81 3.29 7.93s4.94 3.28 7.93 3.28 5.8-1.18 7.92-3.29zm-17.4-7.93c0-2.53.98-4.9 2.77-6.69s4.17-2.77 6.69-2.77c2.53 0 4.9.98 6.69 2.77 3.69 3.69 3.69 9.7 0 13.39a9.403 9.403 0 0 1-6.69 2.77c-2.53 0-4.91-.98-6.69-2.77a9.443 9.443 0 0 1-2.77-6.7z" fill="#cb9cee" opacity="0.45" data-original="#cb9cee"></path>
                                                        <path d="m63.23 55.37-7.93-7.93a2.807 2.807 0 0 0-.41-3.44l-1.21-1.21c.7-1.76 1.07-3.66 1.07-5.61 0-4.08-1.59-7.91-4.47-10.79-5.95-5.95-15.63-5.95-21.58 0a15.158 15.158 0 0 0-4.47 10.79c0 4.07 1.59 7.91 4.47 10.79 2.97 2.97 6.88 4.46 10.79 4.46 1.9 0 3.81-.36 5.6-1.07l1.22 1.22a2.799 2.799 0 0 0 3.44.41l7.93 7.93c.74.74 1.73 1.15 2.77 1.15s2.03-.41 2.77-1.15 1.15-1.73 1.15-2.77-.4-2.03-1.14-2.78zm-33.28-8.63c-2.55-2.55-3.96-5.94-3.96-9.55s1.4-7 3.96-9.55c2.55-2.55 5.94-3.96 9.55-3.96s7 1.41 9.55 3.96S53 33.58 53 37.19c0 1.91-.4 3.75-1.15 5.44-.01.02-.02.03-.02.05-.66 1.49-1.59 2.87-2.78 4.05A13.37 13.37 0 0 1 45 49.51c-.02.01-.04.02-.05.03-1.69.75-3.54 1.15-5.44 1.15-3.62 0-7.01-1.4-9.56-3.95zm17.6 4.61-.77-.77a14.78 14.78 0 0 0 3.5-2.61c1.05-1.05 1.92-2.23 2.61-3.49l.76.76a1.06 1.06 0 0 1 0 1.5l-.15.15-4.3 4.31-.14.14c-.4.41-1.1.41-1.51.01zm14.44 8.33c-.82.82-2.25.82-3.07 0l-7.86-7.86 3.07-3.07 7.86 7.86c.41.41.64.96.64 1.54s-.23 1.12-.64 1.53z" fill="#cb9cee" opacity="0.45" data-original="#cb9cee"></path>
                                                        <path d="M40.37 44.09v-.67c1.57-.39 2.74-1.81 2.74-3.49 0-1.99-1.62-3.61-3.61-3.61-1.03 0-1.86-.84-1.86-1.86s.84-1.86 1.86-1.86 1.86.84 1.86 1.86c0 .48.39.88.88.88s.88-.39.88-.88c0-1.69-1.17-3.1-2.74-3.49v-.67c0-.48-.39-.88-.88-.88s-.88.39-.88.88v.67c-1.57.39-2.74 1.81-2.74 3.49 0 1.99 1.62 3.61 3.61 3.61 1.03 0 1.86.84 1.86 1.86 0 1.03-.84 1.86-1.86 1.86s-1.86-.84-1.86-1.86c0-.48-.39-.88-.88-.88s-.88.39-.88.88c0 1.69 1.17 3.1 2.74 3.49v.67c0 .48.39.88.88.88s.88-.4.88-.88zM25.74 17.29h-8.22V9.08c0-.48-.39-.88-.88-.88-5.5 0-9.97 4.47-9.97 9.97s4.47 9.97 9.97 9.97 9.97-4.47 9.97-9.97a.88.88 0 0 0-.87-.88zm-9.1 9.1c-4.53 0-8.22-3.69-8.22-8.22 0-4.24 3.22-7.73 7.34-8.17v8.17c0 .48.39.88.88.88h8.17c-.43 4.12-3.93 7.34-8.17 7.34z" fill="#cb9cee" opacity="0.45" data-original="#cb9cee"></path>
                                                        <path d="M29.64 15.14c0-5.5-4.47-9.97-9.97-9.97-.48 0-.88.39-.88.88v9.09c0 .48.39.88.88.88h9.09c.49-.01.88-.4.88-.88zm-9.09-.88v-7.3c3.83.41 6.89 3.46 7.3 7.3z" fill="#cb9cee" opacity="1" data-original="#cb9cee"></path>
                                                        <path d="M50.37 55.29c-.48 0-.88.39-.88.88v3.8c0 1.75-1.42 3.17-3.17 3.17H6.54c-1.75 0-3.17-1.42-3.17-3.17V6.04c0-1.75 1.42-3.17 3.17-3.17h39.79c1.75 0 3.17 1.42 3.17 3.17v16.32c0 .48.39.88.88.88s.88-.39.88-.88V6.04a4.93 4.93 0 0 0-4.92-4.92H6.54a4.93 4.93 0 0 0-4.92 4.92v53.92a4.93 4.93 0 0 0 4.92 4.92h39.79a4.93 4.93 0 0 0 4.92-4.92v-3.8a.886.886 0 0 0-.88-.87z" fill="#cb9cee" opacity="1" data-original="#cb9cee"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Cost Efficiency</h2>
                                                <p>Save on recruitment, benefits, and infrastructure costs associated with expanding your in-house team.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="imgWrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 64 64">
                                                    <g>
                                                        <path d="M32 48a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8Zm0-14a6 6 0 1 0 6 6 6.006 6.006 0 0 0-6-6ZM44 61a1 1 0 0 1-1-1v-6c0-1.018-3.883-3-11-3s-11 1.982-11 3v6a1 1 0 0 1-2 0v-6c0-3.283 6.54-5 13-5s13 1.717 13 5v6a1 1 0 0 1-1 1ZM49 43a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8Zm0-14a6 6 0 1 0 6 6 6.006 6.006 0 0 0-6-6Z" fill="#dcdc30" opacity="0.45" data-original="#dcdc30"></path>
                                                        <path d="M61 56a1 1 0 0 1-1-1v-6c0-1.018-3.883-3-11-3-4.1 0-7.9.715-9.9 1.866a1 1 0 1 1-1-1.732C40.425 44.8 44.5 44 49 44c6.46 0 13 1.717 13 5v6a1 1 0 0 1-1 1ZM15 43a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8Zm0-14a6 6 0 1 0 6 6 6.006 6.006 0 0 0-6-6ZM3 56a1 1 0 0 1-1-1v-6c0-3.283 6.54-5 13-5 4.5 0 8.575.8 10.894 2.134a1 1 0 1 1-1 1.732C22.9 46.715 19.105 46 15 46c-7.117 0-11 1.982-11 3v6a1 1 0 0 1-1 1ZM32 24a1 1 0 0 1-.707-1.707L46.364 7.222a1 1 0 0 1 1.414 1.414L32.707 23.707A1 1 0 0 1 32 24Z" fill="#dcdc30" opacity="0.45" data-original="#dcdc30"></path>
                                                        <path d="M46 15a1.01 1.01 0 0 1-.243-.03l-4-1a1 1 0 0 1-.464-1.677l5-5a1 1 0 0 1 .95-.263l4 1a1 1 0 0 1 .464 1.677l-5 5A1 1 0 0 1 46 15Zm-2.044-2.542 1.737.435 3.351-3.351-1.737-.435Z" fill="#dcdc30" opacity="1" data-original="#dcdc30"></path>
                                                        <path d="M42 14a1.016 1.016 0 0 1-.267-.036 1 1 0 0 1-.7-.721l-1-4a1 1 0 0 1 .263-.95l5-5a1 1 0 0 1 1.677.464l1 4a1 1 0 0 1-.263.95l-5 5A1 1 0 0 1 42 14Zm.107-4.693.435 1.737 3.351-3.351-.435-1.737ZM15.115 26a1 1 0 0 1-.992-.884A18.429 18.429 0 0 1 14 23 18.009 18.009 0 0 1 39.412 6.592a1 1 0 0 1-.824 1.823A16.008 16.008 0 0 0 16 23a16.423 16.423 0 0 0 .109 1.884 1 1 0 0 1-.877 1.109.918.918 0 0 1-.117.007ZM48.885 26a.918.918 0 0 1-.117-.007 1 1 0 0 1-.877-1.109A16.423 16.423 0 0 0 48 23a15.87 15.87 0 0 0-1.415-6.588 1 1 0 0 1 1.823-.824A17.867 17.867 0 0 1 50 23a18.429 18.429 0 0 1-.123 2.116 1 1 0 0 1-.992.884ZM32 29a6 6 0 1 1 6-6 6.006 6.006 0 0 1-6 6Zm0-10a4 4 0 1 0 4 4 4 4 0 0 0-4-4Z" fill="#dcdc30" opacity="1" data-original="#dcdc30"></path>
                                                        <path d="M38.319 33a1 1 0 0 1-.577-1.818 10 10 0 1 0-11.484 0 1 1 0 1 1-1.152 1.636 12 12 0 1 1 13.788 0 1 1 0 0 1-.575.182Z" fill="#dcdc30" opacity="1" data-original="#dcdc30"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Focus on Core Business</h2>
                                                <p>With a strengthened team, you can keep your focus on strategic initiatives while trusted experts handle your development needs.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="imgWrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="62" height="62" x="0" y="0" viewBox="0 0 68 68">
                                                    <g>
                                                        <path d="M50.016 65.364H7.385a1 1 0 1 1 0-2c.146 0 .292-.012.449-.035a3.374 3.374 0 0 0 2.871-2.828 2.53 2.53 0 0 0 .049-.507V10.733a4.87 4.87 0 0 1 1.855-3.839l4.04-3.2a4.918 4.918 0 0 1 3.04-1.058h34.19a3.57 3.57 0 0 1 3.565 3.566v25.536a1 1 0 1 1-2 0V6.202c0-.863-.702-1.566-1.565-1.566h-34.19c-.649 0-1.288.223-1.797.626l-4.04 3.2a2.881 2.881 0 0 0-1.098 2.27v49.262c0 .308-.026.586-.08.85a5.198 5.198 0 0 1-1.117 2.52h38.459a5.435 5.435 0 0 0 5.428-5.429v-.966a1 1 0 1 1 2 0v.966c0 4.096-3.332 7.429-7.428 7.429z" fill="#ff9393" opacity="1" data-original="#ff9393"></path>
                                                        <path d="M7.385 65.364c-.387 0-.788-.05-1.195-.145a5.261 5.261 0 0 1-2.608-1.437A5.326 5.326 0 0 1 2 59.994v-6.712a1 1 0 0 1 1-1h8.754a1 1 0 0 1 1 1v6.712c0 .308-.026.586-.08.85-.334 2.283-2.213 4.14-4.56 4.466-.252.036-.491.054-.73.054zM4 54.282v5.712c0 .889.354 1.731.996 2.374a3.282 3.282 0 0 0 1.635.9c.451.106.798.12 1.203.061a3.374 3.374 0 0 0 2.871-2.828 2.53 2.53 0 0 0 .049-.507v-5.712zM51.5 58.901c-7.995 0-14.5-6.505-14.5-14.5s6.505-14.5 14.5-14.5S66 36.406 66 44.4s-6.505 14.5-14.5 14.5zm0-27c-6.893 0-12.5 5.607-12.5 12.5s5.607 12.5 12.5 12.5S64 51.293 64 44.401s-5.607-12.5-12.5-12.5z" fill="#ff9393" opacity="1" data-original="#ff9393"></path>
                                                        <path d="M51.5 35a1 1 0 0 1-1-1v-3.1a1 1 0 1 1 2 0V34a1 1 0 0 1-1 1zM51.5 58.901a1 1 0 0 1-1-1v-3.1a1 1 0 1 1 2 0v3.1a1 1 0 0 1-1 1zM58.854 38.047a1 1 0 0 1-.707-1.707l2.192-2.192a1 1 0 1 1 1.414 1.414l-2.191 2.192a.997.997 0 0 1-.708.293zM41.954 54.947a1 1 0 0 1-.707-1.707l2.191-2.192a1 1 0 1 1 1.415 1.414l-2.192 2.192a.997.997 0 0 1-.707.293zM65 45.4h-3.1a1 1 0 1 1 0-2H65a1 1 0 1 1 0 2zM41.1 45.4H38a1 1 0 1 1 0-2h3.1a1 1 0 1 1 0 2zM61.046 54.947a.997.997 0 0 1-.707-.293l-2.192-2.192a1 1 0 1 1 1.415-1.414l2.19 2.192a1 1 0 0 1-.706 1.707zM44.146 38.047a.997.997 0 0 1-.708-.293l-2.19-2.192a1 1 0 1 1 1.413-1.414l2.192 2.192a1 1 0 0 1-.707 1.707zM51.77 50.726a1 1 0 0 1-1-1v-3a1 1 0 1 1 2 0v3a1 1 0 0 1-1 1zM53.03 43.556a1 1 0 0 1-.705-1.708l2.17-2.16a.999.999 0 1 1 1.41 1.416l-2.17 2.16a.995.995 0 0 1-.706.292zM33.243 49.495H16.93a1 1 0 1 1 0-2h16.313a1 1 0 1 1 0 2zM33.243 44.331H16.93a1 1 0 1 1 0-2h16.313a1 1 0 1 1 0 2zM33.243 39.167H16.93a1 1 0 1 1 0-2h16.313a1 1 0 1 1 0 2zM33.243 34.003H16.93a1 1 0 1 1 0-2h16.313a1 1 0 1 1 0 2zM33.243 54.659H16.93a1 1 0 1 1 0-2h16.313a1 1 0 1 1 0 2zM33.243 59.823H16.93a1 1 0 1 1 0-2h16.313a1 1 0 1 1 0 2zM20.896 28.293a1 1 0 0 1-1-1v-1.548a1 1 0 1 1 2 0v1.548a1 1 0 0 1-1 1zM25.566 28.357a1 1 0 0 1-1-1v-4.393a1 1 0 1 1 2 0v4.393a1 1 0 0 1-1 1zM30.237 28.422a1 1 0 0 1-1-1V20.76a1 1 0 1 1 2 0v6.663a1 1 0 0 1-1 1zM34.908 28.487a1 1 0 0 1-1-1v-3.991a1 1 0 1 1 2 0v3.991a1 1 0 0 1-1 1zM39.58 28.163a1 1 0 0 1-1-1v-6.404a1 1 0 1 1 2 0v6.404a1 1 0 0 1-1 1zM44.25 28.228a1 1 0 0 1-1-1v-9.604a1 1 0 1 1 2 0v9.604a1 1 0 0 1-1 1z" fill="#ff9393" opacity="0.45" data-original="#ff9393"></path>
                                                        <path d="M18.139 23.666a1 1 0 0 1-.605-1.798l9.407-7.114a3.002 3.002 0 0 1 3.482-.096l4.206 2.825c.361.243.85.22 1.185-.051l11.457-9.434a1 1 0 0 1 1.272 1.544L37.081 18.98a3.028 3.028 0 0 1-3.567.164l-4.206-2.825a1.012 1.012 0 0 0-1.16.03l-9.407 7.115a.998.998 0 0 1-.602.202z" fill="#ff9393" opacity="1" data-original="#ff9393"></path>
                                                        <path d="M46.977 15.271a1 1 0 0 1-.987-1.167l.74-4.366-4.427-.123a1 1 0 0 1-.973-1.028c.016-.552.52-.995 1.027-.972l5.578.155a.998.998 0 0 1 .959 1.167l-.932 5.501a1 1 0 0 1-.985.833zM51.51 47.796c-.128 0-.256-.004-.368-.02a3.376 3.376 0 0 1-3.033-3.37c0-1.875 1.526-3.4 3.4-3.4a3.395 3.395 0 0 1 3.39 3.4c0 1.87-1.52 3.39-3.39 3.39zm0-4.79c-.773 0-1.4.628-1.4 1.4 0 .718.544 1.313 1.267 1.385l.133.005c.766 0 1.39-.624 1.39-1.39a1.4 1.4 0 0 0-1.39-1.4z" fill="#ff9393" opacity="1" data-original="#ff9393"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Enhanced Productivity</h2>
                                                <p>Augmented staff can often start contributing immediately, reducing the ramp-up time and accelerating project timelines.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="staffAugItroWrap staffAugValueWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="imgWrap">
                                    <img src={DedicatedTeamsImg3} className="img-fluid" alt=""/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="dataWrap dataWrapPadRt">
                                    <h2>The Value Our Team Adds</h2>
                                    <p>At Hummingtec, we don't just fill positions—we empower businesses to exceed their goals. Our rigorous selection process ensures that only the most skilled and compatible professionals join your team, blending seamlessly with your processes and culture. We bring:</p>
                                    <ul>
                                        <li><strong>1. A Global Talent Pool:</strong> Access to a diverse range of professionals from around the world.</li>
                                        <li><strong>2. Quality Assurance:</strong> Each candidate undergoes a thorough vetting process to ensure they meet our high standards and yours.</li>
                                        <li><strong>3. Strategic Partnership:</strong> We're not just providers; we're partners. Our goal is to understand your business inside out and contribute to your success.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="staffOnBoardWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="ttlWrap">
                                    <h2>Getting Onboarded: A Seamless Process</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <ul>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="noWrap">
                                                <h2>01</h2>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Needs Assessment</h2>
                                                <p>We start with a deep dive into your project requirements, team dynamics, and business objectives.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="noWrap">
                                                <h2>02</h2>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Talent Matching</h2>
                                                <p>Leveraging our extensive talent network, we identify candidates who not only meet your technical needs but also align with your company culture.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="noWrap">
                                                <h2>03</h2>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Interview and Selection</h2>
                                                <p>You'll have the opportunity to interview shortlisted candidates to ensure a perfect fit.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="noWrap">
                                                <h2>04</h2>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Integration</h2>
                                                <p>Our onboarding specialists facilitate a smooth integration process for the augmented staff into your existing team.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="itemWrap">
                                            <div className="noWrap">
                                                <h2>05</h2>
                                            </div>
                                            <div className="dataWrap">
                                                <h2>Ongoing Support</h2>
                                                <p>We provide continuous support and performance monitoring to ensure optimal collaboration and results.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="staffAugFaqWrap">
                    <div class="container d-flex">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="ttlWrap">
                                    <h2>FAQ</h2>
                                </div>
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                How quickly can augmented staff be onboarded?
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p>Depending on the specific skills required, we can have candidates ready for interviews within a few days, with the possibility of starting within 1-2 weeks.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                What happens if there's not a good fit?
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p>We strive for perfect matches, but if there's a need for change, we offer a streamlined replacement process to ensure your project stays on track.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingThree">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Are there minimum or maximum engagement periods?
                                            </button>
                                        </h2>
                                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p>We offer flexible engagement models tailored to your project's needs, from short-term assignments to long-term collaborations.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingFour">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                How do you ensure the quality of augmented staff?
                                            </button>
                                        </h2>
                                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p>Through rigorous vetting, including technical assessments, interviews, and reference checks, we ensure that our professionals meet your highest standards.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 ht100p">
                                <div className="rightWrap">
                                    <h2>Ready to Accelerate Your Success?</h2>
                                    <p>Embrace the future of work with Hummingtec's staff augmentation services. Let's join forces to build innovative solutions, exceed customer expectations, and achieve your business goals together. <Link to={"/Contact"}>Contact us today</Link> to explore how our tailored staff augmentation solutions can propel your projects forward.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default StaffAugmentation;