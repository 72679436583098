import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "./Footer";
import Header from "./Header";
import slide1 from "./assets/img/products/Symbio/slide-1.png";
import slide2 from "./assets/img/products/Symbio/slide-2.png";
import slide3 from "./assets/img/products/Symbio/slide-3.png";
import slide4 from "./assets/img/products/Symbio/slide-4.png";
import slide5 from "./assets/img/products/Symbio/slide-5.png";
import slide6 from "./assets/img/products/Symbio/slide-6.png";
import slide7 from "./assets/img/products/Symbio/slide-7.png";
import slide8 from "./assets/img/products/Symbio/slide-8.png";
import slide9 from "./assets/img/products/Symbio/slide-9.png";
import slide10 from "./assets/img/products/Symbio/slide-10.png";
import slide11 from "./assets/img/products/Symbio/slide-11.png";

import serviceImg1 from "./assets/img/serviceImg-1.png";
import serviceImg2 from "./assets/img/serviceImg-2.png";
import serviceImg3 from "./assets/img/serviceImg-3.png";
import serviceImg4 from "./assets/img/serviceImg-4.png";
const Symbio = () => {
    const productSlider = {
        dots: false,
        arrows:true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <>
            <Header></Header>
            <div className="mainContent">
                <div className="bannerWrap innerBanner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titleWrap">
                                    <h2>Products</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ProjectSecWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="productImgSlider sticyDiv">
                                    <Slider {...productSlider}>
                                            <div>
                                                <img src={slide1} alt="" className="img-fluid" />
                                            </div>
                                            <div>
                                                <img src={slide2} alt="" className="img-fluid" />
                                            </div>
                                            <div>
                                                <img src={slide3} alt="" className="img-fluid" />
                                            </div>
                                            <div>
                                                <img src={slide4} alt="" className="img-fluid" />
                                            </div>
                                            <div>
                                                <img src={slide5} alt="" className="img-fluid" />
                                            </div>
                                            <div>
                                                <img src={slide6} alt="" className="img-fluid" />
                                            </div>
                                            <div>
                                                <img src={slide7} alt="" className="img-fluid" />
                                            </div>
                                            <div>
                                                <img src={slide8} alt="" className="img-fluid" />
                                            </div>
                                            <div>
                                                <img src={slide9} alt="" className="img-fluid" />
                                            </div>
                                            <div>
                                                <img src={slide10} alt="" className="img-fluid" />
                                            </div>
                                            <div>
                                                <img src={slide11} alt="" className="img-fluid" />
                                            </div>
                                    </Slider>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="dataWrap">
                                    <h2>Symbio: Revolutionizing Prehospital Care</h2>
                                    <h3>Empowering Emergency Medical Services</h3>
                                    <p>At the heart of our innovative solutions, Symbio stands out as a pivotal tool in enhancing prehospital care. Designed by Hummingtec, Symbio is tailored to address the critical needs of emergency medical services (EMS) effectively.</p>
                                    <p>Symbio, with its advanced features, represents a new era in prehospital care, providing efficient, secure, and responsive solutions that cater to the dynamic requirements of EMS. For a detailed demonstration of our EMS solutions, we invite you to contact us directly.</p>
                                    <ul>
                                        <li>
                                            <div className="dataWrap">
                                                <h2>Seamless Integrations</h2>
                                                <ul>
                                                    <li><strong>EMR Integration:</strong> Effortlessly integrates with Electronic Medical Records for streamlined data exchange.</li>
                                                    <li><strong>Health Device Integration:</strong> Connects seamlessly with various health devices for real-time patient information</li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataWrap">
                                                <h2>Adaptable to Protocols and Reporting</h2>
                                                <p> Accommodates diverse state protocols and reporting standards, ensuring compliance and versatility.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataWrap">
                                                <h2>Enhanced Patient Reroute Handling</h2>
                                                <p> Navigates patient rerouting efficiently, minimizing delays and optimizing care delivery.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataWrap">
                                                <h2>Results-Oriented Approach</h2>
                                                <ul>
                                                    <li><strong>Emergency Department Preparedness:</strong> Equips emergency departments with comprehensive patient data and insights.</li>
                                                    <li><strong>Accelerated Throughput:</strong> Expedite patient processing, reducing wait times and improving efficiency.</li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataWrap">
                                                <h2>Advanced Data Utilization</h2>
                                                <ul>
                                                    <li><strong>Powerful Data Insights:</strong> Offers access to comprehensive data for informed decision-making.</li>
                                                    <li><strong>Robust HIPAA-Secure Environment:</strong> Ensures data privacy and compliance.</li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataWrap">
                                                <h2>Efficient Notifications and Architecture</h2>
                                                <ul>
                                                    <li>Instant notifications for swift coordination.</li>
                                                    <li>Microservices-based architecture for enhanced scalability and performance.</li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dataWrap">
                                                <h2>AI-Powered Rerouting</h2>
                                                <p> Utilizes AI algorithms for optimal patient rerouting and efficiency.</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="howWeWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="dataWrap">
                                    <h3>We also make sure that:</h3>
                                    <ul>
                                        <li>We assign the proper specialist for the delivery of the solution as per the client’s request.</li>
                                        <li>Provide Free Project Estimations.</li>
                                        <li>Equip and integrate the latest technologies, tools and trends.</li>
                                        <li>Create a stable team of experts with proper work and cultural code.</li>
                                        <li>Work Flexible Hours to manage clients query from all over the world.</li>
                                        <li>Assured Quality Controls & Checks.</li>
                                        <li>We believe in providing solutions to your business and not just codes.</li>
                                        <li>The extra security of your data and guarantee IP rights.</li>
                                        <li>From backend to front-end, we develop, promote, market and take care of your business.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 padLt">
                                <div className="topService">
                                    <div className="topLeft">
                                        <span>Popular</span>
                                        <h4>Design for how people think</h4>
                                        <p>Aliquam ut euismod condimentum elementum ultricies volutpat sit non. </p>
                                        <a href="#">Take Lesson</a>
                                    </div>
                                    <div className="topRight">
                                        <img src={serviceImg1} className="img-fluid" alt="" />
                                        <div className="posImg">
                                            <img src={serviceImg2} className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="bottomService">
                                    <div className="leftImg">
                                        <img src={serviceImg3} className="img-fluid" alt="" />
                                    </div>
                                    <div className="rightImg">
                                        <img src={serviceImg4} className="img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default Symbio;