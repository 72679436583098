export const jobDescriptions = [
    {
        title: "iOS Developer",
        introduction: "Are you passionate about crafting exceptional user experiences on iOS platforms? Humingtec is seeking a talented iOS Developer to join our innovative team. If you're ready to shape the future of mobile technology, we want to hear from you!",
        description: "As an iOS Developer at Humingtec, you will play a key role in designing, developing, and maintaining high-quality mobile applications for iOS devices. You will collaborate closely with our cross-functional team to create innovative solutions that delight our users and drive business objectives.",
        requirements: [
            "Bachelor's degree in Computer Science, Software Engineering, or related field.",
            "Proficiency in Swift and/or Objective-C programming languages.",
            "Experience with iOS frameworks such as UIKit, Core Data, and SwiftUI.",
            "Strong understanding of mobile app architecture, design patterns, and best practices.",
            "Excellent problem-solving and communication skills.",
        ],
        benefits: [
            "Competitive salary and benefits package.",
            "Opportunities for professional growth and development.",
            "Collaborative and inclusive work environment.",
            "Chance to work on exciting projects and cutting-edge technologies.",
        ],
    },
    {
        title: "Full stack developer",
        introduction: "Node Js, react JS: Are you passionate about building scalable and innovative web applications? Humingtec is looking for a talented Full Stack Developer with expertise in Node.js and React.js to join our dynamic team. If you're ready to shape the future of web development, we want to hear from you!",
        description: "As a Full Stack Developer at Humingtec, you will be responsible for designing, developing, and deploying full-stack web applications using Node.js and React.js. You will work closely with our cross-functional team to deliver high-quality software solutions that meet client requirements and exceed user expectations.",
        requirements: [
            "Bachelor's degree in Computer Science, Software Engineering, or related field.",
            "Proficiency in Node.js and React.js frameworks.",
            "Strong understanding of front-end technologies such as HTML5, CSS3, and JavaScript.",
            "Experience with back-end development using Express.js or similar frameworks.",
            "Knowledge of database systems such as MongoDB, MySQL, or PostgreSQL.",
            "Familiarity with version control systems (e.g., Git) and agile development methodologies.",
            "Excellent problem-solving skills and attention to detail.",
        ],
        benefits: [
            "Competitive salary and benefits package.",
            "Opportunities for professional growth and career advancement.",
            "Collaborative and supportive work environment.",
            "Exposure to cutting-edge technologies and exciting projects.",
        ],
    },
    {
        title: "Digital Marketing professional",
        introduction: "SEO, Social Media: Are you passionate about driving digital engagement and expanding brand presence? Humingtec is seeking a skilled Digital Marketing Professional with expertise in SEO and Social Media to join our innovative team. If you're ready to elevate our online visibility and reach, we want to hear from you!",
        description: "As a Digital Marketing Professional at Humingtec, you will lead our digital marketing initiatives to optimize online visibility, drive website traffic, and enhance brand awareness across various digital channels. You will leverage your expertise in SEO and Social Media to develop and execute strategies that engage our target audience and drive measurable results.",
        requirements: [
            "Bachelor's degree in Marketing, Communications, or related field.",
            "Proven experience in digital marketing with a focus on SEO and Social Media management.",
            "In-depth knowledge of SEO best practices, keyword research, and on-page/off-page optimization techniques.",
            "Proficiency in Social Media platforms (e.g., Facebook, Twitter, LinkedIn, Instagram) and experience in creating and managing social media campaigns.",
            "Familiarity with digital analytics tools (e.g., Google Analytics, SEMrush) to track and analyze campaign performance.",
            "Excellent communication skills and ability to collaborate effectively with cross-functional teams.",
        ],
        benefits: [
            "Competitive salary and comprehensive benefits package.",
            "Opportunities for professional growth and career development.",
            "Dynamic and collaborative work environment.",
            "Exposure to exciting projects and innovative technologies.",
        ],
    },
    {
        title: "AI/ML Expert",
        introduction: "Are you passionate about harnessing the power of Artificial Intelligence and Machine Learning to drive innovation? Humingtec is searching for a talented AI/ML Expert to join our dedicated team. If you're ready to shape the future of intelligent technologies, we want to hear from you!",
        description: "As an AI/ML Expert at Humingtec, you will lead the development and implementation of cutting-edge AI and Machine Learning solutions across various domains. You will work closely with our team of engineers and data scientists to design, build, and deploy innovative AI algorithms and models that address real-world challenges and drive business value.",
        requirements: [
            "Master's or Ph.D. in Computer Science, Artificial Intelligence, Machine Learning, or related field.",
            "Proven experience in developing and deploying AI/ML solutions in real-world applications.",
            "Strong understanding of machine learning algorithms, deep learning frameworks (e.g., TensorFlow, PyTorch), and natural language processing techniques.",
            "Proficiency in programming languages such as Python, R, or Java.",
            "Experience with data preprocessing, feature engineering, model training, and evaluation.",
            "Familiarity with cloud platforms (e.g., AWS, Azure, Google Cloud) and big data technologies (e.g., Hadoop, Spark) is a plus.",
            "Excellent problem-solving skills, analytical thinking, and ability to work in a fast-paced environment.",
        ],
        benefits: [
            "Competitive salary and comprehensive benefits package.",
            "Opportunities for professional growth and career advancement in the rapidly evolving field of AI/ML.",
            "Access to state-of-the-art tools, resources, and training programs to support your continued learning and development.",
            "Collaborative and inclusive work environment that encourages innovation, creativity, and collaboration.",
            "Chance to work on exciting projects at the forefront of AI and Machine Learning technology.",
        ],
    },
    {
        title: "Data Engineer",
        introduction: "Are you passionate about building robust data pipelines and driving insights from complex datasets? Humingtec is seeking a talented Data Engineer to join our innovative team. If you're ready to shape the future of data-driven solutions, we want to hear from you!",
        description: "As a Data Engineer at Humingtec, you will play a crucial role in designing, building, and maintaining scalable data infrastructure and analytics solutions. You will collaborate with our cross-functional team to collect, process, and analyze large volumes of data to generate actionable insights and drive business decisions.",
        requirements: [
            "Bachelor's or Master's degree in Computer Science, Engineering, or related field.",
            "Proven experience in data engineering, ETL (Extract, Transform, Load) processes, and data warehousing solutions.",
            "Proficiency in programming languages such as Python, Scala, or Java.",
            "Strong understanding of database systems (e.g., SQL, NoSQL) and data modeling techniques.",
            "Experience with big data technologies such as Hadoop, Spark, Kafka, and cloud platforms (e.g., AWS, Azure, Google Cloud).",
            "Familiarity with data visualization tools (e.g., Tableau, Power BI) and machine learning frameworks is a plus.",
            "Excellent problem-solving skills, attention to detail, and ability to work in a fast-paced environment.",
        ],
        benefits: [
            "Competitive salary and comprehensive benefits package.",
            "Opportunities for professional growth and career advancement in the field of data engineering and analytics.",
            "Access to cutting-edge tools, technologies, and training programs to support your continued learning and development.",
            "Collaborative and inclusive work environment that fosters innovation, creativity, and collaboration.",
            "Chance to work on exciting projects at the forefront of data-driven innovation.",
        ],
    },
    {
        title: "Python Developer",
        introduction: "Are you passionate about building scalable and efficient software solutions using Python? Humingtec is searching for a talented Python Developer to join our dynamic team. If you're ready to innovate and make an impact, we want to hear from you!",
        description: "As a Python Developer at Humingtec, you will be responsible for designing, developing, and maintaining high-quality software applications and solutions using Python programming language. You will collaborate with our cross-functional team to translate requirements into functional code and ensure the performance, scalability, and reliability of our software products.",
        requirements: [
            "Bachelor's degree in Computer Science, Engineering, or related field.",
            "Proven experience in Python development and web frameworks (e.g., Django, Flask).",
            "Strong understanding of object-oriented programming principles and design patterns.",
            "Proficiency in front-end technologies such as HTML, CSS, JavaScript, and experience with JavaScript frameworks (e.g., React, Vue.js) is a plus.",
            "Familiarity with database systems (e.g., SQL, NoSQL) and ORM libraries.",
            "Experience with version control systems (e.g., Git) and agile development methodologies.",
            "Excellent problem-solving skills, attention to detail, and ability to work in a collaborative team environment.",
        ],
        benefits: [
            "Competitive salary and comprehensive benefits package.",
            "Opportunities for professional growth and career advancement in the field of Python development and software engineering.",
            "Access to cutting-edge tools, technologies, and training programs to support your continued learning and development.",
            "Collaborative and inclusive work environment that encourages innovation, creativity, and collaboration.",
            "Chance to work on exciting projects and contribute to the success of our clients and partners.",
        ],
    },
    {
        title: "Designer",
        introduction: "Are you a creative thinker with a passion for designing exceptional user experiences? Humingtec is seeking a talented Designer to join our innovative team. If you're ready to shape the visual identity of our products and solutions, we want to hear from you!",
        description: "As a Designer at Humingtec, you will play a key role in translating concepts into visually stunning designs that resonate with our users and clients. You will collaborate closely with our cross-functional team to create intuitive and engaging user interfaces, graphics, and multimedia content that elevate our brand and enhance user experiences.",
        requirements: [
            "Bachelor's degree in Graphic Design, Visual Arts, or related field.",
            "Proven experience in graphic design, user interface design, and multimedia content creation.",
            "Proficiency in design tools such as Adobe Creative Suite (Photoshop, Illustrator, InDesign), Sketch, Figma, or equivalent.",
            "Strong understanding of design principles, typography, color theory, and layout techniques.",
            "Experience with web design principles and responsive design frameworks is a plus.",
            "Excellent communication and collaboration skills, with the ability to present and articulate design concepts effectively.",
        ],
        benefits: [
            "Competitive salary and comprehensive benefits package.",
            "Opportunities for professional growth and career advancement in the field of design and user experience.",
            "Access to cutting-edge tools, technologies, and resources to support your creative endeavors.",
            "Collaborative and inclusive work environment that values diversity, creativity, and innovation.",
            "Chance to work on exciting projects and contribute to the visual identity of our products and solutions.",
        ],
    },
];