import { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Footer from "./Footer";
import Header from "./Header";
import Salesforce from "./assets/video/Salesforce.mp4";
import Zoho from "./assets/video/Zoho.mp4";

import CRMIco1 from './assets/img/CRMIco-1.png';
import CRMIco2 from './assets/img/CRMIco-2.png';
import CRMIco3 from './assets/img/CRMIco-3.png';
import CRMIco4 from './assets/img/CRMIco-4.png';
import CRMIco5 from './assets/img/CRMIco-5.png';
import CRMIco6 from './assets/img/CRMIco-6.png';
import CRMIco7 from './assets/img/CRMIco-7.png';
import CRMIco8 from './assets/img/CRMIco-8.png';
import CRMIco9 from './assets/img/CRMIco-9.png';
import CRMIco10 from './assets/img/CRMIco-10.png';
import CRMIco11 from './assets/img/CRMIco-11.png';
import CRMIco12 from './assets/img/CRMIco-12.png';
import CRMIco13 from './assets/img/CRMIco-13.png';
import CRMIco14 from './assets/img/CRMIco-14.png';
import ZohoCRMIco1 from './assets/img/ZohoCRMIco-1.png';
import ZohoCRMIco2 from './assets/img/ZohoCRMIco-2.png';
import ZohoCRMIco3 from './assets/img/ZohoCRMIco-3.png';
import ZohoCRMIco4 from './assets/img/ZohoCRMIco-4.png';
import ZohoCRMIco5 from './assets/img/ZohoCRMIco-5.png';
import ZohoCRMIco6 from './assets/img/ZohoCRMIco-6.png';
import ZohoCRMIco7 from './assets/img/ZohoCRMIco-7.png';
import ZohoCRMIco8 from './assets/img/ZohoCRMIco-8.png';
import ZohoCRMIco9 from './assets/img/ZohoCRMIco-9.png';
import ZohoCRMIco10 from './assets/img/ZohoCRMIco-10.png';
import ZohoCRMIco11 from './assets/img/ZohoCRMIco-11.png';
import ZohoCRMIco12 from './assets/img/ZohoCRMIco-12.png';
import ZohoCRMIco13 from './assets/img/ZohoCRMIco-13.png';
import ZohoCRMIco14 from './assets/img/seoIco-4.png';
import CustomCRMIco1 from './assets/img/CustomCRMIco-1.png';
import CustomCRMIco2 from './assets/img/CustomCRMIco-2.png';
import CustomCRMIco3 from './assets/img/CustomCRMIco-3.png';
import CustomCRMIco4 from './assets/img/CustomCRMIco-4.png';
import CustomCRMIco5 from './assets/img/CustomCRMIco-5.png';
import CustomCRMIco6 from './assets/img/CustomCRMIco-6.png';
import CustomCRMIco7 from './assets/img/CustomCRMIco-7.png';
import CustomCRMIco8 from './assets/img/CustomCRMIco-8.png';

const CRMDetails = () => {

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const initialSection = splitLocation[1] || 'salesforceCrm';

    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const slider1 = useRef(null);
    const slider2 = useRef(null);

    useEffect(() => {
        setNav1(slider1.current);
        setNav2(slider2.current);
    }, []);

    const settings1 = {
        asNavFor: nav2,
        ref: slider => (slider1.current = slider),
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 2000,
        fade: true,
        cssEase: 'linear',
        arrows: true,
    };

    const settings2 = {
        asNavFor: nav1,
        ref: slider => (slider2.current = slider),
        slidesToShow: 4,
        swipeToSlide: true,
        focusOnSelect: true,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 2000,
        cssEase: 'linear',
        arrows: false,
    };

    return (
        <>
            <Header></Header>
            <div className="mainContent">
                {(initialSection === 'salesforceCrm' || pathname === '/CRMDetails') && (
                    <section>
                        <div className="topPageBanner">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-md-8">
                                        <div className="titleWrap">
                                            <h2>Salesforce CRM</h2>
                                            <p>Welcome to Hummingtec Private Limited, Your Trusted Salesforce Implementation Partner!
                                                Discover the Power of Salesforce with Our CRM Experts</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="crmWrap commonCotentWrap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <ul className="bredCrumbsWrap">
                                            <li>
                                                <Link to="/CRM">CRM</Link>
                                            </li>
                                            <li>Salesforce CRM</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row justify-content-between">
                                    <div className="col-md-5">
                                        <div className="videoWrap sticyDiv">
                                        <svg class="position-absolute" viewBox="0 0 413 367" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M297.773 365.385C240.904 379.987 171.136 291.086 138.891 279.276C75.5722 256.299 -32.89 263.6 9.61542 210.775C29.2559 186.295 70.2956 153.011 85.539 133.9C116.612 95.0327 55.6386 25.4583 110.749 5.05841C165.566 -15.1267 212.176 30.612 266.114 45.6435C301.584 55.736 339.692 50.7971 376.042 55.9508C400.959 59.6013 422.944 80.0012 408.287 99.9716C318.293 222.8 394.216 340.476 297.773 365.385Z" fill="#701A75"></path></svg>
                                            <div className="video">
                                                <video autoPlay loop muted playsInline>
                                                    <source src={Salesforce} type="video/mp4" />
                                                </video>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="masonaryWrap padLeft">
                                            <ResponsiveMasonry columnsCountBreakPoints={{ 380: 1, 480: 2 }}>
                                                <Masonry>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CRMIco1} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Sales Cloud</h2>
                                                            <p>A sales automation module that manages the sales process, tracks customer interactions, and forecasts sales.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CRMIco2} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Service Cloud</h2>
                                                            <p>Focuses on customer service and support, offering tools for case tracking and resolution, and a knowledge base for customer self-service.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CRMIco3} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Marketing Cloud</h2>
                                                            <p>Provides digital marketing automation and analytics software, including email, social media, and online advertising campaigns.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CRMIco4} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Community Cloud</h2>
                                                            <p>Enables companies to create branded online communities for customers, partners, and employees to connect and collaborate.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CRMIco5} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Commerce Cloud</h2>
                                                            <p>A cloud-based ecommerce platform that provides tools for customer shopping experiences across multiple channels including web, mobile, and social.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CRMIco6} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Analytics Cloud</h2>
                                                            <p>Offers business intelligence and analytics tools, enabling data exploration, visualization, and delivering insights across the organization.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CRMIco7} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Chatter</h2>
                                                            <p>Salesforce's social networking module, facilitating collaboration and communication among employees.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CRMIco8} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Health Cloud</h2>
                                                            <p>Tailored for healthcare management, offering tools for patient relationship management and patient engagement.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CRMIco9} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Financial Services Cloud</h2>
                                                            <p>Designed for financial services industries, providing tools for wealth management, banking, insurance, and more.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CRMIco10} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>IoT Cloud</h2>
                                                            <p>Connects internet-connected devices with Salesforce, allowing businesses to gather, analyze, and act on IoT data.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CRMIco11} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Einstein AI</h2>
                                                            <p>An artificial intelligence layer that provides analytics and machine learning capabilities to enhance and automate decision-making processes.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CRMIco12} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Sustainability Cloud</h2>
                                                            <p>Helps track and analyze environmental data to drive sustainability initiatives and reduce carbon footprint.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CRMIco13} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Education Cloud</h2>
                                                            <p>Designed for educational institutions, facilitating student and alumni management, fundraising, and engagement.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CRMIco14} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Nonprofit Cloud</h2>
                                                            <p>Tailored for non-profit organizations to manage fundraising, program management, and engagement.</p>
                                                        </div>
                                                    </div>
                                                </Masonry>
                                            </ResponsiveMasonry>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                {initialSection === 'zohoCrm' && (
                    <section>
                        <div className="topPageBanner">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-md-8">
                                        <div className="titleWrap">
                                            <h2>Zoho CRM</h2>
                                            <p>Welcome to Hummingtec Private Limited, Your Premier Zoho Implementation Partner!
                                                Unlock the Full Potential of Zoho CRM with Our Expert Team</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="crmWrap commonCotentWrap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <ul className="bredCrumbsWrap">
                                            <li>
                                                <Link to="/CRM">CRM</Link>
                                            </li>
                                            <li>Zoho CRM</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row justify-content-between">
                                    <div className="col-md-5">
                                        <div className="videoWrap sticyDiv">
                                        <svg class="position-absolute" viewBox="0 0 413 367" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M297.773 365.385C240.904 379.987 171.136 291.086 138.891 279.276C75.5722 256.299 -32.89 263.6 9.61542 210.775C29.2559 186.295 70.2956 153.011 85.539 133.9C116.612 95.0327 55.6386 25.4583 110.749 5.05841C165.566 -15.1267 212.176 30.612 266.114 45.6435C301.584 55.736 339.692 50.7971 376.042 55.9508C400.959 59.6013 422.944 80.0012 408.287 99.9716C318.293 222.8 394.216 340.476 297.773 365.385Z" fill="#701A75"></path></svg>
                                            <div className="video">
                                                <video autoPlay loop muted playsInline>
                                                    <source src={Zoho} type="video/mp4" />
                                                </video>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="masonaryWrap padLeft">
                                            <ResponsiveMasonry columnsCountBreakPoints={{ 380: 1, 480: 2 }}>
                                                <Masonry>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={ZohoCRMIco1} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Zoho CRM</h2>
                                                            <p>A comprehensive customer relationship management tool for managing sales, marketing, contacts, customer support, and other business functions.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={ZohoCRMIco2} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Zoho Books</h2>
                                                            <p>An online accounting software for small businesses to manage their finances, prepare and send invoices, and track expenses.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={ZohoCRMIco3} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Zoho Creator</h2>
                                                            <p>A low-code platform for building custom applications tailored to business needs without extensive programming knowledge.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={ZohoCRMIco4} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Zoho Desk</h2>
                                                            <p>Customer service software for managing customer support tickets, providing customer support through various channels like email, phone, and social media.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={ZohoCRMIco5} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Zoho Projects</h2>
                                                            <p>A project management tool that helps in planning, tracking, and collaborating on projects with team members.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={ZohoCRMIco6} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Zoho Mail</h2>
                                                            <p>An email hosting service tailored for businesses, featuring an ad-free, clean, and minimalist interface.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={ZohoCRMIco7} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Zoho Campaigns</h2>
                                                            <p>An email marketing software for creating, sending, and tracking email campaigns to improve customer engagement.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CRMIco10} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Zoho Analytics</h2>
                                                            <p>A self-service BI (Business Intelligence) and analytics software that allows for creating insightful dashboards and data visualizations.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={ZohoCRMIco8} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Zoho People</h2>
                                                            <p>A human resource management system for managing employee data, attendance, leave, performance reviews, and HR workflows.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={ZohoCRMIco9} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Zoho Recruit</h2>
                                                            <p>A recruitment solution that caters to the needs of staffing agencies and corporate HRs to manage job openings, resumes, candidates, and interviews.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={ZohoCRMIco10} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Zoho Inventory</h2>
                                                            <p>An inventory management software designed for growing businesses to manage orders and track inventory levels, sales, and shipments.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={ZohoCRMIco11} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Zoho Expense</h2>
                                                            <p>A platform for automating business and travel expense management, simplifying recording, tracking, and managing expenses.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={ZohoCRMIco12} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Zoho One</h2>
                                                            <p>An integrated suite of over 40 Zoho applications, designed to run an entire business on the cloud.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={ZohoCRMIco13} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Zoho Forms</h2>
                                                            <p>A tool for creating professional web forms for data collection, surveys, registrations, and more.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={ZohoCRMIco14} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Zoho Social</h2>
                                                            <p>A social media management tool that helps businesses manage multiple social networks, schedule posts, monitor keywords, and analyze performance.</p>
                                                        </div>
                                                    </div>
                                                </Masonry>
                                            </ResponsiveMasonry>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                {initialSection === 'CustomCrm' && (
                    <section>
                        <div className="topPageBanner">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-md-8">
                                        <div className="titleWrap">
                                            <h2>Custom CRM</h2>
                                            <p>Welcome to Hummingtec Private Limited, Your Trusted Custom CRM Implementation Partner!
                                                Discover the Power of Custom CRM with Our CRM Experts</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="crmWrap commonCotentWrap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <ul className="bredCrumbsWrap">
                                            <li>
                                                <Link to="/CRM">CRM</Link>
                                            </li>
                                            <li>Custom CRM</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row justify-content-between">
                                    <div className="col-md-5">
                                        <div className="videoWrap sticyDiv">
                                        <svg class="position-absolute" viewBox="0 0 413 367" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M297.773 365.385C240.904 379.987 171.136 291.086 138.891 279.276C75.5722 256.299 -32.89 263.6 9.61542 210.775C29.2559 186.295 70.2956 153.011 85.539 133.9C116.612 95.0327 55.6386 25.4583 110.749 5.05841C165.566 -15.1267 212.176 30.612 266.114 45.6435C301.584 55.736 339.692 50.7971 376.042 55.9508C400.959 59.6013 422.944 80.0012 408.287 99.9716C318.293 222.8 394.216 340.476 297.773 365.385Z" fill="#701A75"></path></svg>
                                            <div className="video">
                                                <video autoPlay loop muted playsInline>
                                                    <source src={Zoho} type="video/mp4" />
                                                </video>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="masonaryWrap padLeft">
                                            <ResponsiveMasonry columnsCountBreakPoints={{ 380: 1, 480: 2 }}>
                                                <Masonry>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={ZohoCRMIco10} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Material Inventory</h2>
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CustomCRMIco1} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Tool Inventory</h2>
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CustomCRMIco2} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Fuel Management</h2>
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CustomCRMIco3} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Vehicle Management</h2>
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CustomCRMIco4} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Payroll & Attendance Management</h2>
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CustomCRMIco5} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Progress Status</h2>
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CustomCRMIco6} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Administrative Management</h2>
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CustomCRMIco7} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Quality Management</h2>
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                        </div>
                                                    </div>
                                                    <div className="itemWrap">
                                                        <div className="icoWrap">
                                                            <img src={CustomCRMIco8} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="dataWrap">
                                                            <h2>Cost Management</h2>
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                        </div>
                                                    </div>
                                                </Masonry>
                                            </ResponsiveMasonry>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                {initialSection === 'SugarCRM' && (
                    <section>
                        <div className="crmWrap commonCotentWrap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <ul className="bredCrumbsWrap">
                                            <li>
                                                <Link to="/CRM">CRM</Link>
                                            </li>
                                            <li>SugarCRM Development</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="titleWrap">
                                            <h2>SugarCRM Development</h2>
                                            <p>For businesses requiring a highly customizable CRM solution, SugarCRM stands out. Our experts at Hummingtec can tailor SugarCRM to fit your specific business needs, integrating it seamlessly with your existing systems for a unified customer management experience.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-between">
                                    <div className="col-md-5">
                                        <div className="titleWrap sticyDiv">
                                           
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="masonaryWrap">
                                            <Masonry columnsCount={2}>

                                            </Masonry>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </div>
            <Footer></Footer>
        </>
    );
}
export default CRMDetails;